import { makeStyles, createStyles } from "@material-ui/styles";
import index from '../../../../assets/Theme/index';

export default makeStyles((theme) => createStyles({
  root: {
    borderBottom: "1px solid #808080",
    height: 48,
    overflow: "hidden",
    transition: "height 225ms cubic-bezier(0,0,.2,1) 0ms",
  },

  item: {
    userSelect: "none",
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    height: 40,
    padding: "8px 10px",
    transition: "background-color 150ms cubic-bezier(.4,0,.2,1)0ms",
    color: theme.palette.terragene.lightThird,


    "&:hover": {
      color: theme.palette.terragene.lightMain,
    },

    [`@media(min-width:${index.breakpoints["md"]}px)`]: {
      justifyContent: 'end',
    },
  },

  itemIcon: {
    height: 24,
    width: 28,


    "& svg": {
      width: 24,
      height: 24,
      verticalAlign: "unset",
      fill: "currentcolor"
    },


    [`@media(min-width:${index.breakpoints["md"]}px)`]: {
      width: 24,
    },
  },

  iTag: {
    // backgroundColor: theme.palette.terragene.lightMain,
    padding: '5px',
    borderRadius: '50%',
    width: '28px',
    textAlign: 'center',
    "&hover":{
      color: theme.palette.terragene.lightMain,
    }
  },

  itemText: {
    textTransform: "uppercase",
    flexGrow: 1,
    fontWeight: "normal",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    fontSize: "13px",

    [`@media(min-width:${index.breakpoints["md"]}px)`]: {
      display: 'none',
    }
  },

  expandIcon: {
    width: 24,
    height: 24,
    transition: "transform 150ms cubic-bezier(.4,0,.2,1)0ms",
    color: theme.palette.terragene.lightThird,


    "& svg": {
      width: 24,
      height: 24,
      verticalAlign: "unset",
      fill: "currentcolor"
    },


    [`@media(min-width:${index.breakpoints["md"]}px)`]: {
      display: 'none',
    },
  },

  expand: {
    "& $expandIcon": {
      transform: "rotate(180deg)",
    },
    "& $item":{
      backgroundColor: theme.palette.terragene.lightMain,
      color: theme.palette.terragene.darkMain,
    }
  },

  subitemsList: {
    listStyle: "none",
    padding: 0,
    margin: 0,
    display: "flex",
    flexDirection: "column"
  },

  subitem: {
    cursor: "pointer",
    userSelect: "none",
    alignItems: "center",
    borderTop: "1px solid #808080",
    height: 35,
    paddingLeft: 30,
    display: "flex",
    color: theme.palette.terragene.lightThird,
    transition: "background-color 150ms cubic-bezier(.4,0,.2,1)0ms",

    "&:hover": {
      backgroundColor: theme.palette.terragene.darkSecondary,
      color: "darkGray"
    }
  },

  subitemIcon: {
    height: 24,
    maxWidth: 42,
    flexGrow: 1,

    "& svg": {
      width: 24,
      height: 24,
      verticalAlign: "unset",
      fill: "currentcolor"
    },
  },

  subitemText: {
    fontWeight: "normal",
    flexGrow: 1,
    textTransform: "uppercase",
    fontSize: "12px",

    [`@media(min-width:${index.breakpoints["md"]}px)`]: {
      display: 'none'
    }
  },

  active: {
    backgroundColor: theme.palette.terragene.lightMain,
    color: theme.palette.terragene.darkMain,
    "&:hover": {
      backgroundColor: theme.palette.terragene.lightMain,
      color: theme.palette.terragene.darkMain,

    }
  },
  subitemActive: {
    backgroundColor: theme.palette.terragene.lightSecondary,
    color: theme.palette.terragene.darkMain,
    "&:hover": {
      backgroundColor: theme.palette.terragene.lightSecondary,
      color: theme.palette.terragene.darkMain,
    }
  },

  open: {
    '& $item': {
      justifyContent: 'normal',
    },

    '& $itemIcon': {
      width: 35,
    },

    '& $itemText': {
      display: 'block'
    },

    '& $expandIcon': {
      display: 'block'
    },

    '& $subitemText': {
      display: 'block'
    },
  },
}))

