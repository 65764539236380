import React, {useEffect, useReducer, useMemo, useContext} from "react";
import request from "../../utils/request";
import df from "../../utils/dateFormater";
import _ from "lodash";
import {getSession, getUserId, getUserCompany, getUserType, getDist} from "../../utils/sessionHandler";
import moment from "moment";

import useStyles from "./BILiveStyles";
import { flatten } from "flat";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";

import CRUD from "../../components/CRUD";
import PageContentLoading from "../../components/PageContentLoading";
import Modal from "../../components/Modal";
import CircularProgress from "../../components/CircularProgress";
import { ProductType } from '../../dictionaries/productType';
import QuickSterilizer from "../../components/QuickSterilizer";
import QuickDisinfector from "../../components/QuickDisinfector/QuickDisinfector";
import GroupedDetail from "../../components/GroupedDetail/GroupedDetail";
import { SCBICondition } from "../../dictionaries/SCBIConditions";
import reducer from './BILiveReducer';

const formatSelectObject = (objectData, emptyText) => {
    return objectData.reduce(
        (objectsList, object) => {

            objectsList.push([object.id, object.description || object.toString]);
            return objectsList;
        },
        [[null, emptyText]]
    );
};

const formatSelectObjectSte = (objectData, emptyText) => {
    return objectData.reduce(
        (objectsList, object) => {
            objectsList.push([object.id, object.description || object.toString, object.sectorId]);
            return objectsList;
        },
        [[null, emptyText]]
    );
};

const formatSelectObjectProd = (objectData, emptyText) => {
    return objectData.reduce(
        (objectsList, object) => {
            objectsList.push([object.id, object.description || object.toString, object.productModel]);
            return objectsList;
        },
        [[null, emptyText]]
    );
};

const formatNombreProgram = (objectData, emptyText) => {
    return objectData.reduce(
        (objectsList, object) => {

            objectsList.push([object.id, object.nombre || object.toString, parseFloat(object.time)]);
            return objectsList;
        },
        [[null, emptyText]]
    );
};

const initialState = {
    programs: [],
    processes: [],
    conditionSCIB: [],
    sterilizers: [],
    disinfectors: [],
    products: [],
    loadingContent: true,
    loadingData: false,
    pagination: { Page: 1, PageSize: 10 },
    requiredFields: [],
    data: [],
    detailObject: null,
    loadingDetail: false,
    formFields: [],
    detailFields: [],
    lastTimeUpdated: undefined,
    groups: [
        {Description: t("ProductGroup"), position: 1, Index: 0, visible: true},
        {Description: t("ProcessGroup"), position: 2, Index: 1, visible: true},
        {Description: t("OtherGroup"), position: 3, Index: 2, visible: true},
        {Description: t("IncubationGroup"), position: 0, Index: 3, visible: true}
    ],
    indexProductGroup: 0,
    indexProcessGroup: 1,
    indexOtherGroup: 2,
    indexIncubationGroup: 3
}

const BILive = () => {
    const classes = useStyles();
    const locale = useContext(LocalizationContext);
    const [state, dispatch] = useReducer(reducer, initialState);

    const getLive = (
        Pagination = { Page: state.pagination.Page, PageSize: state.pagination.PageSize, OrderBy: null, OrderType: null }
    ) => {
        dispatch({ type: "SET_PAGINATION", payload: { pagination: Pagination } });
        updateData();
    };

    useEffect(() => {
        Promise.all([
            request().get("/api/Program"),
            request().get("/api/ReadingBI/GetProcessBI"),
            request().get(`/api/ReadingBI/GetconditionSCIB?userLoggedId=${getUserId()}`),
            request().get(`/api/Sterilizer?CompanyId=${getUserCompany().Id}`),
            request().get(`/api/Disinfector?CompanyId=${getUserCompany().Id}`),
            request().get(`/api/Product/GetByDistributorEncrypted?distributorId=${getDist().id}`),
            request().get(`/api/Screen/GetRequiredFields?Screen=400`)
        ])
            .then(
                ([
                    programsData,
                    processesData,
                    conditionData,
                    sterilizersData,
                    disinfectorsData,
                    productsData,
                    requiredFields
                ]) => {

                    setTimeout(() => {
                        dispatch({
                            type: "SET_CONTENT",
                            payload: {
                                programs: formatNombreProgram(programsData.data, ""),
                                requiredFields: requiredFields.data,
                                processes: formatSelectObject(processesData.data, t("ChooseAProcessText", locale)),
                                conditionSCIB: formatSelectObject(conditionData.data, t("ChooseAConditionSCIBText", locale)),
                                sterilizers: formatSelectObjectSte(sterilizersData.data, t("ChooseASterilizerText", locale)),
                                disinfectors: formatSelectObjectSte(disinfectorsData.data, t("ChooseADisinfectorText", locale)),
                                products: formatSelectObjectProd(
                                    productsData.data.filter((p) => 
                                        (p.productModel.type === ProductType.BiologicalSterilizer) ||
                                        (p.productModel.type === ProductType.BiologicalDisinfection)),
                                    t("ChooseAProductText", locale)),
                                unit: [
                                    [1, t("MinuteText", locale) || "Minutes"],
                                    [2, t("SecondsText", locale) || "Seconds"]
                                ],
                            },
                        });
                    }, 250);
                }
            )
            .catch((error) => console.log(error.response));
    }, [locale]);

    useEffect(() => {
        if (!state.loadingContent) {
            updateData();
        }
    }, [state.loadingContent]);

    const secondsToString = (seconds) => {
        var hour = Math.floor(seconds / 3600);
        hour = (hour < 10) ? '0' + hour : hour;
        var minute = Math.floor((seconds / 60) % 60);
        minute = (minute < 10) ? '0' + minute : minute;
        var second = seconds % 60;
        second = (second < 10) ? '0' + second : second;
        return hour + ':' + minute + ':' + second;
    };

    const updateData = () => {
        dispatch({ type: 'TOGGLE_LOADING_DATA' })
        request()
            .get(`/api/ReadingBI/getlive?UserLoggedId=${getUserId()}`)
            .then((response) => {
                const today = new Date()
                dispatch({
                    type: "SET_DATA", payload: {
                        lastTimeUpdated: df(today, "dateTime"),
                        data: response.data
                            .reduce((readingsList, reading) => {
                                let programNameFromArr = "";
                                for (let i = 0; i < state.programs.length; i++) {
                                    if (state.programs[i][0] === reading.programId) {
                                        programNameFromArr = state.programs[i][1];
                                    }
                                }

                                readingsList.push({
                                    ...reading,
                                    programName: programNameFromArr,
                                    startTimeFormatted: df(reading.startedTime, "dateTime"),
                                    elapsedTime: secondsToString(moment(today.toISOString()).diff(moment.utc(reading.startedTime, "YYYY-MM-DD HH:mm:ss"), "seconds")),
                                    incubatorName: reading.incubator.toString,
                                });

                                return readingsList;
                            }, [])
                            .map((x) =>
                                _.mapKeys(x, (value, key) => {
                                    return "ReadingBI." + key;
                                })
                            )
                    }
                });
                setTimeout(() => {
                    dispatch({ type: 'TOGGLE_LOADING_DATA' })
                }, 250);
            })
            .catch((error) => console.log(error.response));
    };

    const columnsCollections = [
        { name: "ReadingBI.readNumber", canOrder: true },
        { name: "ReadingBI.positionNumber", canOrder: true },
        { name: "ReadingBI.programName", canOrder: true },
        { name: "ReadingBI.startTimeFormatted", canOrder: true },
        { name: "ReadingBI.elapsedTime", canOrder: true },
        { name: "ReadingBI.incubatorName", canOrder: true },
    ];
    const headersCollections = useMemo(
        () => ([
            t("ReadNumberLabel", locale),
            t("PositionNumberLabel", locale),
            t("ProgramLabel", locale),
            t("StartTimeLabel", locale),
            t("ElapsedTimeLabel", locale),
            t("IncubatorLabel", locale),
        ]),
        [locale]
    );
    const sterilizerFormFields = (sterilizers, defaultValue) => {
        return [
            {
                position: 2,
                groupIndex: state.indexProductGroup,
                label: t("ProcessLabel", locale),
                propOnObject: "ReadingBI.process",
                propOnMassiveObject: "ReadBIMasive.process",
                class: "col-12 col-md-6 px-0",
                required: state.requiredFields ? state.requiredFields.find(x => x.objectName === 1250) ? true : false : false,
                type: "select",
                options: state.processes,
            },
            {
                position: 3,
                groupIndex: state.indexProductGroup,
                label: t("ValueDLabel", locale),
                propOnObject: "ReadingBI.valueD",
                propOnMassiveObject: "ReadBIMasive.valueD",
                class: "col-12 col-md-3 px-0",
                required: state.requiredFields ? state.requiredFields.find(x => x.objectName === 1260) ? true : false : false,
                type: "number",
            },
            {
                position: 4,
                groupIndex: state.indexProductGroup,
                label: t("DValueUnitLabel", locale) || "Units",
                propOnObject: "unit",
                propOnMassiveObject: "unit",
                class: "col-12 col-md-3 px-0",
                required: state.requiredFields ? state.requiredFields.find(x => x.objectName === 1261) ? true : false : false,
                type: "select",
                options: state.unit,
            },
            {
                position: 1,
                groupIndex: state.indexProcessGroup,
                label: t("CycleLabel", locale),
                propOnObject: "ReadingBI.cycle",
                propOnMassiveObject: "ReadBIMasive.cycle",
                class: "col-12 col-md-6 px-0",
                required: state.requiredFields ? state.requiredFields.find(x => x.objectName === 1305) ? true : false : false,
                type: "specialInput"
            },
            {
                position: 0,
                groupIndex: state.indexProcessGroup,
                label: t("SterilizerLabel", locale),
                propOnObject: "ReadingBI.sterilizerId",
                propOnMassiveObject: "ReadBIMasive.sterilizerId",
                component: <a style={{ fontSize: 11 }} onClick={(e) => {
                    e.preventDefault()
                    dispatch({type: 'SHOW_ABM_STERILIZER'})
                  }} href="#">{t("AddSterilizer", locale)}</a>,
                class: "col-12 col-md-6 px-0",
                required: state.requiredFields ? state.requiredFields.find(x => x.objectName === 1320) ? true : false : false,
                type: "select",
                options: sterilizers ? sterilizers : state.sterilizers,
                id: "machine",
                userType: getUserType(),
                defaultValue: defaultValue
            },
            {
                position: 2,
                groupIndex: state.indexProcessGroup,
                label: t("LoadNumberLabel", locale),
                propOnObject: "ReadingBI.loadNumber",
                propOnMassiveObject: "ReadBIMasive.loadNumber",
                class: "col-12 col-md-6 px-0",
                required: state.requiredFields ? state.requiredFields.find(x => x.objectName === 1481) ? true : false : false,
            }
        ]
    }
    const disinfectionFormFields = (disinfectors, defaultValue) => {
        return [
            {
                position: 0,
                groupIndex: state.indexProcessGroup,
                label: t("DisinfectorLabel", locale),
                propOnObject: "ReadingBI.disinfectorId",
                propOnMassiveObject: "ReadBIMasive.disinfectorId",
                component: <a style={{ fontSize: 11 }} onClick={(e) => {
                    e.preventDefault()
                    dispatch({type: 'SHOW_ABM_DISINFECTOR'})
                  }} href="#">{t("AddDisinfector", locale)}</a>,
                class: "col-12 col-md-6 px-0",
                required: state.requiredFields ? state.requiredFields.find(x => x.objectName === 1484) ? true : false : false,
                type: "select",
                options: disinfectors ? disinfectors : state.disinfectors,
                id: "machine",
                userType: getUserType(),
                defaultValue: defaultValue
            },
            {
                position: 0,
                groupIndex: state.indexProcessGroup,
                label: t("RoomIdLabel", locale),
                propOnObject: "ReadingBI.roomId",
                propOnMassiveObject: "ReadBIMasive.roomId",
                class: "col-12 col-md-6 px-0",
                required: state.requiredFields ? state.requiredFields.find(x => x.objectName === 1486) ? true : false : false
            },
            {
                position: 0,
                groupIndex: state.indexProcessGroup,
                label: t("RoomVolumeLabel", locale),
                propOnObject: "ReadingBI.roomVolume",
                propOnMassiveObject: "ReadBIMasive.roomVolume",
                class: "col-12 col-md-6 px-0",
                type: "number",
                required: state.requiredFields ? state.requiredFields.find(x => x.objectName === 1485) ? true : false : false
            },
            {
                position: 0,
                groupIndex: state.indexProcessGroup,
                label: t("PeroxideConcentrationLabel", locale),
                propOnObject: "ReadingBI.peroxideConcentration",
                propOnMassiveObject: "ReadBIMasive.peroxideConcentration",
                class: "col-12 col-md-6 px-0",
                type: "number",
                required: state.requiredFields ? state.requiredFields.find(x => x.objectName === 1482) ? true : false : false
            }
        ]
    }
    const detailCommonFields = useMemo(
        () => ([
            //product group
            { label: t("ProductLabel", locale), name: "product.toString", position: 0, groupIndex: state.indexProductGroup },
            { label: t("ProductBrandLabel", locale), name: "product.brand", position: 1, groupIndex: state.indexProductGroup },
            { label: t("LoteProductLabel", locale), name: "loteProduct", position: 2, groupIndex: state.indexProductGroup },
            { label: t("ConditionSCIBTextLabel", locale), name: "conditionSCIBDescription", position: 5, groupIndex: state.indexProductGroup },
            //process group
            { label: t("ProgramNumberLabel", locale), name: "programNumber", position: 4, groupIndex: state.indexProcessGroup },
            { label: t("TermsLabel", locale), name: "terms", position: 8, groupIndex: state.indexProcessGroup },
            //incubation group
            { label: t("UserLabel", locale), name: "editUser.toString", position: 0, groupIndex: state.indexIncubationGroup },
            { label: t("SectorLabel", locale), name: "sector.toString", position: 1, groupIndex: state.indexIncubationGroup },
            { label: t("PositionNumberLabel", locale), name: "positionNumber", position: 2, groupIndex: state.indexIncubationGroup },
            { label: t("StartTimeLabel", locale), name: "startedTime", type: "dateTime", position: 3, groupIndex: state.indexIncubationGroup },
            { label: t("IncubatorLabel", locale), name: "incubator.toString", position: 4, groupIndex: state.indexIncubationGroup },
            { label: t("ProgramLabel", locale), name: "program.nombre", position: 5, groupIndex: state.indexIncubationGroup },
            //other group
            { label: t("BookNumberLabel", locale), name: "bookNumber", position: 0, groupIndex: state.indexOtherGroup },
            { label: t("PageNumberLabel", locale), name: "pageNumber", position: 1, groupIndex: state.indexOtherGroup },
            { label: t("NotesLabel", locale), name: "notes", position: 2, groupIndex: state.indexOtherGroup }]
        ),
        [locale]
    )
    const detailDisinfectorFields = useMemo(
        () => ([
            { label: t("DisinfectorLabel", locale), name: "disinfector.name", position: 0, groupIndex: state.indexProcessGroup },
            { label: t("DisinfectorBrandLabel", locale), name: "disinfector.brand", position: 1, groupIndex: state.indexProcessGroup },
            { label: t("DisinfectorSerialNumberLabel", locale), name: "disinfector.serial", position: 2, groupIndex: state.indexProcessGroup },
            { label: t("PeroxideConcentrationLabel", locale), name: "peroxideConcentration", position: 5, groupIndex: state.indexProcessGroup },
            { label: t("RoomIdLabel", locale), name: "roomId", position: 6, groupIndex: state.indexProcessGroup },
            { label: t("RoomVolumeLabel", locale), name: "roomVolume", position: 7, groupIndex: state.indexProcessGroup }
        ]),
        [locale]
    )
    const detailSterilizerFields = useMemo(
        () => ([
            { label: t("SterilizerLabel", locale), name: "sterilizer.name", position: 0, groupIndex: state.indexProcessGroup },
            { label: t("SterilizerBrandLabel", locale), name: "sterilizer.brand", position: 1, groupIndex: state.indexProcessGroup },
            { label: t("SterilizerSerialNumberLabel", locale), name: "sterilizer.serial", position: 2, groupIndex: state.indexProcessGroup },
            { label: t("CycleLabel", locale), name: "cycle", position: 6, groupIndex: state.indexProcessGroup },
            { label: t("ValueDLabel", locale), name: "valueD", position: 4, groupIndex: state.indexProductGroup },
            { label: t("ProcessLabel", locale), name: "processDescription", position: 3, groupIndex: state.indexProductGroup },
            { label: t("LoadNumber", locale), name: "loadNumber", position: 3, groupIndex: state.indexProcessGroup }
        ]),
        [locale]
    );
    const commonFieldsMap = (products, defaultValue) => [
        {
            position: 0,
            groupIndex: state.indexProductGroup,
            label: t("ProductLabel", locale),
            propOnObject: "ReadingBI.productId",
            propOnMassiveObject: "ReadBIMasive.productId",
            class: "col-12 col-md-6 px-0",
            required: state.requiredFields ? state.requiredFields.find(x => x.objectName === 1225) ? true : false : false,
            type: "select",
            defaultValue: defaultValue,
            options: products ? products : state.products,
            onSelectedChange: (row) => {
                let product = state.productsStatic.find(x => x[0] == row)
                if(product)
                    setFieldsByProductType(product, product[2].type, products)
            }
        },
        {
            position: 1,
            groupIndex: state.indexProductGroup,
            label: t("LoteProductLabel", locale),
            propOnObject: "ReadingBI.loteProduct",
            propOnMassiveObject: "ReadBIMasive.loteProduct",
            class: "col-12 col-md-6 px-0",
            required: state.requiredFields ? state.requiredFields.find(x => x.objectName === 1235) ? true : false : false,
        },
        {
            position: 5,
            groupIndex: state.indexProductGroup,
            label: t("ConditionSCIBTextLabel", locale),
            propOnObject: "ReadingBI.conditionSCIB",
            propOnMassiveObject: "ReadBIMasive.conditionSCIB",
            class: "col-12 col-md-6 px-0",
            required: state.requiredFields ? state.requiredFields.find(x => x.objectName === 1275) ? true : false : false,
            type: "select",
            visibilityGroupDependencies: [
                { groupIndex: state.indexProcessGroup, condition: SCBICondition.POSITIVE, visible: false},
                { groupIndex: state.indexProcessGroup, condition: SCBICondition.EXPOSED, visible: true}
            ],
            requiredDependencies: [
                { keySingle: "ReadingBI.cycle", keyMassive: 'ReadBIMasive.cycle', condition: SCBICondition.EXPOSED, required: true },
                { keySingle: "ReadingBI.cycle", keyMassive: 'ReadBIMasive.cycle', condition: SCBICondition.POSITIVE, required: false },
                { keySingle: "ReadingBI.sterilizerId", keyMassive: 'ReadBIMasive.sterilizerId', condition: SCBICondition.EXPOSED, required: true },
                { keySingle: "ReadingBI.sterilizerId", keyMassive: 'ReadBIMasive.sterilizerId', condition: SCBICondition.POSITIVE, required: false },
                { keySingle: "ReadingBI.programNumber", keyMassive: 'ReadBIMasive.programNumber', condition: SCBICondition.EXPOSED, required: true },
                { keySingle: "ReadingBI.programNumber", keyMassive: 'ReadBIMasive.programNumber', condition: SCBICondition.POSITIVE, required: false },
                { keySingle: "ReadingBI.disinfectorId", keyMassive: 'ReadBIMasive.disinfectorId', condition: SCBICondition.EXPOSED, required: true },
                { keySingle: "ReadingBI.disinfectorId", keyMassive: 'ReadBIMasive.disinfectorId', condition: SCBICondition.POSITIVE, required: false },
            ],
            options: state.conditionSCIB
        },
        {
            position: 0,
            groupIndex: state.indexOtherGroup,
            label: t("BookNumberLabel", locale),
            propOnObject: "ReadingBI.bookNumber",
            propOnMassiveObject: "ReadBIMasive.bookNumber",
            class: "col-12 col-md-6 px-0",
            required: state.requiredFields ? state.requiredFields.find(x => x.objectName === 1285) ? true : false : false
        },
        {
            position: 1,
            groupIndex: state.indexOtherGroup,
            label: t("PageNumberLabel", locale),
            propOnObject: "ReadingBI.pageNumber",
            propOnMassiveObject: "ReadBIMasive.pageNumber",
            class: "col-12 col-md-6 px-0",
            required: state.requiredFields ? state.requiredFields.find(x => x.objectName === 1295) ? true : false : false
        },
        {
            position: 3,
            groupIndex: state.indexProcessGroup,
            label: t("ProgramNumberLabel", locale),
            propOnObject: "ReadingBI.programNumber",
            propOnMassiveObject: "ReadBIMasive.programNumber",
            class: "col-12 col-md-6 px-0",
            required: state.requiredFields ? state.requiredFields.find(x => x.objectName === 1297) ? true : false : false
        },
        {
            position: 4,
            groupIndex: state.indexProcessGroup,
            label: t("TermsLabel", locale),
            propOnObject: "ReadingBI.terms",
            propOnMassiveObject: "ReadBIMasive.terms",
            class: "col-12 col-md-6 px-0",
            required: state.requiredFields ? state.requiredFields.find(x => x.objectName === 1330) ? true : false : false,
        },
        {
            position: 2,
            groupIndex: state.indexOtherGroup,
            label: t("NotesLabel", locale),
            propOnObject: "ReadingBI.notes",
            propOnMassiveObject: "ReadBIMasive.notes",
            class: "col-12 col-md-6 px-0",
            required: state.requiredFields ? state.requiredFields.find(x => x.objectName === 1345) ? true : false : false,
        }
    ]

    const validateSCBICondition = (conditionSCIB) => {
        let processedGroups = state.groups
        let processGroup = processedGroups.find(x => x.Index === state.indexProcessGroup)
        if(!conditionSCIB || conditionSCIB === SCBICondition.POSITIVE){
            processGroup.visible = false
        }
        if(conditionSCIB === SCBICondition.EXPOSED){
            processGroup.visible = true
        }
        return processedGroups
    }
    
    const setFieldsByProductType = (product, productType, products = [], conditionSCIB) => {
        if(products.length === 1 || products.length === 0){//si la longitud es 1, solo viene el registro de la traducción del placeholder.
            products = state.productsStatic
        }
        let processedGroups = validateSCBICondition(conditionSCIB)
        if (product && productType == ProductType.BiologicalSterilizer) {
            dispatch({
                type: 'FORMAT_ARRAY_FORM_FIELD', payload: {
                    commonFormFields: commonFieldsMap(products),
                    detailCommonFields: detailCommonFields,
                    extraFormFields: sterilizerFormFields(),
                    extraDetailsFields: detailSterilizerFields,
                    productType: productType,
                    groups: processedGroups
                }
            })
        }
        if (product && productType == ProductType.BiologicalDisinfection) {
            dispatch({
                type: 'FORMAT_ARRAY_FORM_FIELD', payload: {
                    commonFormFields: commonFieldsMap(products),
                    detailCommonFields: detailCommonFields,
                    extraFormFields: disinfectionFormFields(),
                    extraDetailsFields: detailDisinfectorFields,
                    productType: productType,
                    groups: processedGroups
                }
            })
        }
        if (!product) {
            dispatch({
                type: 'FORMAT_ARRAY_FORM_FIELD', payload: {
                    commonFormFields: commonFieldsMap(products, products.length === 2 ? products[1][0] : null),
                    detailCommonFields: detailCommonFields,
                    extraFormFields: products.length === 2 ? products[1][2].type === ProductType.BiologicalSterilizer ? sterilizerFormFields() : disinfectionFormFields() : [],
                    extraDetailsFields: [],
                    productType: products.length === 2 ? products[1][2].type : undefined,
                    groups: processedGroups
                }
            })
        }
    }

    const getDetail = (r) => {
        dispatch({ type: "SET_DETAIL_OBJECT", payload: { detailObject: null, loadingDetail: true } })
        let product = r["ReadingBI.product"]
        setFieldsByProductType(product, product?.productModel?.type, [], r["ReadingBI.conditionSCIB"]);
        request()
            .get(`/api/ReadingBI/getDetail?Id=${r["ReadingBI.id"]}`)
            .then((response) => {
                setTimeout(() => {
                    const row = state.data.find(x => x["ReadingBI.id"] === response.data.id)
                    row["ReadingBI.viewed"] = true
                    dispatch({ type: "SET_DETAIL_OBJECT", payload: { detailObject: flatten(response.data), data: state.data, loadingDetail: false } })
                }, 500)
            }
            )
    }

    const baseObject = useMemo(
        () => ({
            UserLogged: getSession(),
            unit: "1",
        }),
        [locale]
    );

    const massiveBaseObject = useMemo(
        () => ({
            ReadBIMasive: {
                ReadIds: [],
                productId: null,
                loteProduct: "",
                process: null,
                valueD: "",
                conditionSCIB: null,
                bookNumber: "",
                pageNumber: "",
                programNumber: "",
                cycle: "",
                sterilizerId: null,
                disinfectorId: null,
                terms: "",
                notes: "",
                loadNumber: null
            },
            UserLogged: getSession(),
            unit: "1",
        }),
        [locale]
    );

    const clasifyProductsByProgram = (programId) => {
        const programTime = state.programs.filter(x => x[0] === programId)[0][2]//RECUPERO EL TIEMPO.
        const similarTimeProducts = [[null, t("ChooseAProductText", locale)]]//AGREGO EL ELEMENTO VACÍO
        const products = state.productsStatic.filter(x => x[2]?.program?.time == programTime)
        products.forEach(element => {
            similarTimeProducts.push(element)
        })
        return similarTimeProducts
    }
    
    const setRecentlyAddedSterilizer = (defaultSterilizerId, sterilizers) => {
        dispatch({ type: 'ADD_STERILIZER', payload: { 
            Sterilizers: sterilizers,
            extraFormFields: sterilizerFormFields(sterilizers, defaultSterilizerId)
        } })
    }
    const setRecentlyAddedDisinfector = (defaultDisinfectorId, disinfectors) => {
        dispatch({ type: 'ADD_DISINFECTOR', payload: { 
            Disinfectors: disinfectors,
            extraFormFields: disinfectionFormFields(disinfectors, defaultDisinfectorId)
        } })
    }

    if (state.loadingContent) return <PageContentLoading />

    return (
        <div>
            {
                <>
                    <CRUD
                        counterDown={{
                            initialMinute: 0,
                            initialSeconds: 10,
                            refresh: updateData,
                            onceTime: false
                        }}
                        loadingData={state.loadingData}
                        newOption={false}
                        detailOption={false}
                        deleteOption={false}
                        multiSelect={true}
                        data={state.data}
                        order={{ dir: "desc", orderBy: "ReadingBI.readNumber" }}
                        url={"/api/ReadingBI"}
                        massiveURL={"/api/ReadingBI/masiveModify"}
                        title={t("Title", locale)}
                        tableHeader={`${t("LiveTitle", locale)} ${state.lastTimeUpdated}`}
                        viewedMark={true}
                        onEditClick={(rowSelected) => {
                            let product = rowSelected["ReadingBI.product"]
                            let conditionSCIB = rowSelected["ReadingBI.conditionSCIB"]
                            setFieldsByProductType(product, product?.productModel?.type, clasifyProductsByProgram(rowSelected["ReadingBI.programId"]), conditionSCIB)
                        }}
                        onEditMassiveButtonClick={() => {
                            setFieldsByProductType()
                        }}
                        tableActions={[
                            {
                                icon:
                                    "M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z",
                                tooltip: t("DetailTooltip"),
                                action: getDetail,
                            },
                        ]}
                        updateData={() => getLive(state.pagination)}
                        columns={columnsCollections}
                        headers={headersCollections}
                        idField={"ReadingBI.id"}
                        grouped={state.groups}
                        useFieldMap={false}
                        formFields={state.formFields}
                        formFieldsMachine={state.productType === ProductType.BiologicalSterilizer ? [state.sterilizers] : [state.disinfectors]}
                        baseObject={baseObject}
                        massiveBaseObject={massiveBaseObject}
                        method={"put"}
                    />

                    {(state.loadingDetail || state.detailObject) && (
                        <Modal
                            header={
                                <div className={classes.header}>
                                    <div className={classes.headerText}>{`${t("Title", locale)} ${state.detailObject ? `(${state.detailObject.readNumber})` : ""
                                        }`}</div>
                                    <span
                                        className={classes.modalCloseButton}
                                        onClick={() => dispatch({ type: 'SET_DETAIL_OBJECT', payload: { detailObject: null } })}
                                    >
                                        <svg viewBox="0 0 24 24">
                                            <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                                        </svg>
                                    </span>
                                </div>
                            }
                            closeModal={() => dispatch({ type: 'SET_DETAIL_OBJECT', payload: { detailObject: null } })}
                            responsive
                        >
                            {!state.detailObject && (
                                <div className={classes.detailLoading}>
                                    <CircularProgress />
                                </div>
                            )}
                            {
                                state.detailObject && <GroupedDetail groups={state.groups} detailFields={state.detailFields} detailObject={state.detailObject}></GroupedDetail>
                            }
                        </Modal>
                    )}
                </>
            }
            {state.abmSterilizerVisible && (
                <Modal
                    header={
                        <div className={classes.header}>
                            <div className={classes.headerText}>{t("AddSterilizer", locale)}</div>
                            <span
                                className={classes.modalCloseButton}
                                onClick={() => dispatch({ type: 'HIDE_ABM_STERILIZER' })}
                            >
                                <svg viewBox="0 0 24 24">
                                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                                </svg>
                            </span>
                        </div>
                    }
                    closeModal={() => dispatch({ type: 'HIDE_ABM_STERILIZER' })}
                >
                    <QuickSterilizer onClickCallBack={(e) => {
                        const newSterilizer = [e.Id, e.Name, getSession().SectorId]
                        state.sterilizers.push(newSterilizer);
                        setRecentlyAddedSterilizer(e.Id, state.sterilizers)
                    }}></QuickSterilizer>
                </Modal>
            )}
            {state.abmDisinfectorVisible && (
                <Modal
                    header={
                        <div className={classes.header}>
                            <div className={classes.headerText}>{t("AddDisinfector", locale)}</div>
                            <span
                                className={classes.modalCloseButton}
                                onClick={() => dispatch({ type: 'HIDE_ABM_DISINFECTOR' })}
                            >
                                <svg viewBox="0 0 24 24">
                                    <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                                </svg>
                            </span>
                        </div>
                    }
                    closeModal={() => dispatch({ type: 'HIDE_ABM_DISINFECTOR' })}
                >
                    <QuickDisinfector onClickCallBack={(e) => {
                        const newDisinfector = [e.Id, e.Name, getSession().SectorId]
                        state.disinfectors.push(newDisinfector);
                        setRecentlyAddedDisinfector(e.Id, state.disinfectors)
                    }}></QuickDisinfector>
                </Modal>
            )}
        </div>
    );
};

export default BILive;