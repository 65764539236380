import React, { useState } from "react";
import propTypes from "prop-types";
import _ from "lodash";
import classnames from "classnames";
import t from "../../utils/translation";
import { getUserHash, getRowsPerPage } from "../../utils/sessionHandler"

import useStyles from "./TableStyles";

import TableRow from "./TableRow";
import ServerSidePagination from "./ServerSidePagination";
import SearchBar from "../SearchBar";
import Button from "../Button";
import MaterialPagination from "./MaterialPagination/MaterialPagination";
import { CircularProgress } from "@material-ui/core";
import ScrollContainer from "react-indiana-drag-scroll";
import './icons/icons.css';
import CountDownTimer from "../CountDownTimer/CountDownTimer";
const webUrl = process.env.REACT_APP_WEB_URL;

const Table = (props) => {
  const classes = useStyles();

  const {
    headers,
    columns,
    data,
    rowsPerPageOptions,
    headerText,
    actions = [],
    multiSelect = false,
    connectionStatus = false,
    selectedObjectsId = [],
    loadingData,
    serversidePagination = false,
    btnTrazantoVisible,
    totalRowsServerSide,
    editedInfo = { enabled: false },
    migrationInfo = { enabled: false },
    handlePagination,
    resultStatus,
    masterDetail = {
      visible: false
    },
    counterDown = {
      initialMinute: null,
      initialSeconds: null,
      refresh: () => { }
    },
    viewedMark
  } = props;

  const maxRowsPerPage = parseInt(getRowsPerPage(rowsPerPageOptions[1]))
  const [rowsPerPage, setRowsPerPage] = useState(maxRowsPerPage);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState(props.order);
  const [term, setTerm] = useState("");
  let totalRows = data.length;

  let colSpan = columns.length;
  multiSelect && colSpan++;
  connectionStatus && colSpan++;
  editedInfo.enabled && colSpan++;
  migrationInfo.enabled && colSpan++;
  resultStatus && colSpan++;
  masterDetail && masterDetail.visible && colSpan++;


  const noResultList = () => (
    <tr key={Math.random()}>
      <td
        colSpan={colSpan}
        style={{ fontStyle: "italic", textAlign: "center" }}
      >
        {t("EmptyTableText") || "No results"}
      </td>
    </tr>
  );

  const loadingDataRow = () => (
    <tr>
      <td
        colSpan={colSpan}
        style={{ fontStyle: "italic", textAlign: "center" }}
      >
        {t("Loading") || "Loading..."}
      </td>
    </tr>
  );

  const stableSort = (array, cmp) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = cmp(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  };

  const getSorting = (order, orderBy) => {
    return order === "desc"
      ? (a, b) => desc(a, b, orderBy)
      : (a, b) => -desc(a, b, orderBy);
  };

  const desc = (a, b, orderBy) => {
    if (_.get(b, orderBy) < _.get(a, orderBy)) {
      return -1;
    }
    if (_.get(b, orderBy) > _.get(a, orderBy)) {
      return 1;
    }
    return 0;
  };

  const handleTableSort = (column) => {
    if (!serversidePagination) {
      let newOrder = {
        dir: "desc",
        orderBy: column,
      };

      order.orderBy === column && order.dir === "asc"
        ? (newOrder.dir = "desc")
        : (newOrder.dir = "asc");
      setOrder(newOrder);
    } else {
      let orderType = "";
      order.orderBy === column && order.dir === "asc"
        ? (orderType = "desc")
        : (orderType = "asc");
      handlePagination(1, rowsPerPage, column, orderType);
      let newOrder = {
        dir: orderType,
        orderBy: column,
      };
      setOrder(newOrder);
    }
  };

  const filterRows = (rows) => {
    const termWithScapedCharacters = term
      .trim()
      .replace(/\*|\\|\+|\[|\]|\?/gi, (matched) => "\\" + matched);

    const termRegExp = RegExp(termWithScapedCharacters, "i");

    let rowToAdd = null;
    let filteredResult = [];

    if (term !== "") {
      filteredResult = rows.reduce((filteredRows, row) => {
        rowToAdd = null;
        for (let i = 0; i < columns.length; i++) {
          if (termRegExp.test(row[columns[i]])) {
            rowToAdd = row;
          }
        }

        rowToAdd && filteredRows.push(rowToAdd);
        return filteredRows;
      }, []);

      totalRows = filteredResult.length;
      return filteredResult.length > 0 ? filteredResult : [{}];
    }

    totalRows = data.length;
    return rows;
  };

  return (
    <div className={classes.tableRoot}>
      <div className={classes.header}>
        {loadingData && <CircularProgress className={classes.circularProgressRoot} size={20} />}
        <span className={classes.headerText}>
          <CountDownTimer initialMinute={counterDown.initialMinute} initialSeconds={counterDown.initialSeconds} counterFinished={counterDown.refresh} onceTime={counterDown.onceTime}></CountDownTimer> {headerText}
        </span>
        {btnTrazantoVisible &&
          <span className={classes.btnTrazanto}>
            <span title='Trazanto' className={"trazantoIcon"} style={{height:"29px",width:"40px"}}></span>
            <Button
              color={'secondary'}
              caption={t("ScanButton") || "Scan"}
              onClick={() => {
                const hash = getUserHash()
                const magnetic = "trazanto:" + webUrl + ";" + hash
                window.location.replace(magnetic)
              }}
            >
            </Button>
          </span>
        }
      </div>
      {!serversidePagination && (
        <SearchBar
          value={term}
          onChange={(val) => {
            setTerm(val);
            setPage(1);
          }}
        />
      )}
      <div className={`table-responsive ${classes.tablePrimaryResponsive}`}>

        <ScrollContainer hideScrollbars={false}>
          <table className={`table ${classes.tablePrimary} table-hover`}>
            <thead>
              <tr className={classes.hasEditAction}>
                {masterDetail && masterDetail.visible && <td style={{ borderRight: 0 }} />}
                {multiSelect && <td style={{ borderRight: 0 }} />}
                {connectionStatus && <td style={{ borderRight: 0 }} />}
                {editedInfo.enabled && <td style={{ borderRight: 0 }} />}
                {migrationInfo.enabled && <td style={{ borderRight: 0 }} />}
                {resultStatus && <td style={{ borderRight: 0 }} />}
                {columns.map((c, index) => {
                  return c.name ? (
                    <th
                      scope="col"
                      key={c.name}
                      onClick={() => {
                        return c.canOrder ? handleTableSort(c.name) : undefined
                      }
                      }
                      style={{ verticalAlign: "middle"}}
                    >
                      <div className={classnames("d-flex")} style={{ alignItems: "center" }}>
                        {headers[index]}
                        {c.canOrder ? (
                          order.orderBy === c.name ? (
                            <i
                              className={classnames("fa fa-arrow-up", {
                                [classes.desc]: order.dir === "desc",
                              })}
                            ></i>
                          ) : (
                            <i
                              className={`fa fa-arrow-up ${classes.prevSortArrow}`}
                            ></i>
                          )

                        ) : null
                        }
                      </div>
                    </th>
                  ) : (
                    <th
                      scope="col"
                      key={c}
                      onClick={() =>
                        handleTableSort(c)
                      }
                      style={{ verticalAlign: "middle" }}
                    >
                      <div className={"d-flex"} style={{ alignItems: "center" }}>
                        {headers[index]}
                        {
                          order.orderBy === c ? (
                            <i
                              className={classnames("fa fa-arrow-up", {
                                [classes.desc]: order.dir === "desc",
                              })}
                            ></i>
                          ) : (
                            <i
                              className={`fa fa-arrow-up ${classes.prevSortArrow}`}
                            ></i>
                          )
                        }
                      </div>
                    </th>
                  )
                })}
              </tr>
            </thead>
            <tbody>
              {loadingData && loadingDataRow()}
              {!loadingData && data.length === 0 && noResultList()}
              { 
                data.length > 0 &&
                loadingData !== true &&
                (
                  serversidePagination 
                  ? filterRows(data) 
                  : stableSort(filterRows(data), getSorting(order.dir, order.orderBy))
                    .slice(
                      (page - 1) * rowsPerPage,
                      (page - 1) * rowsPerPage + rowsPerPage
                    )
                )
                  .map((row) => {
                    if (Object.keys(row).length === 0) return noResultList();
                    return (
                      <TableRow
                        viewedMark={viewedMark}
                        noResultList={noResultList}
                        colSpan={colSpan}
                        masterDetail={masterDetail}
                        resultStatus={resultStatus}
                        key={data.indexOf(row)}
                        columns={columns}
                        row={row}
                        classes={classes}
                        handleRowClick={props.handleRowClick}
                        handleRowEdit={props.handleRowEdit}
                        handleRowDetail={props.handleRowDetail}
                        handleRowDelete={props.handleRowDelete}
                        actions={actions}
                        multiSelect={multiSelect}
                        connectionStatus={connectionStatus}
                        editedInfo={editedInfo}
                        migrationInfo={migrationInfo}
                        selected={selectedObjectsId.includes(row[props.idField])}
                        idField={props.idField}
                      />
                    );
                  })}
            </tbody>
          </table>
        </ScrollContainer>
      </div>
      {
        !serversidePagination ? (
          <MaterialPagination
            rowsPerPageOptions={rowsPerPageOptions}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={page}
            setPage={setPage}
            totalRows={totalRows}
            rowsPerPageText={props.rowsPerPageText}
          />
        ) : (
          <ServerSidePagination
            rowsPerPageOptions={rowsPerPageOptions}
            order={order}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            page={props.page}
            handlePagination={handlePagination}
            totalRows={totalRowsServerSide}
            rowsPerPageText={props.rowsPerPageText}
          />
        )
      }
    </div> 
  );
};

Table.propTypes = {
  headerText: propTypes.string.isRequired,
  headers: propTypes.array.isRequired,
  columns: propTypes.array.isRequired,
  data: propTypes.array.isRequired,
  order: propTypes.exact({
    dir: propTypes.oneOf(["desc", "asc"]),
    orderBy: propTypes.string,
  }).isRequired,
  rowsPerPageOptions: propTypes.array.isRequired,
  rowsPerPageText: propTypes.string,
};

export default Table;
