import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles((theme) => createStyles({
  root: {
    display: "inline-flex",
    position: "relative",
    flexDirection: "column",
    verticalAlign: "top",
    // margin:'0px 15px',
  },

  fullwidth: {
    width: "100%"
  },

  inputLabel: {
    position: "absolute",
    top: 0,
    left: 0,
    zIndex: 1,
    transform: "translate(0, 24px) scale(1)",
    display: "block",
    transformOrigin: "top left",
    transition:
      "color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    color: "rgba(0,0,0,0.54)",
    lineHeight: "1",
    letterSpacing: "0.00938em",
    margin: 0,
    pointerEvents: "none",
    padding: '0 5px',
    backgroundColor: '#f7f7f7'
  },

  inputLabelOutlined: {
    transform: "translate(6px, 14px) scale(1)",
  },

  focused: {
    "& $inputLabel, & $calendarIcon": {
      color: theme.palette.terragene.darkMain
    },

    "& $inputOutline": {
      borderColor: `${theme.palette.terragene.darkMain}!important`,
      borderWidth: 2
    },

    "& $inputUnderline::after": {
      transform: "scaleX(1)"
    }
  },

  inputLabelShrink: {
    "& $inputLabel": {
      transform: "translate(0, 1.5px) scale(0.75)"
    },

    "& $inputLabelOutlined": {
      transform: "translate(14px, -6px) scale(0.75)"
    }
  },

  inputBaseRoot: {
    position: "relative",
    color: "rgba(0, 0, 0, 0.87)",
    display: "inline-flex",
    fontSize: "1rem",
    boxSizing: "border-box",
    alignItems: "center",
    lineHeight: "1.1875em",
    

    "&:hover $inputOutline, &:hover$inputUnderline::before": {
      borderColor: "rgba(0,0,0,0.87)"
    },

    "&:hover $calendarIcon": {
      color: "rgba(0,0,0,0.87)"
    }
  },

  inputBaseInput: {
    width: "100%",
    display: "block",
    minWidth: 0,
    background: "none",
    boxSizing: "content-box",

    "&>div": { width: "100%" },
    
    "&>div>div::before": { display: 'none' },
    "&>div>div::after": { display: 'none' },

    "& input": {
      font: "inherit",
      padding: "6px 0 7px",
      textOverflow: "ellipsis",
      height: "1.1875em",
      marginTop: 16,
    }
  },

  inputDetail: {
    height: "auto"
  },

  outlinedInputInput: {
    '& input': {
      padding: "12.5px 13px 10.5px 8px",
      marginTop: 0
    },

    '& $calendarIcon': {
      right: 9,
      bottom: 13.5
    }
  },

  inputUnderline: {
    // '&:hover::before': {
    //   borderColor: "rgba(0,0,0,0.87)"
    // },

    "&::before": {
      left: 0,
      right: 0,
      bottom: 0,
      content: "''",
      position: "absolute",
      transition: "border-bottom-color 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
      borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
      pointerEvents: "none"
    },

    "&::after": {
      left: 0,
      right: 0,
      bottom: 0,
      content: "''",
      position: "absolute",
      transform: "scaleX(0)",
      transition: "transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
      borderBottom: `2px solid ${theme.palette.terragene.darkMain}`,
      pointerEvents: "none"
    }
  },

  inputOutline: {
    borderColor: "rgba(0,0,0,0.23)",
    position: "absolute",
    top: "-5px",
    left: 0,
    right: 0,
    bottom: 0,
    margin: 0,
    padding: 0,
    transition:
      "padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    borderWidth: 1,
    borderStyle: "solid",
    borderRadius: 4,
    pointerEvents: "none"
  },

  whiteSpace: {
    padding: 0,
    textAlign: "left",
    transition: "width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms",
    lineHeight: "11px"
  },

  hasError: {
    "& $inputLabel, & $calendarIcon": {
      color: `${theme.palette.terragene.requiredField}!important`
    },

    "& $inputOutline": {
      borderColor: `${theme.palette.terragene.requiredField}!important`,
      borderWidth: 2
    },

    "& $inputUnderline::after": {
      transform: "scaleX(1)",
      borderBottomColor: theme.palette.terragene.requiredField
    }
  },

  errorMessage: {
    color: theme.palette.terragene.requiredField,
    margin: 0,
    fontSize: "0.75rem",
    minHeight: "1em",
    textAlign: "left",
    lineHeight: "1em",
    letterSpacing: "0.03333em",
    marginTop: 8
  },

  errorMessageContained: {
    margin: "8px 14px 0"
  },

  disabled: {
    "& $inputLabel, & $inputBaseInput, & $inputUnderline::before, & $calendarIcon": {
      color: "rgba(0, 0, 0, 0.38)!important"
    },

    '& $inputUnderline::before, & $inputUnderline:hover::before': {
      borderColor: 'rgba(0, 0, 0, 0.38)',
    },

    "& $inputUnderline::after": {
      borderBottom: "1px solid rgba(0, 0, 0, 0.38)"
    },

    "& $inputOutline": {
      borderColor: "rgba(0, 0, 0, 0.38)!important",
      borderWidth: 1
    }
  },

  calendarIcon: {
    color: 'rgba(0,0,0,.48)',
    position: 'absolute',
    bottom: 7,
    right: 2,
    zIndex: -1
  }
}));
