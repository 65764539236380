import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles((theme) => createStyles ({
    root: {
        display: "flex",
        alignItems: "baseline",
        borderTop: `1px solid ${theme.palette.terragene.darkMain}`,
        color: theme.palette.terragene.lightThird,
        background: theme.palette.terragene.darkSecondary,
        textTransform: "uppercase",
        justifyContent: "center",

        "& ul": {
            marginBottom: "0!important"
        }
    },
    selectIcon: {
        color: theme.palette.terragene.lightMain
    },
    toolbar: {
        minHeight: "38px"
    },
    selectRoot:{
        color: theme.palette.terragene.lightThird
    },
    menuItem:{
        color: theme.palette.terragene.darkMain
    }
}));

