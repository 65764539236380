import { makeStyles } from '@material-ui/styles';

export default makeStyles({
    header: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '10px 24px',
        margin:'25px 0px 0px 25px'
    },

    headerText: {
        fontSize: 16,
        fontWeight: 500,
        lineHeight: '1.5rem',
        color: '#5a5a5d'
    },

    headerPopUpImage:{
        display: "flex",
        padding: "10px 24px",
        justifyContent: "flex-end"
    },

    modalCloseButton: {
        color: "#9e9e9e",
        borderRadius: "50%",
        cursor: "pointer",
        userSelect: "none",

        '&:hover': {
            color: '#5a5a5d'
        },

        "& svg": {
            fill: "currentcolor",
            width: "1em",
            height: "1em",
            fontSize: "1.5rem",
            userSelect: "none"
        }
    },

    footerAction: {
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end'
    },

    detailLoading: {
        display: 'flex',
        justifyContent: 'center'
    },

    pdfIconButton: {
        display: 'flex',
        float: 'left',
        "& .MuiToggleButton-label": {
            color: 'black'
        }
    },
    pdfIcon: {
        position: 'relative',
        float: 'left',
        color: 'black'
    },
    circularProgressRoot: {
        float: 'left',
        marginLeft: 25
    }
})