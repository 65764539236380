import React, { useState, useEffect, useContext } from "react";
import {
  getUserCompany,
  getUserId,
  getSession,
} from "../../utils/sessionHandler";
import request from "../../utils/request";
import LocalizationContext from "../../utils/LocalizationContext";
import t from "../../utils/translation";

import PageContentLoading from "../../components/PageContentLoading";
import Chart from "../../components/Chart";
import Select2 from "../../components/Select2";
import DateInput from "../../components/DateInput/DateInput";
import Input from "../../components/Input";
import Button from "../../components/Button";
import CircularProgress from "../../components/CircularProgress";
import classNames from "classnames";
import Button2 from "../../components/Button2";

const formatSelectObject = (objectData, emptyText) => {
  return objectData.reduce(
    (objectsList, object) => {
      objectsList.push([
        object.id,
        object.description || object.toString,
        object.brand,
        object.serial,
      ]);
      return objectsList;
    },
    [[null, emptyText]]
  );
};

export default function AppRPE() {
  const locale = useContext(LocalizationContext);

  const [contentLoading, setContentLoading] = useState(true);
  const [data, setData] = useState([]);
  const [SRPLevel, setSRPLevel] = useState(null);
  const [sterilizers, setSterilizers] = useState(null);
  const [selectedSterilizer, setSelectedSterilizer] = useState("");
  const [dateFrom, setDateFrom] = useState(null);
  const [dateTo, setDateTo] = useState(null);
  const [loadingChartData, setLoadingChartData] = useState(false);

  const [calculateSterilizer, setCalculateSterilizer] = useState("");
  const [cicle, setCicle] = useState(null);
  const [calculatingRPE, setCalculatingRPE] = useState(false);
  const [rpeResponse, setRpeResponse] = useState(null);

  const [showChart, setShowChart] = useState(false);
  const [requiredErrors, setRequiredErrors] = useState({});
  const [activeOption, setActiveOption] = useState("graphic");
  const [specialTranslations, setSpecialTranslations] = useState(null);


  useEffect(() => {
    const sectorId = getSession().SectorId || 0
    Promise.all([
      request().get(`/api/appparameter/getbytype?userLoggedId=${getUserId()}&appParameterTypeId=4`),
      request().get(`/api/Sterilizer/GetByUserLogged`),
      request().get(`/api/RPE/limitsTranslations`),
    ])
      .then(([parameter, sterilizersData, translations]) => {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);
        setDateFrom(firstDay)
        setDateTo(lastDay)
        setSRPLevel(parseInt(parameter.data.value));
        setSterilizers(
          formatSelectObject(
            sterilizersData.data,
            t("ChooseASterilizerText", locale)
          )
        );
        setContentLoading(false);
        setSpecialTranslations(translations.data);
      })
      .catch((error) => console.log(error));
  }, [locale]);

  useEffect(() => {
    setRequiredErrors({});
    setShowChart(false);
  }, [selectedSterilizer, dateFrom, dateTo]);

  const validateRequiredFieldsChart = () => {
    let errors = {};

    if (!selectedSterilizer)
      errors.selectedSterilizer = t("RequiredFieldError");
    if (!dateFrom) errors.dateFrom = t("RequiredFieldError");
    if (!dateTo) errors.dateTo = t("RequiredFieldError");

    return errors;
  };

  const generateChart = () => {
    const errors = validateRequiredFieldsChart();
    console.log(errors);

    if (Object.keys(errors).length > 0) {
      setRequiredErrors(errors);
    } else {
      setRequiredErrors({});
      setLoadingChartData(true);
      setShowChart(false);
      request()
        .post("/api/RPE/calculateSPRgraphics", {
          Sterilizer: { Id: sterilizers[selectedSterilizer][0] },
          DateFrom: dateFrom.toISOString(),
          DateTo: dateTo.toISOString(),
          UserLogged: getSession(),
        })
        .then((response) => {
          setLoadingChartData(false);
          setShowChart(true);
          setData(response.data);
        })
        .catch((error) => {
          setLoadingChartData(false);
          setShowChart(false);
          setRpeResponse(error?.response?.data?.message);
        });
    }
  };

  const validateRequiredFieldsCalculate = () => {
    let errors = {};

    if (!calculateSterilizer)
      errors.calculateSterilizer = t("RequiredFieldError");
    if (!cicle) errors.cicle = t("RequiredFieldError");

    return errors;
  };

  const calculateRPE = () => {
    const errors = validateRequiredFieldsCalculate();
    setRequiredErrors({});
    if (Object.keys(errors).length > 0) {
      setRequiredErrors(errors);
    } else {
      setCalculatingRPE(true);
      setRpeResponse(null);
      request()
        .post("/api/RPE/calculateRPE", {
          Sterilizer: { Id: calculateSterilizer },
          Cicle: cicle,
          UserLogged: getSession(),
        })
        .then((response) => {
          setRpeResponse({ type: "success", data: response.data });
          setCalculatingRPE(false);
        })
        .catch((error) => {
          setRpeResponse({
            type: "danger",
            message: error?.response?.data?.message,
          });
          setCalculatingRPE(false);
        });
    }
  };

  const clearFields = (option) => {
    setSelectedSterilizer("");
    setDateFrom(null);
    setDateTo(null);
    setCalculateSterilizer("");
    setCicle(null);
    setShowChart(false);
    setLoadingChartData(false);
    setCalculatingRPE(false);
    setRpeResponse(null);
    setRequiredErrors({});
    setActiveOption(option);
  };

  if (contentLoading) return <PageContentLoading />;

  return (
    <div style={{fontFamily:"Montserrat"}} className="container-fluid px-0">
      {activeOption === "graphic" && rpeResponse && (
        <div className={"row mx-0"}>
          <div className={"col-12"}>
            <div className={`alert alert-danger`} role="alert">
              {rpeResponse}
            </div>
          </div>
        </div>
      )}

      {activeOption === "graphic" && (
        <div className="row mx-0">
          <div className="col-md-6">
            <Select2
              getIndex={true}
              disabled={loadingChartData}
              value={
                selectedSterilizer ? sterilizers[selectedSterilizer][0] : ""
              }
              label={t("SterilizerLabel", locale)}
              options={sterilizers}
              onChange={(val) => setSelectedSterilizer(val)}
              variant="outlined"
              error={requiredErrors.selectedSterilizer}
            />
          </div>
          <div className="col-md-3">
            <Input
              label={t("BrandLabel", locale)}
              value={
                selectedSterilizer ? sterilizers[selectedSterilizer][2] : ""
              }
              disabled
              onChange={undefined}
              variant="outlined"
              gutter="38px 0 0"
            />
          </div>
          <div className="col-md-3">
            <Input
              label={t("SerialLabel", locale)}
              value={
                selectedSterilizer ? sterilizers[selectedSterilizer][3] : ""
              }
              disabled
              onChange={undefined}
              variant="outlined"
              gutter="38px 0 0"
            />
          </div>
          <div className="row mx-0">
            <div className="col-md-5">
              <DateInput
                value={dateFrom}
                onChange={(val) => setDateFrom(val._d)}
                label={t("DateFromLabel", locale)}
                variant="outlined"
                disabled={loadingChartData}
                gutter="38px 0px 0px 25px"
                error={requiredErrors.dateFrom}
              />
            </div>
            <div className="col-md-5">
              <DateInput
                value={dateTo}
                onChange={(val) => setDateTo(val.endOf('day')._d)}
                label={t("DateToLabel", locale)}
                variant="outlined"
                disabled={loadingChartData}
                gutter="38px 0px 0px 25px"
                error={requiredErrors.dateTo}
              />
            </div>
            <div
              className="col-md-2"
              style={{ display: "flex", marginTop:"15px" }}
            >
              <Button2
                caption={t("GenerateButton")}
                onClick={generateChart}
                loading={loadingChartData}
              />
            </div>
          </div>
        </div>
      )}

      {loadingChartData && activeOption === "graphic" && (
        <div className="row mx-0" style={{ marginTop: 20 }}>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <CircularProgress size={50} />
          </div>
        </div>
      )}

      {showChart && activeOption === "graphic" && (
        <div className="row mx-0">
          <div className="col-12">
            <Chart
              data={data}
              yAxisLabel={t("ScoreLabel", locale)}  
              xAxisLabel={t("CycleLabel", locale)}
              yTicksVisible={false}
              referenceLinePosition={"left"}
              XAxisKey={"cicle"}
              line={[
                {
                  key: "score",
                  color: "#1976d2",
                  label: t("ScoreLabel", locale),
                  tooltip: [
                    { value: "score", label: t("ScoreLabel", locale) },
                    {
                      value: "scoreTypeDescription",
                      label: t("ScoreTypeLabel", locale),
                    },
                    { value: "!cicle", label: t("CycleLabel", locale) },
                  ],
                },
                { key: "", color: "red", label: specialTranslations.HIGH},
                { key: "", color: "yellow", label: specialTranslations.MEDIUM},
                { key: "", color: "green", label: specialTranslations.LOW},
                { key: "", color: "grey", label: specialTranslations.NULL},
              ]}
              referenceLine={[
                { value: 16, label: specialTranslations.HIGH, color: "red" },
                { value: 49.5, label: specialTranslations.MEDIUM, color: "yellow"},
                { value: 85, label: specialTranslations.LOW, color: "green"},
              ]}
            />
          </div>
        </div>
      )}

      {activeOption === "calculate" && (
        <div className={"container-fluid px-0"}>
          <div className={"row mx-0"}>
            <div className={"col-md-6"}>
              <Select2
                error={requiredErrors.calculateSterilizer}
                label={t("SterilizerLabel", locale)}
                value={calculateSterilizer}
                options={sterilizers}
                onChange={(val) => setCalculateSterilizer(val)}
                disabled={calculatingRPE}
                isRequired={true}
                variant="outlined"
              />
            </div>
            <div className={"col-md-6"}>
              <Input
                error={requiredErrors.cicle}
                label={t("CycleLabel", locale)}
                value={cicle}
                onChange={(val) => setCicle(val)}
                disabled={calculatingRPE}
                isRequired={true}
                type="number"
                variant="outlined"
                gutter="38px 0 0"
              />
            </div>
            <div className={"col-12"}>
              <Button
                caption={t("CalculateRPELabel", locale)}
                dense
                onClick={calculateRPE}
                loading={calculatingRPE}
              />
            </div>
          </div>
          {rpeResponse && (
            <div className={"row mx-0"}>
              <div className={"col-12"}>
                <div className={`alert alert-${rpeResponse.type}`} role="alert">
                  {rpeResponse.message ? (
                    rpeResponse.message
                  ) : (
                      <div>
                        <p>
                          Reads analyzed: {rpeResponse.data.countReadsAnalyzed}
                        </p>
                        <p>Score: {rpeResponse.data.score}</p>
                        <p>Score type: {rpeResponse.data.scoreTypeDescription}</p>
                      </div>
                    )}
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}
