import React, { useState, useEffect } from "react";
import request from "../../utils/request";
import dateFormater from "../../utils/dateFormater";
import styles from "./styles/LicenseDateComponent.module.css";
import Swal from "sweetalert2";

import LocalizationContext from "../../utils/LocalizationContext";
import NavBar from "../NavBar/NavBar";
import useMenu from "../../utils/Hooks/useMenu";
import ValidateURL from "../../utils/ValidateURL";
import moment from "moment";

export const LicenseDateComponent = () => {
  const menu = useMenu();
  const currentPage = {
    menuGroup: menu?.License,
    key: "License",
  };

  const [companyLicenses, setCompanyLicenses] = useState([]);
  const [companyUsers, setCompanyUsers] = useState([]);
  const [dist, setDist] = useState([]);
  const [filter, setFilter] = useState("");
  const [distSelected, setDistSelected] = useState(null);
  const [companies, setCompanies] = useState([]);
  const [filteredCompanies, setFilteredCompanies] = useState(companies);

  const [companySelected, setCompanySelected] = useState(null);
  const [viewUsers, setViewUsers] = useState(false);
  const [viewLicenses, setViewLicenses] = useState(false);
  const [selectedLicense, setSelectedLicense] = useState({
    LicenseId: "",
    DaysToAdd: 0,
    LicenseStatus: "",
    Observations: "",
  });
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    const getDistributors = async () => {
      try {
        const distOptions = await request().get(`/api/distributor`);
        setDist(distOptions.data);
      } catch (error) {
        console.error("Error fetching distributors:", error);
      }
    };

    getDistributors();
  }, []);

  const getCompanyList = async (distId) => {
    try {
      const response = await request().get(
        `/api/Company/CompanyList?distId=${distId}`
      );
      setCompanies(response.data);
      setFilteredCompanies(response.data);
    } catch (error) {
      console.error("Error fetching companies:", error);
    }
  };

  const handleDistChange = (e) => {
    const selectedDistId = e.target.value;
    setDistSelected(selectedDistId);
    getCompanyList(selectedDistId);
  };

  const handleCompanyChange = (e) => {
    const selectedCompany = e.target.value;
    setCompanySelected(selectedCompany);
    handleCompanySelected(selectedCompany);
  };

  const handleCompanySelected = async (companyId) => {
    try {
      const licenses = await request().get(
        `/api/LicenseCode/CompanyLicensesById?companyId=${companyId}`
      );
      const users = await request().get(
        `/api/Company/CompanyUsersByCompanyId?companyId=${companyId}`
      );
      setCompanyLicenses(licenses.data);
      setCompanyUsers(users.data.users);
    } catch (error) {
      console.error("Error fetching information:", error);
    }
  };

  const handleViews = (menuOption) => {
    if (menuOption === "users") {
      setViewUsers(!viewUsers);
      setViewLicenses(false);
    } else if (menuOption === "licenses") {
      setViewLicenses(!viewLicenses);
      setViewUsers(false);
    } else {
      setViewUsers(false);
      setViewLicenses(false);
    }
  };

  const handleLicenseSelected = (license) => {
    setSelectedLicense({
      LicenseId: license.licenseId,
      EndDate: license.endDate,
      StartDate: license.startDate,
      LicenseStatus: license.licenseStatus,
      Observations: license.observations,
    });
    setIsEditing(true);
  };
  const handleDeleteDialog = (licenseId) => {
    Swal.fire({
      title: "Confirmar borrado de licencia",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Borrar licencia",
    }).then((result) => {
      if (result.isConfirmed) {
        handleDelete(licenseId);
      }
    });
  };

  const handleDelete = async (licenseId) => {
    try {
      await request().delete(`/api/License?licenseId=${licenseId}`);
      handleCompanySelected(companySelected);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Request Failed",
        text: `Request failed: ${error.response.data.message}`, // Muestra el mensaje de error específico
        confirmButtonText: "OK",
      });
    }
  };

  const handleCancelClick = () => {
    setIsEditing(false);
  };

  const handleSaveClick = async () => {
    try {
      const { LicenseId, DaysToAdd, LicenseStatus, Observations } =
        selectedLicense;

      const formattedLicense = {
        LicenseId,
        DaysToAdd,
        LicenseStatus: selectedLicense.LicenseStatus
          ? mapLicenseStatus(selectedLicense.LicenseStatus)
          : mapLicenseStatus(LicenseStatus),
        Observations,
      };
      await request().put("/api/License/LicenseInfo", formattedLicense);
      setIsEditing(false);
      handleCompanySelected(companySelected);
    } catch (error) {
      Swal.fire({
        icon: "error",
        title: "Request Failed",
        text: `Request failed: ${error.response.data.message}`,
        confirmButtonText: "OK",
      });
    }
  };

  const handleCompanyFilter = (searchTerm) => {
    setFilter(searchTerm);
    const filteredList = companies.filter(
      (company) =>
        company.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        company.companyId.toString().includes(searchTerm)
    );
    setFilteredCompanies(filteredList);
    if (filteredList.length === 1) {
      const selectedCompany = filteredList[0].companyId;
      setCompanySelected(selectedCompany);
      handleCompanyChange({ target: { value: selectedCompany } });
    }
  };
  const setAsAdministrator = async (userId) => {
    console.log(userId);
    Swal.fire({
      title: "Confirmar cambio de administrador",
      showCancelButton: true,
      confirmButtonColor: "#00b189",
      cancelButtonColor: "#d33",
      cancelButtonText: "Cancelar",
      confirmButtonText: "Seleccionar como nuevo administrador",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await request().post(
            `/api/company/ModifyAdmin?newAdminId=${userId}`
          );

          if (response.status === 200) {
            handleCompanySelected(companySelected);
          } else {
            console.error("Error al cambiar el administrador", response);
          }
        } catch (error) {
          console.error(
            "Error en la solicitud de cambio de administrador",
            error
          );
        }
      }
    });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedLicense((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const mapLicenseStatus = (status) => {
    switch (status) {
      case "0":
        return "Requested";
      case "2":
        return "Active";
      case "3":
        return "Expired";
      case "4":
        return "Suspended";
      case "5":
        return "Cancelled";
      default:
        return status;
    }
  };

  return (
    <ValidateURL currentPage={currentPage}>
      <LocalizationContext.Provider value={{}}>
        <NavBar MenuOptions={menu} currentPage={currentPage}>
          <div className={styles.container}>
            <div className={styles.menuOptions}>
              <div className={styles.firstRow}>
                <select
                  onChange={handleDistChange}
                  value={distSelected || ""}
                  className={styles.select}
                >
                  <option value="" disabled>
                    Elegir distribuidor
                  </option>
                  {dist.map((distOption) => (
                    <option key={distOption.id} value={distOption.id}>
                      {distOption.name}
                    </option>
                  ))}
                </select>
                {companies.length > 0 && (
                  <>
                    <input
                      type="text"
                      value={filter}
                      onChange={(e) => handleCompanyFilter(e.target.value)}
                      placeholder="Buscar compañía"
                      className={styles.input}
                    />
                    <select
                      onChange={handleCompanyChange}
                      value={companySelected || ""}
                      className={styles.select}
                    >
                      <option value="" disabled>
                        Elegir compañía
                      </option>
                      {filteredCompanies.map((company) => (
                        <option
                          key={company.companyId}
                          value={company.companyId}
                        >
                          {company.name}
                        </option>
                      ))}
                    </select>
                  </>
                )}
              </div>
              {companySelected && (
                <div>
                  <button
                    onClick={() => handleViews("licenses")}
                    className={styles.button}
                  >
                    Ver licencias de la compañía
                  </button>
                  <button
                    onClick={() => handleViews("users")}
                    className={styles.button}
                  >
                    Ver usuarios de la compañía
                  </button>
                </div>
              )}
            </div>

            {viewLicenses && companyLicenses.length > 0 ? (
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>License ID</th>
                    <th>Code</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                    <th>License Status </th>
                    <th>Observations</th>
                    <th>License Product Name</th>
                    <th>Bionova Cloud License</th>
                    <th>Period Name</th>
                    <th>Trial</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {companyLicenses.map((license) => (
                    <tr key={license.licenseId}>
                      <td>{license.licenseId}</td>
                      <td>{license.code}</td>
                      {license.licenseDetails.map((detail) => (
                        <React.Fragment key={detail.licenseCodeId}>
                          <td>
                            {/* {isEditing &&
                            selectedLicense.LicenseId === license.licenseId ? (
                              <>
                                <p>
                                  Fecha a modificar:
                                  {moment(detail.startDate).format(
                                    "DD/MM/YYYY"
                                  )}
                                </p>
                                <input
                                  type="date"
                                  name="StartDate"
                                  value={selectedLicense.StartDate}
                                  onChange={handleInputChange}
                                />
                              </>
                            ) : ( */}
                            {moment(detail.startDate).format("DD/MM/YYYY")}
                            {/* )} */}
                          </td>
                          <td>
                            {isEditing &&
                            selectedLicense.LicenseId === license.licenseId ? (
                              <>
                                <div className={styles.editFields}>
                                  {" "}
                                  Fecha a modificar:
                                  {moment(detail.endDate).format("DD/MM/YYYY")}
                                </div>
                                <div className={styles.editFields}>
                                  Días a modificar:
                                  <input
                                    type="number"
                                    name="DaysToAdd"
                                    className={styles.inputNumber}
                                    value={selectedLicense.DaysToAdd}
                                    onChange={handleInputChange}
                                  />
                                </div>
                              </>
                            ) : (
                              moment(detail.endDate).format("DD/MM/YYYY")
                            )}
                          </td>
                          <td>
                            {isEditing &&
                            selectedLicense.LicenseId === license.licenseId ? (
                              <select
                                name="LicenseStatus"
                                value={selectedLicense.LicenseStatus}
                                onChange={handleInputChange}
                              >
                                <option value={license.LicenseStatus} disabled>
                                  Seleccione una opción
                                </option>
                                <option value="0">Requested</option>
                                <option value="2">Active</option>
                                <option value="3">Expired</option>
                                <option value="4">Suspended</option>
                                <option value="5">Cancelled</option>
                              </select>
                            ) : (
                              mapLicenseStatus(detail.licenseStatusDescription)
                            )}
                          </td>
                          <td>
                            {isEditing &&
                            selectedLicense.LicenseId === license.licenseId ? (
                              <input
                                type="text"
                                name="Observations"
                                value={selectedLicense.Observations}
                                onChange={handleInputChange}
                              />
                            ) : (
                              detail.observations
                            )}
                          </td>
                          <td>{detail.licenseProductName}</td>
                          <td>{detail.isBionovaCloudLicense ? "Yes" : "No"}</td>
                          <td>{detail.licensePeriodObject.periodName}</td>
                          {/* <td>{detail.licensePeriodObject.days}</td> */}
                          <td>
                            {detail.licensePeriodObject.trial ? "Yes" : "No"}
                          </td>
                          <td className={styles.buttonContainer}>
                            {isEditing &&
                            selectedLicense.LicenseId === license.licenseId ? (
                              <>
                                <button onClick={handleSaveClick}>Save</button>
                                <button onClick={handleCancelClick}>
                                  Cancel
                                </button>
                              </>
                            ) : (
                              <button
                                onClick={() =>
                                  handleLicenseSelected({
                                    ...license,
                                    ...detail,
                                  })
                                }
                              >
                                Editar
                              </button>
                            )}
                            <button
                              onClick={() =>
                                handleDeleteDialog(license.licenseId)
                              }
                            >
                              Borrar
                            </button>
                          </td>
                        </React.Fragment>
                      ))}
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className={styles.noData}></p>
            )}

            {viewUsers && companyUsers.length > 0 ? (
              <table className={styles.table}>
                <thead>
                  <tr>
                    <th>User ID</th>
                    <th>Name</th>
                    <th>Lastname</th>
                    <th>Email</th>
                    <th>Sector ID</th>
                    <th>Sector name</th>
                    <th>User Type</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {companyUsers.map((user) => (
                    <tr key={user.userId}>
                      <td>{user.userId}</td>
                      <td>{user.name}</td>
                      <td>{user.lastname}</td>
                      <td>{user.email}</td>
                      <td>{user.sectorId}</td>
                      <td>{user.sectorName}</td>
                      <td>{user.userTypeDescription}</td>
                      <td>
                        {user.userTypeDescription !== "ADMINISTRATOR" ? (
                          <button
                            onClick={() => setAsAdministrator(user.userId)}
                          >
                            Hacer administrador
                          </button>
                        ) : null}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p className={styles.noData}></p>
            )}
          </div>
        </NavBar>
      </LocalizationContext.Provider>
    </ValidateURL>
  );
};
