import React, { useState } from 'react';
import { useTheme, makeStyles } from '@material-ui/core/styles';
import useStyles from "./DownloadManagerStyles";
import { Card, CardActionArea, Container, CardMedia, CardContent, CardActions, Button, Box } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import request from '../../utils/request';
import { CircularProgress } from "@material-ui/core";
import t from "../../utils/translation";
import { getDist } from '../../utils/sessionHandler';
import Redirect from "../../utils/Redirect";
import FixedAlert from '../FixedAlert/FixedAlert';
import Progress from '../Progress';

const useStylesContainer = makeStyles(() => ({
    root: {
    }
}));

const DownloadManager = ({  apiMethod, translations, allowGoToMainPage, prefixTranslations }) => {
    const theme = useTheme();
    const classesContainer = useStylesContainer();
    const classes = useStyles();
    const [downloading, setDownloading] = useState(false);
    const [error, setError] = useState(); 

    const downloadFile = async () => {
        if (!downloading) {
            setDownloading(true)
            request().get(`/api/DownloaderManager/${apiMethod}?distributorId=${getDist().id}`).then(resp => {
                window.open(resp.data, "_blank")
                setTimeout(() => {
                    setDownloading(false)
                }, 10000);
            })
            .catch(err => {
                setError(err?.response?.data?.Message)
            })
        }
    }

    const goMainPage = () => {
        Redirect({
            redirect: true,
            path: "/login"
        })
    }
    return (
        <Container className={classesContainer.root} maxWidth={"sm"}>
            <FixedAlert text={error} type={'error'} closeErrorCb={() => { setError("") }}></FixedAlert>
            <div className={classes.cardContainer}>
                <Card className={classes.root}>
                    <Progress active={downloading} />
                    <CardActionArea onClick={() => { downloadFile() }}>
                     <CardMedia
                            className={classes.media}
                            image={theme.palette.images.logoBionovaOscuro}
                            title="biocloud-img"
                        /> 
                        <CardContent>
                            <Typography gutterBottom variant="h5" component="h2">
                                {t(`${prefixTranslations}Label`, translations) || "Agent"}
                            </Typography>
                            {
                                downloading &&
                                <>
                                    <CircularProgress></CircularProgress>
                                    <div>
                                        {t("Downloading", translations) || "Downloading..."}
                                    </div>
                                </>
                            }
                            <Typography variant="body2" color="textSecondary" component="p" style={{display: 'none'}}>
                                {t(`${prefixTranslations}Description`, translations) || "It's necessary to install the agent for Bionova Cloud to recognize the devices"}
                                <br />
                                {/* {prefixTranslations === 'Agent' && (t("AgentClickHere", translations) || "Click here to download the last version of agent")} */}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button  color="primary"  onClick={() => { downloadFile() }}>
                            {t("Download") || "Download"}
                        </Button>                           
                        {
                            allowGoToMainPage &&
                            <Button color="primary" onClick={() => { goMainPage() }}>
                                Go To Main Page
                            </Button>
                        }
                    </CardActions>
                </Card>
            </div>
        </Container>
    );
};

export default DownloadManager;