import React, { useEffect, useRef, useCallback, useContext } from 'react';
import { useSnackbar } from 'notistack';
import request from '../../utils/request';
import { SnackbarProvider } from 'notistack';
import useStyles from './AppMainStyles';
import { getUserId, getMenu, getSession } from '../../utils/sessionHandler';
import Alert from '@material-ui/lab/Alert';
import IconCard from '../../components/IconCard/IconCard';
import DashboardModule from '../../components/Dashboard/DashboardModule';
import LocalizationContext from "../../utils/LocalizationContext";
import AccordionDownloader from '../../components/Downloader/AccordionDownloader';
import { useTheme } from '@material-ui/core/styles';

function AppMain() {
	const translations = useContext(LocalizationContext);
	const theme = useTheme();
	const menu = getMenu();
	const classes = useStyles();
	const { enqueueSnackbar } = useSnackbar();

	const getDownloadManager = () => {
		return (
			<div className={'col-lg-12'} style={{marginTop: 12}}>
          		<AccordionDownloader translations={translations}></AccordionDownloader>
        	</div>
		)
	}

	const newNotification = useCallback(
		(notification, variant) => {
			enqueueSnackbar({ notification, variant });
		},
		[enqueueSnackbar]
	);

	useEffect(() => {
		request()
			.get(`/api/appnotification?userLoggedId=${getUserId()}`)
			.then((response) => {
				response.data.map((n) =>
					newNotification(
						n,
						(n.notificationMessageType === 0) ? "info" :
							(n.notificationMessageType === 1) ? "warning" :
								(n.notificationMessageType === 2) ? "error" :
									"default"
					)
				);
			})
			.catch((error) => console.log(error));
	}, [newNotification]);

	if (getSession().UserType === 0) {
		return (
			<>
				{getDownloadManager()}
				{/* <IconCard
					title={menu.BI.Translation}
					icon={menu.BI.Image}
					url={menu.Dashboard.Items.find((x) => Object.keys(x)[0] === "Historical")?.Historical?.Url}
					parameters={{ read: 'ReadingBI' }}>
				</IconCard>
				<IconCard
					title={menu.PI.Translation}
					icon={menu.PI.Image}
					url={menu.Dashboard.Items.find((x) => Object.keys(x)[0] === "Historical")?.Historical?.Url}
					parameters={{ read: 'ReadingPRO' }}>
				</IconCard>
				<IconCard
					title={menu.CHE.Translation + ' ' + menu.CHE.Items.find((x) => Object.keys(x)[0] === "Sterilizer")?.Sterilizer?.Translation}
					icon={menu.CHE.Items.find((x) => Object.keys(x)[0] === "Sterilizer")?.Sterilizer?.Image}
					url={menu.Dashboard.Items.find((x) => Object.keys(x)[0] === "Historical")?.Historical?.Url}
					parameters={{ read: 'readingchesterilizer' }}></IconCard>
				<IconCard
					title={menu.CHE.Translation + ' ' + menu.CHE.Items.find((x) => Object.keys(x)[0] === "Washer")?.Washer?.Translation}
					icon={menu.CHE.Items.find((x) => Object.keys(x)[0] === "Washer")?.Washer?.Image}
					url={menu.Dashboard.Items.find((x) => Object.keys(x)[0] === "Historical")?.Historical?.Url}
					parameters={{ read: 'readingchewasher' }}>
				</IconCard>
				<IconCard
					title={menu.Incubator.Items.find((x) => Object.keys(x)[0] === "ConnectedIncubators")?.ConnectedIncubators.Translation}
					icon={menu.Incubator.Items.find((x) => Object.keys(x)[0] === "ConnectedIncubators")?.ConnectedIncubators.Image}
					url={menu.Incubator.Items.find((x) => Object.keys(x)[0] === "ConnectedIncubators")?.ConnectedIncubators.Url}>
				</IconCard>
				<IconCard
					title={menu.License.Items.find((x) => Object.keys(x)[0] === "MyLicenses")?.MyLicenses.Translation}
					icon={menu.License.Items.find((x) => Object.keys(x)[0] === "MyLicenses")?.MyLicenses.Image}
					url={menu.License.Items.find((x) => Object.keys(x)[0] === "MyLicenses")?.MyLicenses.Url}>
				</IconCard>*/}
				<DashboardModule translations={translations} panelOptios={theme ? theme.appMainPanel : null}></DashboardModule>
			</>
		);
	}
	else if (getSession().UserType === 1) {
		return (
			<>
				{getDownloadManager()}
				<DashboardModule translations={translations} panelOptios={theme ? theme.appMainPanel : null}></DashboardModule>
				{/* <IconCard
					title={menu.BI.Translation}
					icon={menu.BI.Image}
					url={menu.Dashboard.Items.find((x) => Object.keys(x)[0] === "Historical")?.Historical?.Url}
					parameters={{ read: 'ReadingBI' }}>
				</IconCard>
				<IconCard
					title={menu.PI.Translation}
					icon={menu.PI.Image}
					url={menu.Dashboard.Items.find((x) => Object.keys(x)[0] === "Historical")?.Historical?.Url}
					parameters={{ read: 'ReadingPRO' }}>
				</IconCard>
				<IconCard
					title={menu.CHE.Translation + ' ' + menu.CHE.Items.find((x) => Object.keys(x)[0] === "Sterilizer")?.Sterilizer?.Translation}
					icon={menu.CHE.Items.find((x) => Object.keys(x)[0] === "Sterilizer")?.Sterilizer?.Image}
					url={menu.Dashboard.Items.find((x) => Object.keys(x)[0] === "Historical")?.Historical?.Url}
					parameters={{ read: 'readingchesterilizer' }}>
				</IconCard>
				<IconCard
					title={menu.CHE.Translation + ' ' + menu.CHE.Items.find((x) => Object.keys(x)[0] === "Washer")?.Washer?.Translation}
					icon={menu.CHE.Items.find((x) => Object.keys(x)[0] === "Washer")?.Washer?.Image}
					url={menu.Dashboard.Items.find((x) => Object.keys(x)[0] === "Historical")?.Historical?.Url}
					parameters={{ read: 'readingchewasher' }}>
				</IconCard>*/}
			</>
		);
	} else {
		return (getDownloadManager())
	}
}

export default function AppMainNotificationProvider() {
	const classes = useStyles();
	const notistackRef = useRef(null)

	const handleNotificationClose = (notification, key) => {
		request().post('/api/appnotification/view', notification)
			.then(() => notistackRef.current.closeSnackbar(key))
			.catch(error => console.log(error))
	}

	const handleNotificationRedirect = (notification) => {
		request().post('/api/appnotification/view', notification)
			.then(() => window.location.href = '/' + notification.urlDescription)
			.catch(error => console.log(error))
	}

	return (
		<SnackbarProvider
			maxSnack={100}
			ref={notistackRef}
			autoHideDuration={9999999}
			anchorOrigin={{ vertical: "top", horizontal: "right" }}
			content={(key, { notification, variant }) => (
				<div
					className={classes.root}
					onClick={() => handleNotificationRedirect(notification)}
				>
					<Alert
						severity={variant}
						elevation={6}
						variant="filled"
						onClose={(e) => {
							handleNotificationClose(notification, key);
							e.stopPropagation();
						}}
					>
						<div>{notification.notificationDetail}</div>
					</Alert>
				</div>
			)}
		>
			<AppMain />
		</SnackbarProvider>
	);
}


