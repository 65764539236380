import React, { useState, useRef } from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Grow from '@material-ui/core/Grow';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import MenuItem from '@material-ui/core/MenuItem';
import MenuList from '@material-ui/core/MenuList';
import useStyles from './SplitButtonStyles'
import propTypes from "prop-types";

const SplitButton = ({ options, selectedOption, localStorageSelectedOption, disabled}) => {
    const [open, setOpen] = useState(false)
    const anchorRef = useRef(null);
    const actuallyOptionSaved = localStorage.getItem(localStorageSelectedOption);
    const result = (options.length - 1) < actuallyOptionSaved
    const [selectedIndex, setSelectedIndex] = useState(result ? selectedOption : actuallyOptionSaved || selectedOption)

    const classes = useStyles()

    const handleClick = (option) => {
        option.onClickOption(option.text);
    };

    const handleMenuItemClick = (event, index, option) => {
        setSelectedIndex(index);
        localStorage.setItem(localStorageSelectedOption, index)
        setOpen(false);
        option.onClickOption(option.text);
    };

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
        setOpen(false);
    };
    return (
        <Grid container direction="column" alignItems="flex-end">
            <Grid item xs={12}>
                <ButtonGroup variant="contained" color="secondary" classes={classes} ref={anchorRef} aria-label="split button">
                    <Button disabled={disabled} onClick={() => handleClick(options[selectedIndex])}>{options[selectedIndex].text}</Button>
                    {
                        options.length > 1 &&
                        <Button
                            color="secondary"
                            size="small"
                            aria-controls={open ? 'split-button-menu' : undefined}
                            aria-expanded={open ? 'true' : undefined}
                            aria-label="select merge strategy"
                            aria-haspopup="menu"
                            disabled={disabled}
                            onClick={handleToggle}
                        >
                            <ArrowDropDownIcon />
                        </Button>
                    }
                </ButtonGroup>
                <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal>
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom',
                            }}
                        >
                            <Paper>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList id="split-button-menu">
                                        {options.map((option, index) => (
                                            <MenuItem
                                                key={index}
                                                disabled={option.disabled}
                                                selected={index === selectedIndex}
                                                onClick={(event) => handleMenuItemClick(event, index, option)}
                                            >
                                                {option.text}
                                            </MenuItem>
                                        ))}
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>
            </Grid>
        </Grid>
    );
};

export default SplitButton;

SplitButton.propTypes = {
    selectedOption: propTypes.number.isRequired,
};
