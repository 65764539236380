import React, { useState } from 'react'
import ShowPassword from '../../components/Form/Login/ShowPassword';
import LoginInput from '../../components/LoginInput';

const ConfirmPasswordView = (props) => {
    const {locale,passwordConfirma,setPasswordConfirma,setError,error,loading,t,style} = props
    const [viewPasswordConfirma, setViewPasswordConfirma] = useState(false);
const togglePasswordConfirmaVisibility = () => {
    setViewPasswordConfirma(!viewPasswordConfirma);
};

  return (
    <div>
        <LoginInput
        label={t("ConfirmPasswordLabel", locale)} //'Confirm Password'
        claro={true}
        value={passwordConfirma}                 
        onChange={value => {
        setPasswordConfirma(value);
        setError({ type: "", message: "" });
        }}

        error={error.type === "passwordConfirma" ? error.message : ""}
        type={viewPasswordConfirma ? 'text' : 'password'}
        disabled={loading}
        />
    <ShowPassword style={style} parentCallback={()=>{togglePasswordConfirmaVisibility()}} />
    </div>
  )
}

export default ConfirmPasswordView