import React, { useState, useContext } from "react";
import request from "../../../utils/request";
import LocalizationContext from "../../../utils/LocalizationContext";
import {
  getDist,
  login,
  setGeneralObjects,
  setUserLanguage,
  setRegionalFormat,
  setEula,
} from "../../../utils/sessionHandler";
import { parseJwtData } from '../../../utils/functions/ParserJwt';

import Progress from "../../Progress";
import Button from "../../Button";
import { useTheme } from "@material-ui/core";
import ViewPasswordButton from "./ViewPasswordButton"; 
import LoginInput from "../../LoginInput";
import Button2 from "../../Button2";
const iconStyle={
  position:'relative',
  display:'block',
  bottom:'3rem',
  color:'#D6D6D6',
  cursor:'pointer',
  height:'20px',
  left:'470%'
}



export default function Login(props) {
  const { classes, dispatch, email, images } = props;
  const theme = useTheme();
  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState("");
  const [error, setError] = useState({ type: "", message: "" });
  const [
    redirectingToPasswordChange,
    setRedirectingToPasswordChange,
  ] = useState(false);
  const [redirectingText, setRedirectingText] = useState("");

  const locale = useContext(LocalizationContext);

  const checkRequiredFields = () => {
    if (email.length === 0) {
      setError({ type: "email", message: "This field is required" });
      setLoading(false);
      return false;
    }
    if (password.length === 0) {
      setError({ type: "password", message: "This field is required" });
      setLoading(false);
      return false;
    }

    return true;
  };

  const loginSubmit = (e) => {
    document.activeElement.blur();
    e.preventDefault();
    setLoading(true);
    if (!checkRequiredFields()) {
      return;
    }
    const user = { Email: email, Password: password, DistId: getDist().id };

    request()
      .post(`/api/login/login`, user)
      .then((response) => {
        const jwt = response.data;
        const payloadFromToken = parseJwtData(jwt);
        
        const userId = payloadFromToken.Id;

        if (payloadFromToken.MustChangePassword) {
          request()
            .post("/api/appuser/getmustchangepasswordtext", payloadFromToken)
            .then((response) => {
              setRedirectingText(response.data);
              setRedirectingToPasswordChange(true);
              setTimeout(
                () =>
                  dispatch({
                    type: "resetPasswordClick",
                    payload: { id: userId },
                  }),
                7000
              );
            });
        } else {
          if (payloadFromToken.UserType === undefined) {
            login(jwt);
            window.location.href = "/";
            return;
          }

          let country = payloadFromToken.Company.Country;

          const authorizationHeader = {
            headers: { Authorization: "bearer " + jwt },
          };

          Promise.all([
            request().get(
              `/api/GeneralObject/getgeneralobjectjson?UserLoggedId=${userId}`,
              { ...authorizationHeader }
            ),
            request().get(
              `/api/language/GetUserLanguage?userLoggedId=${userId}`,
              { ...authorizationHeader }
            ),
            request().get(
              `/api/regionalformat/GetByUser?userLoggedId=${userId}`,
              { ...authorizationHeader }
            ),
            request().get(
              `/api/EulaCompanies/validateeulacompany/${payloadFromToken.Company.Id}`,
              { ...authorizationHeader }
            ),
          ])
            .then(([generalObjectsData, languageData, regionalFormatData, eula]) => {
              setUserLanguage(languageData.data);
              setGeneralObjects(generalObjectsData.data);
              setRegionalFormat(regionalFormatData.data);
              setEula(eula.data);
              login(jwt);
              
              if (eula.data.acceptedEula || payloadFromToken.UserType !== 0) {
                window.location.href = "/";
              }
              else {
                window.location.href = "/appeula";
              }
            })
            .catch((error) => {
              setError({ type: "alert", message: error.response.data.message });
            });
        }
      })
      .catch((error) => {
        if (!error.response) {
          setError({ type: "alert", message: error.message });
          setPassword("");
        } else {
          setError({ type: "alert", message: error.response.data.message });
          setPassword("");
        }
        setLoading(false);
      });
  };

  if (redirectingToPasswordChange) {
    return (
      <div className={classes.loginForm}>
        <Progress class={classes.loading} active={loading} />
        <div>
          <img
            src={images.logo}
            alt=""
            className={classes.logoTerragene}
          ></img>
        </div>
        <h1 className={classes.loginText}>{locale.header.login}</h1>
        <div className={"alert alert-primary"}>{redirectingText}</div>
      </div>
    );
  }

  return (
    <form className={classes.loginForm} onSubmit={(e) => loginSubmit(e)}>
      <Progress class={classes.loading} active={loading} />
      <div>
      </div>
      {error.type === "alert" && (
        <div className={"alert alert-danger"}>{error.message}</div>
      )}
      <img src={theme.palette.images.logoBionova} className={classes.logoBionova}></img>
      <LoginInput
        gutter={'0'}
        claro={true}
        label={locale.form.input.email}
        value={email}
        onChange={(value) => {
          dispatch({ type: "setEmail", payload: value });
          setError({ type: "", message: "" });
        }}
        error={error.type === "email" ? error.message : ""}
        variant={"standard"}
        disabled={loading}
        /> 

     <ViewPasswordButton
      locale={locale}
      setPassword={setPassword}
      password={password}
      setError={setError}
      error={error}
      loading={loading}
      style={iconStyle}
      /> 

            <p
        className={classes.forgotPasswordLink}
        onClick={() => dispatch({ type: "forgotPasswordClick" })}
      >
        {locale.link.forgotPassword}
      </p>
      <Button2
       
        type={"submit"}
        caption={locale.form.submit.login}
        disabled={loading}
      />
      <p
        className={classes.newCompanyLink}
        onClick={() => dispatch({ type: "signUpClick" })}
      >
        {locale.link.signup}
      </p>
      <div>
        <img
          src={images.logo}
          alt=""
          className={classes.logoTerragene}
        ></img>
      </div>
    </form>
  );
}
