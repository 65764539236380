import React from "react";
import { Redirect } from "react-router-dom";
import PageContentLoading from "../components/PageContentLoading";
import { getMenu } from "../utils/sessionHandler";
import t from "../utils/translation";

export const getMenuItemId = (menuGroup, key) => {
  if (menuGroup.Items.length > 0) {
    return menuGroup.Items.find((i) => Object.keys(i)[0] === key)?.[key].Id;
  } else {
    return menuGroup.Id;
  }
};

function ValidateUrl(props) {
  const { menuGroup, key } = props.currentPage;

  if (getMenu() === null)
    return (
      <div style={{ height: "100vh" }}>
        <PageContentLoading />
      </div>
    );

  if (menuGroup === undefined) {
    return (
      <Redirect
        to={{
          pathname: "/invalidUrl",
          state: { message: t("AccessDeniedMessage") || "Access Denied" },
        }}
      />
    );
  }

  if (getMenuItemId(menuGroup, key) === undefined) {
    return (
      <Redirect
        to={{
          pathname: "/invalidUrl",
          state: { message: t("AccessDeniedMessage") || "Access Denied" },
        }}
      />
    );
  }

  return props.children;
}

export default ValidateUrl;
