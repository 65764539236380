import React, { useState, useContext } from "react";
import request from "../../../utils/request";
import LocalizationContext from "../../../utils/LocalizationContext";
import { useTheme } from '@material-ui/core/styles';
import useStyles from "./UserResetPasswordStyles";
import { Input } from "@material-ui/core";
import Button2 from "../../Button2";
import LoginInput from "../../LoginInput";
import ViewPasswordButton from "./ViewPasswordButton";
import t from "../../../utils/translation";
import Redirect from "../../../utils/Redirect";



const iconStyle={
  position:'relative',
  display:'block',
  bottom:'3rem',
  color:'#D6D6D6',
  cursor:'pointer',
  height:'20px',
  left:'470%'
}


export default function UserResetPassword({params}) {
  let { token, email, distId } = params;

  console.log(params)

  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [error, setError] = useState({ message: null, type: null });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const locale = useContext(LocalizationContext);
  const classes = useStyles();
  const theme =  JSON.parse(localStorage.getItem("__dist"))?.themeFileName


  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*\d).{8,}$/;
    return regex.test(password);
  };

  const checkRequiredFields = () => {

    if (newPassword.length === 0) {
      setError({ type: "newPassword", message: "This field is required" });
      return false;
    }
    
    if (!validatePassword(newPassword)) {
      setError({
        type: 'newPassword',
        message: '8+ characters,1 uppercase letter,1 number.',
      });
      return false;
    }

    if (repeatNewPassword !== newPassword) {
      setError({
        type: "repeatPassword",
        message: "The passwords do not match."
      });
      return false;
    }

    return true;
  };



  const resetPasswordSubmit = e => {
    document.activeElement.blur();
    e.preventDefault();

    if (!checkRequiredFields()) {
      return;
    }

    setLoading(true);

    
    const user = {
      email: email, 
      distId: distId,
      token: token,
      password: newPassword, 
    };


    request()
      .post(`/api/login/password-recovery`, user) 
      .then(response => {
        setSuccess(true);
        setLoading(false);
        setError({ type: "success", message: response.data });
      })
      .catch(error => {
        if (!error.response) {
          return;
        }
        console.log(error.response.data.message);
        setError({ type: "error", message: error.response.data.message });
        setLoading(false);
      });

  };

  return (
    <div className={classes.root}>
      <div className={classes.loginCard}>
      <div>
      {theme === "aspTheme" ? (
        <img src={ "/../../images/aspcloudwhite.png"} alt="" className={classes.logoBionova}/>
        ) : (
        <img src={"/../../images/bionovanuevo.png"} alt="" className={classes.logoBionova}/>
      )}
      </div>
      <h1 className={classes.loginText}> {locale.form.submit.resetPassword}</h1>
      {(error.type === "error" || error.type === "success") && (
        <div
          className={` ${
            error.type === "error" ? "alert-danger" : " alert alert-primary"
          }`}
          role="alert"
        >
          {error.message}
        </div>
      )}
    {!success && (
    <form className={classes.loginForm} onSubmit={e => resetPasswordSubmit(e)}>
      {!success && (
        <LoginInput
          claro={true}
          label={locale.form.input.email}
          value={email}
          type={"text"}
          disabled={true} 
        />
      )}

      {!success && (
      <ViewPasswordButton
      locale={locale}
      setNewPassword={setNewPassword}
      newPassword={newPassword}
      setError={setError}
      error={error}
      loading={loading}
      style={iconStyle}
      success={success}
      setRepeatNewPassword={setRepeatNewPassword}
      repeatNewPassword={repeatNewPassword}
      t={t}
      /> 
      )}

     {/* <Button2
      type={"submit"}
      disabled={loading}
      onClick={() =>
        error.type === "success"
          ? window.location.replace("/login")
          : null
      }
      caption={
        error.type === "success"
          ? locale.form.submit.login
          : locale.eulaForm.acceptButton
      }
     /> */}

    {!success && (
    <Button2
    type={"submit"}
    disabled={loading}
    caption={locale.eulaForm.acceptButton}
    /> 
    )}
     </form>
     )}

   
    {success && (
      <form className={classes.loginForm}>
      <Button2 
      type={"submit"}
      disabled={loading}
      caption= {locale.form.submit.login}
      // onClick={() => window.location.replace("/login")}
      onClick={() =>
        Redirect({
          redirect: true,
          path: "/login",
        })
      }
      />
       </form>
    )}

    <div>
    {theme === "aspTheme" ? (
      null
      ) : (
      <img src={"/../../images/logotipoblanco.png"} alt="" className={classes.logoTerragene}/>
    )}
    </div>
    </div>
    </div>
  );
}
