import React, { useEffect, useContext, useReducer } from 'react';
import LocalizationContext from "../../utils/LocalizationContext";
import Suscription from '../../components/Suscription/Suscription';
import request from '../../utils/request';
import useStyles from "../../pages/Suscriptions/SuscriptionStyles";
import t from "../../utils/translation";
import Button from '../../components/Button';
import PageContentLoading from "../../components/PageContentLoading";
import { getSession } from '../../utils/sessionHandler';
import FixedAlert from '../../components/FixedAlert/FixedAlert';
import RequestLicense from '../../components/RequestLicense/RequestLicense';
import reducer from './SuscriptionsReducer'

const Suscriptions = () => {
    const locale = useContext(LocalizationContext);

    const classes = useStyles();

    const [state, dispatch] = useReducer(reducer, {
        loading: true,
        text: ''
    })

    useEffect(() => {
        Promise.all([
            request().get(`/api/Suscriptions/GetLicensesByIndicatorTypes/`)
        ]).then(([suscriptions]) => {
            dispatch({
                type: 'loadSuscriptions',
                payload: {
                    ...state,
                    loading: false,
                    NormalSuscription: {
                        LicenseProduct: t('NormalSuscription', locale),
                        Suscriptions: suscriptions.data.filter(x => x.licenseProduct.id === 3)
                    },
                    PlusSuscription: {
                        LicenseProduct: t('PlusSuscription', locale),
                        Suscriptions: suscriptions.data.filter(x => x.licenseProduct.id === 2)
                    }
                }
            })
        });
    }, [])

    const onClickConfirm = () => {
        const suscriptions = state.NormalSuscription.Suscriptions.concat(state.PlusSuscription.Suscriptions)
        const companyId = getSession().Company.Id
        const suscriptionsDefinitive = suscriptions.map((suscription) => {
            return {
                ...suscription,
                licenseProduct: null,
                indicatorType: null,
                companyId: companyId
            }
        })
        request().post(`/api/Suscriptions/Save`, suscriptionsDefinitive).then((resp) => {
            dispatch({ type: 'SHOW_ALERT', payload: { text: resp.data, type: 'success' } })
        })
    }

    if (state.loading) return <PageContentLoading />;
    return (
        <div>
            { state.NormalSuscription.Suscriptions && state.NormalSuscription.Suscriptions.length > 0 &&
                <>
                    <div className={classes.header}>
                        <span className={classes.headerText}>
                            {state.NormalSuscription.LicenseProduct}
                        </span>
                    </div>
                    <div className={classes.suscriptionContainer}>
                        {
                            state.NormalSuscription && state.NormalSuscription.Suscriptions.length > 0 && state.NormalSuscription.Suscriptions.map((suscription) => {
                                return (
                                    <Suscription key={suscription.indicatorType.id} dispatch={dispatch} suscription={suscription} locale={locale}></Suscription>
                                )
                            })
                        }
                    </div>
                </>
            }
            { state.PlusSuscription.Suscriptions && state.PlusSuscription.Suscriptions.length > 0 &&
                <>
                    <div className={classes.header}>
                        <span className={classes.headerText}>
                            {state.PlusSuscription.LicenseProduct}
                        </span>
                    </div>
                    <div className={classes.suscriptionContainer}>
                        {
                            state.PlusSuscription.Suscriptions && state.PlusSuscription.Suscriptions.length > 0 && state.PlusSuscription.Suscriptions.map((suscription) => {
                                return (
                                    <Suscription key={suscription.indicatorType.id} dispatch={dispatch} suscription={suscription} locale={locale}></Suscription>
                                )
                            })
                        }
                    </div>
                </>
            }
            {
                ((state.PlusSuscription.Suscriptions && state.PlusSuscription.Suscriptions.length > 0) ||
                (state.NormalSuscription.Suscriptions && state.NormalSuscription.Suscriptions.length > 0)) &&
                <div style={{ paddingTop: 20 }}>
                    <FixedAlert type={state.type} text={state.text} dispatch={dispatch}></FixedAlert>
                    <Button fullwidth={true} caption={t('ConfirmButton')} onClick={() => onClickConfirm()}></Button>
                </div>
            }
            { state.PlusSuscription.Suscriptions && state.PlusSuscription.Suscriptions.length === 0 &&
                state.NormalSuscription.Suscriptions && state.NormalSuscription.Suscriptions.length === 0 &&
                <RequestLicense licenseProductId={2}></RequestLicense>
            }
        </div>
    );
};

export default Suscriptions;