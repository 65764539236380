import React, { useState, useEffect, useContext, useReducer } from "react";
import LocalizationContext from "../../utils/LocalizationContext";
import useStyles from "./InstrumentStyles";
import t from "../../utils/translation";
import request from "../../utils/request";
import { useLocation } from "react-router-dom";
import df from "../../utils/dateFormater";

import {
  getSession,
  getUserId,
  getUserCompany,
  getUserType,
  getDist,
} from "../../utils/sessionHandler";
import _ from "lodash";
import ToolMeasure from "../../components/MeasureToolTable/ToolMeasure";

import reducer from "./InstrumentReducer";
import Select from "../../components/Select";
import CRUD from "../../components/CRUD";
import PageContentLoading from "../../components/PageContentLoading";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const initialState = {
  programs: [],
  products: [],
  washers: [],
  visualResults: [],
  loadingContent: true,
  showToolMeasureComponent: false,
  readingPROToAssociate: {},
  afterAssociate: {
    close: false,
    message: "",
  },
};

export default function Instrument() {
  const classes = useStyles();

  const locale = useContext(LocalizationContext);
  const company = getUserCompany();
  const query = useQuery();

  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState(
    query.get("sectorId") || ""
  );

  const [state, dispatch] = useReducer(reducer, initialState);
  const { loadingContent } = state;

  useEffect(() => {
    request()
      .get(`/api/sector?companyId=${company.Id}`)
      .then((response) => {
        setTimeout(() => {
          setSectors(
            response.data.reduce((sectorOptions, sector) => {
              sectorOptions.push([sector.id, sector.name]);
              return sectorOptions;
            }, [])
          );
        }, 250);
        dispatch({
          type: "SET_CONTENT",
          payload: {},
        });
        dispatch({ type: "SHOW_TOOL_MEASURE" });
      })
      .catch((error) => console.log(error));
  }, [company.Id]);

  const handleAfterAssociate = (serverResponse, updatedObject) => {
    dispatch({ type: "HIDE_TOOL_MEASURE" });
    setLoadingData(true);
    dispatch({
      type: "SET_AFTER_ASSOCIATE",
      payload: {
        afterAssociate: {
          executeAction: true,
          message: serverResponse.message,
          updatedObject: updatedObject,
          type: serverResponse.result,
        },
      },
    });
    dispatch({
      type: "SET_AFTER_ASSOCIATE",
      payload: { afterAssociate: { executeAction: false } },
    });
  };

  if (loadingContent) return <PageContentLoading />;

  return (
    <div>
      {state.showToolMeasureComponent && (
        <ToolMeasure
          readingPRO={state.readingPROToAssociate}
          method={"put"}
          url={"/api/ReadingPRO"}
          handleAfterAssociate={handleAfterAssociate}
        ></ToolMeasure>
      )}
    </div>
  );
}
