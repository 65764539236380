import moment from "moment";
import "moment/min/locales.min";
import { getRegionalFormat, getUserLanguage } from "../utils/sessionHandler";

export const userLanguageCode = () => {
  const regionalFormat = getRegionalFormat();
  if (regionalFormat){
    return regionalFormat.languageCode;
  }

  // Compatibilidad hacia atrás hasta que los usuarios vuelvan a loguearse
  // Luego de una semana de productivo (expiración tokens) se puede eliminar el siguiente código.
  const language = getUserLanguage();
  if (language) {
    switch (language.description) {
      case "CHINESE_SIMPLIFIED":
      case "CHINESE_TRADITIONAL":
        return "zh-cn";
      case "SPANISH":
        return "es";
      case "ITALIAN":
        return "it";
      case "PORTUGUESE":
        return "pt-br";
      case "TURKISH":
        return "tr";
      case "KOREAN":
        return "ko";
      case "JAPANESE":
        return "ja";
      case "GREEK":
        return "el";
      default:
        return "en";
    }
  } else {
    return "en";
  }
};

export const dotnetToMomentLocaleInfo = (languageCode) => {
  languageCode = languageCode.toLowerCase()
  switch (languageCode)
  {
    case "zh-hans-mo":
    case "zh-hans-hk":
    case "zh-hans":
    case "zh-sg":
      return "zh-cn";
    case "zh-hant":
      return "zh-tw";
    default:
      return languageCode;
  }
}

export const dotnetToMomentShortTimeFormat = (timeFormat) => {
  return timeFormat.replaceAll('tt', 'a')
                   .replaceAll('t', 'a')
}

export const dotnetToMomentShortDateFormat = (dateFormat) => {
  return dateFormat.replaceAll('d', 'D')
                   .replaceAll('y', 'Y')
}

export default function dateFormater(date, toFormat) {
  if (!date) return "";

  const dateFormat = getRegionalFormat()?.dateFormat ?? "L";
  const timeFormat = getRegionalFormat()?.timeFormat ?? "LT";

  moment.locale(userLanguageCode());
  const momentDate = moment.utc(date).local();
  if (toFormat === "date") {
    return momentDate.format(dateFormat);
  } else if (toFormat === "dateTime") {
    return momentDate.format(dateFormat) + " " + momentDate.format(timeFormat);
  }
}

