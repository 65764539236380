import React, { useState, useContext } from "react";
import request from '../../../utils/request';
import LocalizationContext from "../../../utils/LocalizationContext";
import { useTheme } from "@material-ui/core";
import Progress from "../../Progress";
import Button from "../../Button";
import { getDist } from "../../../utils/sessionHandler";
import LoginInput from "../../LoginInput";
import Button2 from "../../Button2";

export default function ForgotPassword(props) {
  const { classes, dispatch, state, images } = props;
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({ type: "", message: "" });
  const [success, setSuccess] = useState(false);

  const locale = useContext(LocalizationContext);

  const theme = useTheme();

  const checkRequiredFields = () => {
    if (state.email.length === 0) {
      setError({ type: "userName", message: "This field is required" });
      return false;
    }
    return true;
  };

  const forgotPasswordSubmit = e => {
    document.activeElement.blur()
    e.preventDefault();

    if (!checkRequiredFields()) {
      return;
    }

    setLoading(true);

    const user = { Email: state.email, DistId: getDist().id };
    request()
      .post(`/api/login/forgotPassword`, user)
      .then(response => {
        setLoading(false);
        setError({ type: "success", message: response.data });
        setSuccess(true)
      })
      .catch(error => {
        if (!error.response) { return };
        setLoading(false);
        setError({ type: "error", message: error.response.data.message });
      });
  };

  return (
    <form
      className={classes.loginForm}
      onSubmit={e =>
        error.type === "success"
          ? dispatch({ type: "loginClick" })
          : forgotPasswordSubmit(e)
      }
    >
      <Progress class={classes.loading} active={loading} />
      <img src={theme.palette.images.logoBionova} className={classes.logoBionova} style={{width: theme.palette.images.widthlogoBionovaLogin ? theme.palette.images.widthlogoBionovaLogin : null}}></img>
      <div>
      </div>
      <h1 className={classes.loginText}>{locale.header.forgotPassword}</h1>
      {(error.type === "error" || error.type === "success") && (
        <div
          className={`alert ${error.type === "error" ? "alert-danger" : "alert-primary"
            } `}
          role="alert"
        >
          {error.message}
        </div>
      )}
      { !success && (
        <LoginInput
          claro={true}
          label={locale.form.input.username}
          value={state.email}
          onChange={value => {
            dispatch({ type: 'setEmail', payload: value })
            error.type === "userName" && setError({ type: "", message: "" });
          }}
          type={"text"}
          error={error.type === "userName" ? error.message : ""}
          disabled={loading || success}
        />
      )}
      <Button2
        type={"submit"}
        caption={
          error.type === "success"
            ? locale.form.submit.login
            : locale.form.submit.resetPassword
        }
        disabled={loading}
      />
      {!success &&
        <p
          className={classes.forgotPasswordLink}
          onClick={() => window.location.replace("/login")}
        >
          Go back to login
        </p>
      }
              <img
          src={images.logo}
          alt=""
          className={classes.logoTerragene}
        ></img>
    </form>
    
  );
}
