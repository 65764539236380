import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles((theme) => createStyles({
    root: {
        maxWidth: 345,
        textAlign: 'center',
        // boxShadow: "1px 9px 1px 1px rgba(0,0,0,0.2), 3px 4px 1px 6px rgba(0,0,0,0.14), 0px 1px 3px 0px rgba(0,0,0,0.12)",
    },
    media: {
        height: 110,
    },
    textContainer: {
        margin: 20,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    imagesContainer: {
        margin: 5,
        textAlign: 'center'
    },
    cardContainer: {
        // padding: 10,
        textAlign: 'center',
    },
    logoBionova: {
        width: 110,
        margin: 20
    },

    logoTerragene: {
        width: 170,
        margin: 20
    }
}))
