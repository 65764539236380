import React, { useState } from 'react'
import ShowPassword from '../../components/Form/Login/ShowPassword';
import LoginInput from '../../components/LoginInput';

const NewPasswordView = (props) => {
    const {locale,passwordNuevo,setPasswordNuevo,setError,error,loading,t,style} = props;
    const [viewPasswordNuevo, setViewPasswordNuevo] = useState(false);
    const togglePasswordNuevoVisibility = () => {
        setViewPasswordNuevo(!viewPasswordNuevo);
    };
  return (
    <div>
         <LoginInput label={t("NewPasswordLabel", locale)} // 'New Password'
                            claro={true}
                            value={passwordNuevo}
                            onChange={value => {
                                setPasswordNuevo(value);
                                setError({ type: "", message: "" });
                            }}

                            error={error.type === "passwordNuevo" ? error.message : ""}
                            type={viewPasswordNuevo ? 'text' : 'password'}
                            disabled={loading}
                        />
    <ShowPassword style={style} parentCallback={()=>{togglePasswordNuevoVisibility()}} />
               

    </div>
  )
}

export default NewPasswordView