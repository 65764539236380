import React, { useState } from 'react'
import LoginInput from '../../LoginInput';
import ShowPassword from './ShowPassword';

   const ViewPasswordButton = (props) => {
    const {locale,setPassword,password,setError,error,loading,style} = props;
    const [viewPassword, setViewPassword] = useState(false)
   function changePasswordView(){
   viewPassword ? setViewPassword(false) : setViewPassword(true);
  }
  return (
    <>
        <LoginInput
        gutter={'0'}
        claro={true}
        label={locale.form.input.password}
        value={password}
        onChange={(value) => {
          setPassword(value);
          setError({ type: "", message: "" });
        }}
        error={error.type === "password" ? error.message : ""}
        type={viewPassword ? 'text' : 'password'}
        variant={"standard"}
        disabled={loading}
      />
        <ShowPassword style={style} parentCallback={()=>{changePasswordView()}} />
    </>
  )
}
export default ViewPasswordButton;

