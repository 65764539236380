import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles((theme) => createStyles(
  {
    header: {
      background: theme.palette.terragene.lightMain,
      color: theme.palette.terragene.darkMain,
      textAlign: "center",
      textTransform: "uppercase"
    },

    headerText: {
      fontSize: 15,
      lineHeight: "35px",
      color: theme.palette.terragene.darkMain,
      fontWeight: "bold"
    },

    headerNotLicense:{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '16px 24px',
    },

    headerNotLicenseText:{
      fontSize: 16,
        fontWeight: 500,
        lineHeight: '1.5rem',
        color: '#5a5a5d'
    },

    suscriptionContainer: {
      padding: 10,
      border: 'solid 2px ' + theme.palette.terragene.darkMain,
    }
  }
));
