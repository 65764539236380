import React, {
  useReducer,
  useContext,
  useMemo,
  useEffect,
  useState,
} from "react";
import classNames from "classnames";
import _ from "lodash";

import request from "../../utils/request";
import {
  getRowsPerPage,
  getSession,
  getUserCompany,
  getUserId,
} from "../../utils/sessionHandler";
import LocalizationContext from "../../utils/LocalizationContext";
import t from "../../utils/translation";
import df from "../../utils/dateFormater";
import useStyles from "./HistoricalStyles";

import ToggleButton from "@material-ui/lab/ToggleButton";
import CRUD from "../../components/CRUD";
import HistoricalExportFilter from "../../components/HistoricalExportFilter";
import PageContentLoading from "../../components/PageContentLoading";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { exportToPdf } from "../../utils/readingExporter";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import FixedAlert from "../../components/FixedAlert/FixedAlert";
import { CircularProgress } from "@material-ui/core";
import RequestLicense from "../../components/RequestLicense/RequestLicense";
import Input from "../../components/Input";
import ReportPrinter from "../../components/ReportPrinter/ReportPrinter";
import { ProductType } from "../../dictionaries/productType";
import reducer from "./HistoricalReducer";
import Redirect from "../../utils/Redirect";
import moment from "moment-timezone";
import Button2 from "../../components/Button2";

const formatSelectObject = (objectData, emptyText) => {
  return objectData.reduce(
    (objectsList, object) => {
      objectsList.push([object.id, object.description || object.toString]);
      return objectsList;
    },
    [[null, emptyText]]
  );
};

const initialState = {
  data: [],
  tableActions: [],
  headerText: "Historical",
  loadingData: false,
  columns: [],
  headers: [],
  activeOption: undefined,
  sectors: [],
  users: [],
  incubators: [],
  washers: [],
  sterilizers: [],
  disinfectors: [],
  visualResults: [],
  biResults: [],
  proResults: [],
  cheResults: [],
  pageContentLoading: true,
  filters: {},
  pagination: { Page: 1, PageSize: getRowsPerPage(10) },
  dialogValidation: false,
  errorText: "",
  generatingReport: false,
  reportVisible: false,
  report_html: "",
};

const columnsCollections = {
  BI: [
    { name: "readNumber", canOrder: true },
    { name: "positionNumber", canOrder: true },
    { name: "startedTime", canOrder: true },
    { name: "resultDate", canOrder: true },
    { name: "programName", canOrder: true },
    { name: "programNumber", canOrder: true },
    { name: "averageTemperatureFormat", canOrder: false },
    { name: "result", canOrder: true },
    { name: "visualResultDescription", canOrder: false },
    { name: "product", canOrder: true },
    { name: "loteProduct", canOrder: true },
    { name: "processDescription", canOrder: false },
    { name: "valueD", canOrder: true },
    { name: "conditionSCIB", canOrder: true },
    { name: "bookNumber", canOrder: true },
    { name: "pageNumber", canOrder: true },
    { name: "cycle", canOrder: true },
    { name: "sterilizerName", canOrder: true },
    { name: "sterilizerSerial", canOrder: true },
    { name: "sterilizerBrand", canOrder: true },
    { name: "incubator", canOrder: true },
    { name: "loadNumber", canOrder: true },
    { name: "ticketNumber", canOrder: true },
  ],
  BI_DISINFECTION: [
    { name: "readNumber", canOrder: true },
    { name: "positionNumber", canOrder: true },
    { name: "startedTime", canOrder: true },
    { name: "resultDate", canOrder: true },
    { name: "programName", canOrder: true },
    { name: "programNumber", canOrder: true },
    { name: "averageTemperatureFormat", canOrder: false },
    { name: "result", canOrder: true },
    { name: "visualResultDescription", canOrder: false },
    { name: "product", canOrder: true },
    { name: "loteProduct", canOrder: true },
    { name: "conditionSCIB", canOrder: true },
    { name: "bookNumber", canOrder: true },
    { name: "pageNumber", canOrder: true },
    { name: "disinfectorName", canOrder: true },
    { name: "disinfectorSerial", canOrder: true },
    { name: "disinfectorBrand", canOrder: true },
    { name: "roomId", canOrder: true },
    { name: "roomVolume", canOrder: true },
    { name: "peroxideConcentration", canOrder: true },
    { name: "incubator", canOrder: true },
    { name: "ticketNumber", canOrder: true },
  ],
  PRO: [
    { name: "readNumber", canOrder: true },
    { name: "startedTime", canOrder: true },
    { name: "resultDate", canOrder: true },
    { name: "positionNumber", canOrder: true },
    { name: "visualResultDescription", canOrder: false },
    { name: "averageTemperatureFormat", canOrder: false },
    { name: "cuantitativeResultFormat", canOrder: false },
    { name: "programName", canOrder: false },
    { name: "programNumber", canOrder: true },
    { name: "onlyResultString", canOrder: false },
    { name: "product", canOrder: true },
    { name: "loteProduct", canOrder: true },
    { name: "bookNumber", canOrder: true },
    { name: "pageNumber", canOrder: true },
    { name: "cycle", canOrder: true },
    { name: "tool", canOrder: true },
    { name: "surfaceFormat", canOrder: true },
    { name: "washer", canOrder: true },
    { name: "washerSerial", canOrder: true },
    { name: "washerBrand", canOrder: true },
    { name: "incubator", canOrder: true },
    { name: "alertResult", canOrder: false },
    { name: "actionResult", canOrder: false },
    { name: "proteinFormat", canOrder: false },
    { name: "ticketNumber", canOrder: true },
  ],
  CHESterilizer: [
    { name: "readNumber", canOrder: true },
    { name: "creationTest", canOrder: true },
    { name: "resultDescription", canOrder: false },
    { name: "visualResultDescription", canOrder: true },
    { name: "manufactureDate", canOrder: true },
    { name: "product", canOrder: true },
    { name: "loteProduct", canOrder: true },
    { name: "brand", canOrder: true },
    { name: "expirationDate", canOrder: true },
    { name: "sterilizerName", canOrder: true },
    { name: "sterilizerSerial", canOrder: true },
    { name: "sterilizerBrand", canOrder: true },
    { name: "cycle", canOrder: true },
    { name: "temperature", canOrder: true },
    { name: "packageNumber", canOrder: true },
    { name: "concentration", canOrder: true },
    { name: "relativeDampness", canOrder: true },
    { name: "programNumber", canOrder: true },
    { name: "bookNumber", canOrder: true },
    { name: "pageNumber", canOrder: true },
  ],
  CHEWasher: [
    { name: "readNumber", canOrder: true },
    { name: "creationTest", canOrder: true },
    { name: "resultDescription", canOrder: false },
    { name: "visualResultDescription", canOrder: true },
    { name: "manufactureDate", canOrder: true },
    { name: "product", canOrder: true },
    { name: "loteProduct", canOrder: true },
    { name: "brand", canOrder: true },
    { name: "expirationDate", canOrder: true },
    { name: "cycle", canOrder: true },
    { name: "temperature", canOrder: true },
    { name: "washer", canOrder: true },
    { name: "washerSerial", canOrder: true },
    { name: "washerBrand", canOrder: true },
    { name: "location", canOrder: true },
    { name: "washingTime", canOrder: true },
    { name: "detergentConcentration", canOrder: true },
    { name: "detergentType", canOrder: true },
    { name: "waterPressure", canOrder: true },
    { name: "waterHardness", canOrder: true },
    { name: "programNumber", canOrder: true },
    { name: "bookNumber", canOrder: true },
    { name: "pageNumber", canOrder: true },
  ],
};

export default function Historical(props) {
  const locale = useContext(LocalizationContext);
  const classes = useStyles();

  const [openFilter, setOpenFilter] = useState(false);
  const [validateLicenseError, setValidateLicenseError] = useState("");
  const [image, setImage] = useState(null);

  const headersCollections = useMemo(
    () => ({
      BI: [
        t("BaseReadReadNumber", locale),
        t("BaseBIPROPositionNumber", locale),
        t("BaseBIPROStartedTime", locale),
        t("BaseBIPROResultDate", locale),
        t("BaseBIPROProgram", locale),
        t("BaseReadProgramNumber", locale),
        t("BaseBIPROAverageTemperature", locale),
        t("ReadingBICommonResult", locale),
        t("ReadingBICommonVisualResult", locale),
        t("BaseReadProduct", locale),
        t("BaseReadLoteProduct", locale),
        t("ReadingBISterilizationProcess", locale),
        t("ReadingBISterilizationValueD", locale),
        t("ReadingBICommonConditionSCIB", locale),
        t("BaseReadBookNumber", locale),
        t("BaseReadPageNumber", locale),
        t("ReadingBISterilizationCycle", locale),
        t("ReadingBISterilizationSterilizerName", locale),
        t("ReadingBISterilizationSterilizerSerial", locale),
        t("ReadingBISterilizationSterilizerBrand", locale),
        t("BaseBIPROIncubator", locale),
        t("BISterilizerLoadNumber", locale),
        t("BaseBIPROTicketNumber", locale),
      ],
      BI_DISINFECTION: [
        t("BaseReadReadNumber", locale),
        t("BaseBIPROPositionNumber", locale),
        t("BaseBIPROStartedTime", locale),
        t("BaseBIPROResultDate", locale),
        t("BaseBIPROProgram", locale),
        t("BaseReadProgramNumber", locale),
        t("BaseBIPROAverageTemperature", locale),
        t("ReadingBICommonResult", locale),
        t("ReadingBICommonVisualResult", locale),
        t("BaseReadProduct", locale),
        t("BaseReadLoteProduct", locale),
        t("ReadingBICommonConditionSCIB", locale),
        t("BaseReadBookNumber", locale),
        t("BaseReadPageNumber", locale),
        t("ReadingBIDisinfectionDisinfectorName", locale),
        t("ReadingBIDisinfectionDisinfectorSerial", locale),
        t("ReadingBIDisinfectionDisinfectorBrand", locale),
        t("ReadingBIDisinfectionRoomId", locale),
        t("ReadingBIDisinfectionRoomVolume", locale),
        t("ReadingBIDisinfectionPeroxideConcentration", locale),
        t("BaseBIPROIncubator", locale),
        t("BaseBIPROTicketNumber", locale),
      ],
      PRO: [
        t("BaseReadReadNumber", locale),
        t("BaseBIPROStartedTime", locale),
        t("BaseBIPROResultDate", locale),
        t("BaseBIPROPositionNumber", locale),
        t("ReadingPROVisualResult", locale),
        t("BaseBIPROAverageTemperature", locale),
        t("ReadingPROProtein", locale),
        t("BaseBIPROProgram", locale),
        t("BaseReadProgramNumber", locale),
        t("ReadingPROResult", locale),
        t("BaseReadProduct", locale),
        t("BaseReadLoteProduct", locale),
        t("BaseReadBookNumber", locale),
        t("BaseReadPageNumber", locale),
        t("BaseCHECycleNumber", locale),
        t("ReadingPROTool", locale),
        t("AreaLabel", locale),
        t("ReadingPROWasherName", locale),
        t("ReadingPROWasherSerial", locale),
        t("ReadingPROWasherBrand", locale),
        t("BaseBIPROIncubator", locale),
        t("ProteinAlertLabel", locale),
        t("ProteinActionLabel", locale),
        t("ReadingPROUmbral", locale),
        t("BaseBIPROTicketNumber", locale),
      ],
      CHESterilizer: [
        t("BaseReadReadNumber", locale),
        t("BaseCHECreationTestDate", locale),
        t("BaseCHEResult", locale),
        t("BaseCHEVisualResult", locale),
        t("BaseCHEManufactureDate", locale),
        t("BaseReadProduct", locale),
        t("BaseReadLoteProduct", locale),
        t("BaseCHEBrand", locale),
        t("BaseCHEExpirationDate", locale),
        t("ReadingCHESterilizerSterilizerName", locale),
        t("ReadingCHESterilizerSterilizerSerial", locale),
        t("ReadingCHESterilizerSterilizerBrand", locale),
        t("BaseCHECycleNumber", locale),
        t("BaseCHETemperature", locale),
        t("ReadingCHESterilizerPackageNumber", locale),
        t("ReadingCHESterilizerConcentration", locale),
        t("ReadingCHESterilizerRelativeDampness", locale),
        t("BaseReadProgramNumber", locale),
        t("BaseReadBookNumber", locale),
        t("BaseReadPageNumber", locale),
      ],
      CHEWasher: [
        t("BaseReadReadNumber", locale),
        t("BaseCHECreationTestDate", locale),
        t("BaseCHEResult", locale),
        t("BaseCHEVisualResult", locale),
        t("BaseCHEManufactureDate", locale),
        t("BaseReadProduct", locale),
        t("BaseReadLoteProduct", locale),
        t("BaseCHEBrand", locale),
        t("BaseCHEExpirationDate", locale),
        t("BaseCHECycleNumber", locale),
        t("BaseCHETemperature", locale),
        t("ReadingCHEWasherWasherName", locale),
        t("ReadingCHEWasherWasherSerial", locale),
        t("ReadingCHEWasherWasherBrand", locale),
        t("ReadingCHEWasherLocation", locale),
        t("ReadingCHEWasherWashingTime", locale),
        t("ReadingCHEWasherDetergentConcentration", locale),
        t("ReadingCHEWasherDetergentType", locale),
        t("ReadingCHEWasherWaterPressure", locale),
        t("ReadingCHEWasherWaterHardness", locale),
        t("BaseReadProgramNumber", locale),
        t("BaseReadBookNumber", locale),
        t("BaseReadPageNumber", locale),
      ],
    }),
    [locale]
  );

  const [state, dispatch] = useReducer(reducer, {
    ...initialState,
    pagination: { ...initialState.pagination, PageSize: getRowsPerPage() },
  });

  const {
    data,
    headerText,
    loadingData,
    columns,
    headers,
    activeOption,
    sectors,
    users,
    incubators,
    washers,
    sterilizers,
    disinfectors,
    biResults,
    proResults,
    cheResults,
    visualResults,
    filters,
    pagination,
    pageContentLoading,
    totalRows,
    dialogValidation,
    errorText,
    generatingReport,
    reportVisible,
    report_html,
  } = state;

  useEffect(() => {
    if (
      activeOption &&
      (activeOption == "ReadingBI" ||
        activeOption == "ReadingBIDisinfection" ||
        activeOption == "ReadingPRO")
    ) {
      let productType = 1;
      if (activeOption == "ReadingBI")
        productType = ProductType.BiologicalSterilizer;
      if (activeOption == "ReadingBIDisinfection")
        productType = ProductType.BiologicalDisinfection;
      request()
        .get(
          `/api/${activeOption}/GetIncubatorsFromReadings?productType=${productType}`
        )
        .then((resp) => {
          dispatch({
            type: "SET_SELECTBOX_INCUBATORS",
            payload: formatSelectObject(resp.data),
          });
        });
    }
  }, [activeOption]);

  useEffect(() => {
    dispatch({ type: "TOGGLE_LOADING" });
    Promise.all([
      request().get(`/api/Sector?companyid=${getUserCompany().Id}`),
      request().get(
        `/api/appuser/getbycompany?companyId=${getUserCompany().Id}`
      ),
      request().get(`/api/Enum/GetVisualResultsBI`),
      request().get(`/api/Washer/GetByUserLogged`),
      request().get(`/api/Sterilizer/GetByUserLogged`),
      request().get(`/api/Disinfector/GetByUserLogged`),
      request().get(`/api/ReadingBI/GetPossibleResults`),
      request().get(`/api/ReadingPRO/GetPossibleResults`),
      request().get(`/api/ReadingCHESterilizer/GetPossibleResults`),
    ]).then(
      ([
        sectorsData,
        usersData,
        visualResultsData,
        washers,
        sterilizers,
        disinfectors,
        biStatus,
        proStatus,
        cheStatus,
      ]) => {
        dispatch({
          type: "SET_CONTENT",
          payload: {
            sectors: formatSelectObject(sectorsData.data, ""), //t("ChooseASector", locale)
            users: formatSelectObject(usersData.data, ""), //t("UserLabel", locale)
            visualResults: formatSelectObject(
              visualResultsData.data,
              t("ChooseAVisualResult", locale)
            ),
            washers: formatSelectObject(washers.data, ""),
            sterilizers: formatSelectObject(sterilizers.data, ""),
            disinfectors: formatSelectObject(disinfectors.data, ""),
            biResults: formatSelectObject(biStatus.data, ""),
            proResults: formatSelectObject(proStatus.data, ""),
            cheResults: formatSelectObject(cheStatus.data, ""),
          },
        });
      }
    );
  }, [locale, props.defaultReadOption]);

  const getTableHeader = (reading) => {
    return reading === "ReadingBI"
      ? t("BiologicalIndicatorLabel", locale) + " - " + t("SterilizationLabel", locale)
      : reading === "ReadingBIDisinfection"
      ? t("BiologicalIndicatorLabel", locale) + " - " + t("DisinfectionLabel", locale)
      : reading === "ReadingPRO"
      ? t("ProteinIndicatorLabel", locale)
      : reading === "readingchesterilizer"
      ? t("ChemicalIndicatorsLabel", locale) + " - " + t("ChemicalSterilizerLabel", locale)
      : reading === "readingchewasher"
      ? t("ChemicalIndicatorsLabel", locale) + " - " + t("ChemicalWasherLabel", locale)
      : "";
  };

  const getColumns = (reading) => {
    return reading === "ReadingBI"
      ? columnsCollections.BI
      : reading === "ReadingBIDisinfection"
      ? columnsCollections.BI_DISINFECTION
      : reading === "ReadingPRO"
      ? columnsCollections.PRO
      : reading === "readingchesterilizer"
      ? columnsCollections.CHESterilizer
      : reading === "readingchewasher"
      ? columnsCollections.CHEWasher
      : null;
  };
  const getHeaders = (reading) => {
    return reading === "ReadingBI"
      ? headersCollections.BI
      : reading === "ReadingBIDisinfection"
      ? headersCollections.BI_DISINFECTION
      : reading === "ReadingPRO"
      ? headersCollections.PRO
      : reading === "readingchesterilizer"
      ? headersCollections.CHESterilizer
      : reading === "readingchewasher"
      ? headersCollections.CHEWasher
      : null;
  };

  const extractIncubatorsFromReadings = (readings) => {
    return readings.map((r) => {
      return {
        description: r.incubator,
        id: r.incubatorId,
      };
    });
  };

  const getHistorical = (
    reading,
    Filter = filters,
    Pagination = pagination
  ) => {
    
    if (reading !== activeOption) {
      dispatch({ type: "CLEAR_FILTERS" });
      Filter = initialState.filters;  
      Pagination = { ...Pagination, OrderBy: null, OrderType: null };
    }

    dispatch({
      type: "TOGGLE_LOADING_DATA",
      payload: { activeOption: reading },
    });
    dispatch({ type: "SET_PAGINATION", payload: { pagination: Pagination } });

    const tableHeader = getTableHeader(reading);
    const columns = getColumns(reading);
    const headers = getHeaders(reading);

    dispatch({
      type: "SET_TABLE_FIELDS",
      payload: {
        headerText: tableHeader,
        columns: columns,
        headers: headers,
      },
    });

    request()
      .post(`/api/historical/${reading}`, {
        UserLogged: getSession(),
        Filter,
        Pagination,
      })
      .then((response) => {
        setTimeout(() => {
          dispatch({
            type: "SET_DATA",
            payload: {
              totalRows: response?.data?.totalRows,
              incubators: !_.isEmpty(state.filters)
                ? state.incubators
                : formatSelectObject(
                    extractIncubatorsFromReadings(response?.data.lecturas)
                  ),
              data: response?.data.lecturas
                .map((r) => {
                  return {
                    ...r,
                    startedTime: df(r.startedTime, "dateTime"),
                    resultDate: df(r.resultDate, "dateTime"),
                    creationTest: df(r.creationTest, "dateTime"),
                    manufactureDate: df(r.manufactureDate, "date"),
                    expirationDate: df(r.expirationDate, "date"),
                  };
                })
                .map((x) => ({
                  ..._.mapKeys(x, (value, key) => key),
                })),
            },
          });
        }, 500);
      })
      .catch((error) => {
        console.log(error.response);
        dispatch({
          type: "SET_DATA",
          payload: { data: [] },
        });
        dispatch({
          type: "SHOW_ERROR",
          payload: error.response?.data?.message,
        });
      });
  };

  const handlePagination = (pageNumber, pageSize, orderBy, orderType) => {
    getHistorical(activeOption, filters, {
      Page: pageNumber,
      PageSize: pageSize,
      OrderBy: orderBy,
      OrderType: orderType,
    });
  };

  const validateExportLicense = () => {
    request()
      .post("/api/license/validateExporterLicense")
      .then(() =>
        Redirect({
          redirect: true,
          path: "/appexporter",
          parameters: { indicatorSelected: activeOption },
        })
      )
      .catch((error) => setValidateLicenseError(error.response?.data?.message));
  };

  const formatHtml = (html) => {
    html = html.replaceAll(
      '<a name="PageN',
      '<div style="break-after:page"></div><a name="PageN'
    );
    html = html.replace(
      '<div style="break-after:page"></div><a name="PageN1"',
      '<a name="PageN1"'
    );
    return html;
  };

  const exportToPdfSelectedReadings = async (validate) => {
    try {
      if (validate) {
        const isEmpty = !Object.values(filters).some(
          (x) => x !== null && x !== ""
        );
        
        if (isEmpty) {
          dispatch({
            type: "SET_FILTERS",
            payload: { 
              filters: {
                ClientDateTimeNow: moment().toISOString(true),
                ClientTimeZoneId: moment.tz.guess(true),
              } 
            },
          });  
          dispatch({ type: "SHOW_DIALOG" });
        } else {
          dispatch({ type: "GENERATING_REPORT" });
          const report = await exportToPdf(activeOption, filters, pagination);
          dispatch({
            type: "REPORT_GENERATED",
            payload: formatHtml(report.data),
          });
        }
      } else {
        
        dispatch({ type: "GENERATING_REPORT" });
        const report = await exportToPdf(activeOption, filters, pagination);
        dispatch({
          type: "REPORT_GENERATED",
          payload: formatHtml(report.data),
        });
      }
    } catch (error) {
      dispatch({ type: "SHOW_ERROR", payload: error });
    }
  };

  const handleClose = (isAccepted) => {
    if (isAccepted) {
      exportToPdfSelectedReadings(false);
    } else {
      dispatch({ type: "CLOSE_DIALOG" });
    }
  };

  const getDetail = (reading) => {
    if (reading.imageId)
      request()
        .get(`/api/${activeOption}/GetImage?imageId=` + reading.imageId)
        .then((resp) => {
          setImage({ Image: resp.data.base64, ReadNumber: reading.readNumber });
        });
  };

  if (pageContentLoading) return <PageContentLoading />;

  return (
    <div>
      <div style={{margin:'38px 0px 0px 25px'}} className="btn-group" role="group">
        <div className="btn-group" role="group">
          <button
            id="btnGroupDrop1"
            type="button"
            className="btn btn-outline-dark dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t("BiologicalIndicatorLabel", locale)}
          </button>
          <div className="dropdown-menu" aria-labelledby="btnGroupDrop1">
            <button
              style={{ cursor: "pointer" }}
              className={classNames("dropdown-item", {
                active: activeOption === "ReadingBI",
              })}
              onClick={() => getHistorical("ReadingBI")}
            >
              {t("SterilizationLabel", locale)}
            </button>
            <button
              style={{ cursor: "pointer" }}
              className={classNames("dropdown-item", {
                active: activeOption === "ReadingBIDisinfection",
              })}
              onClick={() => getHistorical("ReadingBIDisinfection")}
            >
              {t("DisinfectionLabel", locale)}
            </button>
          </div>
        </div>
        <button
          type="button"
          className={classNames("btn btn-outline-dark", {
            active: activeOption === "ReadingPRO",
          })}
          onClick={() => getHistorical("ReadingPRO")}
        >
          {t("ProteinIndicatorLabel", locale)}
        </button>

        <div className="btn-group" role="group">
          <button
            id="btnGroupDrop2"
            type="button"
            className="btn btn-outline-dark dropdown-toggle"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            {t("ChemicalIndicatorsLabel", locale)}
          </button>
          <div className="dropdown-menu" aria-labelledby="btnGroupDrop2">
            <button
              style={{ cursor: "pointer" }}
              className={classNames("dropdown-item", {
                active: activeOption === "readingchesterilizer",
              })}
              onClick={() => getHistorical("readingchesterilizer")}
            >
              {t("ChemicalSterilizerLabel", locale)}
            </button>
            <button
              style={{ cursor: "pointer" }}
              className={classNames("dropdown-item", {
                active: activeOption === "readingchewasher",
              })}
              onClick={() => getHistorical("readingchewasher")}
            >
              {t("ChemicalWasherLabel", locale)}
            </button>
          </div>
        </div>
      </div>
      {activeOption && (
        <>
          <div style={{ height: 10 }} />
          <div
            style={{
              display: "flex",
              marginBottom: 5,
              justifyContent: "space-between",
              padding:'0px 25px',
            }}
          >
            <div
              style={{
                display: "flex",
                justifyContent: "flex-start",
              }}
            >
              {!_.isEmpty(filters) && (
                <ToggleButton
                  title={t("ClearFilter", locale)}
                  value="check"
                  selected={null}
                  onChange={() => {
                    setOpenFilter(false);
                    dispatch({ type: "CLEAR_FILTERS" });
                    getHistorical(activeOption, {}, { ...pagination, Page: 1 });
                  }}
                  style={{ color: 'black',
                           marginRight: 0,
                        }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    class="feather feather-x-octagon"
                  >
                    <polygon points="7.86 2 16.14 2 22 7.86 22 16.14 16.14 22 7.86 22 2 16.14 2 7.86 7.86 2"></polygon>
                    <line x1="15" y1="9" x2="9" y2="15"></line>
                    <line x1="9" y1="9" x2="15" y2="15"></line>
                  </svg>
                </ToggleButton>
              )}
              {" "}
              <ToggleButton
                title={t("FilterText", locale)}
                value="check"
                selected={null}
                onChange={() => setOpenFilter(true)}
                style={{ color: 'black',
                         marginRight: 5,
                      }}
              >
                <svg
                  focusable="false"
                  viewBox="0 0 24 24"
                  aria-hidden="true"
                  tabIndex="-1"
                  style={{ width: 24 }}
                >
                  <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
                </svg>
                <div style={{ marginLeft: 5 }}>{t("FilterText", locale)}</div>
              </ToggleButton>
              {" "}
            
              <ToggleButton
                className={classes.pdfIconButton}
                value="check"
                title={t("ExportButton")}
                selected={null}
                disabled={generatingReport}
                onChange={() => {
                  exportToPdfSelectedReadings(true);
                }}
              >
                <PictureAsPdfIcon className={classes.pdfIcon}></PictureAsPdfIcon>
                <div style={{ marginLeft: 5 }}>{t("GenerateRecordButton", locale)}</div>
                {generatingReport && (
                  <CircularProgress
                    className={classes.circularProgressRoot}
                    size={20}
                  />
                )}
              </ToggleButton>
              
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button2
                caption={t("ExportButton")}
                onClick={validateExportLicense}
              />
            </div>
          </div>
          <FixedAlert
            text={errorText}
            type={"error"}
            dispatch={dispatch}
          ></FixedAlert>
          {validateLicenseError && (
            <RequestLicense licenseProductId={1}></RequestLicense>
          )}
          <CRUD
            totalRowsServerSide={totalRows}
            newOption={false}
            migrationInfo={{ enabled: true, field: "desktopMigrationId" }}
            detailOption={false}
            deleteOption={false}
            editOption={true}
            url={`/api/${activeOption}/saveDataFromSavedRead`}
            title={headerText}
            data={data || []}
            loadingData={loadingData}
            updateData={() => getHistorical(activeOption, filters, pagination)}
            columns={columns}
            headers={headers}
            order={{}}
            serversidePagination={true}
            page={pagination.Page}
            handlePagination={handlePagination}
            idField={"id"}
            useFieldMap={false}
            tableActions={
              activeOption === "readingchesterilizer" ||
              activeOption === "readingchewasher"
                ? [
                    {
                      icon: "M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z",
                      tooltip: t("ImageLabel"),
                      action: (r) => getDetail(r),
                    },
                  ]
                : []
            }
            formFields={
              activeOption === "ReadingBI" ||
              activeOption === "ReadingBIDisinfection"
                ? [
                    {
                      label: t("VisualResult", locale),
                      propOnObject: "visualResult",
                      class: "col-12 col-md-12 px-0",
                      type: "select",
                      options: visualResults,
                    },
                    {
                      label: t("Notes", locale),
                      propOnObject: "notes",
                      class: "col-12 px-0",
                    },
                  ]
                : activeOption === "ReadingPRO"
                ? [
                    {
                      label: t("IgnoreFromIQAS", locale),
                      propOnObject: "ignoreFromIQAS",
                      class: "col-12 col-md-12 px-0",
                      type: "checkbox",
                    },
                    {
                      label: t("Notes", locale),
                      propOnObject: "notes",
                      class: "col-12 px-0",
                    },
                  ]
                : [
                    {
                      label: t("Notes", locale),
                      propOnObject: "notes",
                      class: "col-12 px-0",
                    },
                  ]
            }
            baseObject={{}}
            method={"put"}
          />
          {openFilter && (
            <HistoricalExportFilter
              biResults={biResults}
              proResults={proResults}
              cheResults={cheResults}
              sectors={sectors}
              users={users}
              incubators={incubators}
              washers={washers}
              sterilizers={sterilizers}
              disinfectors={disinfectors}
              activeOption={activeOption}
              closeFilters={() => setOpenFilter(false)}
              confirmAction={(filters) => {
                setOpenFilter(false);
                dispatch({
                  type: "SET_FILTERS",
                  payload: { filters: filters },
                });
                getHistorical(activeOption, filters, { ...pagination, Page: 1 });
              }}
              filters={filters}
            />
          )}
          <Dialog
            open={dialogValidation}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {t("FilterEmptyTitle", locale)}
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                {t("FilterEmpty", locale)}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                caption={t("CancelButton")}
                onClick={() => {
                  handleClose(false);
                }}
              />
              <Button
                caption={t("AcceptButton")}
                onClick={() => {
                  handleClose(true);
                }}
              />
            </DialogActions>
          </Dialog>
          {image && (
            <Modal
              header={
                <>
                  <span style={{ float: "left", padding: "10px 24px" }}>
                    {t("BaseReadReadNumber", locale) +
                      " ( " +
                      image.ReadNumber +
                      " )"}
                  </span>
                  <div className={classes.headerPopUpImage}>
                    <span
                      className={classes.modalCloseButton}
                      onClick={() => setImage(null)}
                    >
                      <svg viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                      </svg>
                    </span>
                  </div>
                </>
              }
              closeModal={() => setImage(null)}
              responsive
            >
              {!image && (
                <CircularProgress
                  className={classes.circularProgressRoot}
                  size={20}
                />
              )}
              {image && (
                <div className="col-12 px-0">
                  <Input
                    label={t("ImageLabel")}
                    value={image.Image}
                    variant={"image"}
                  />
                </div>
              )}
            </Modal>
          )}
          {reportVisible && (
            <Modal
              closeModal={() => dispatch({ type: "CLOSE_REPORT" })}
              header={
                <>
                  <span style={{ float: "left", padding: "10px 24px" }}>
                    {t("ReportViewer") || "Report Viewer"}
                  </span>
                  <div className={classes.headerPopUpImage}>
                    <span
                      className={classes.modalCloseButton}
                      onClick={() => dispatch({ type: "CLOSE_REPORT" })}
                    >
                      <svg viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                      </svg>
                    </span>
                  </div>
                </>
              }
              reporting
              responsive
            >
              <div className={"container-fluid px-0"}>
                <div className={"row mx-0"}>
                  <ReportPrinter html_data= {state.report_html}></ReportPrinter>
                </div>
              </div>
            </Modal>
          )}
        </>
      )}
    </div>
  );
}
