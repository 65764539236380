import React, { useEffect, useState, useContext } from 'react';
import { TextField, makeStyles } from '@material-ui/core';
import request from '../../../utils/request';
import Button from '../../Button'
import LocalizationContext from "../../../utils/LocalizationContext";
import { getDist } from "../../../utils/sessionHandler";
import CircularProgress from '../../CircularProgress/CircularProgress';

const useStyles = makeStyles({
    root: props => ({
        maxHeight: props,
        width: '100%',
        "& .MuiInputBase-root": {
            color: 'white'
        }
    })
});

const Eula = ({ dispatch, classes, regionId }) => {
    const locale = useContext(LocalizationContext);
    const textFieldClasses = useStyles(window.screen.height - 10);
    const [eula, setEula] = useState("")
    
    useEffect(() => {
        const param = {
            DistributorId: getDist().id,
            RegionId: regionId
        }
        request().post(`/api/Eula/GetByDistributorAndRegion`, param).then((response) => {
            setEula(response.data.text)
        })
    }, [])

    return (
        <div className={"row"}>
            <h2 className={classes.title}>{locale.eulaForm.title}</h2>
            {
                !eula &&
                <div className={"col-lg-12"}>
                    <CircularProgress class={textFieldClasses.loading} />
                </div>
            }
            <div className={"col-lg-12"}>
                {/*<TextField
                    classes={textFieldClasses}
                    multiline
                    defaultValue={eula}
                    rowsMax={25}
                />*/}
                <div 
                    className={classes.subTitle}
                    style={{color:'white',fontFamily: 'Montserrat',whiteSpace: "pre-wrap",textAlign:'left',marginBottom:'25px'}} dangerouslySetInnerHTML={{ __html: eula}} />
            </div>
            <div className={"col-lg-12"}>
                <div className={"row"}>
                    <Button
                        fullwidth={true}
                        caption={locale.eulaForm.acceptButton}
                        onClick={() => {
                            dispatch({ type: 'acceptEula' })
                        }} />
                </div>
                <div className={"row"}>
                    <Button
                        //isVerticalMargined={true}
                        fullwidth={true}
                        caption={locale.eulaForm.cancelButton}
                        onClick={() => {
                            dispatch({ type: 'cancelEula' })
                        }} />
                    </div>
            </div>
        </div>
    );
};

export default Eula;