import React, { useState } from 'react'
import ShowPassword from '../UserResetPassword/ShowPassword.js';
import LoginInput from "../../LoginInput";

   const ViewResetPasswordButton = (props) => {
    const {locale,newPassword,setNewPassword,setError,error,loading,t,style,repeatNewPassword,setRepeatNewPassword,success} = props;

const [viewNewPassword, setViewNewPassword] = useState(false);
const [viewRepeatNewPassword, setViewRepeatNewPassword] = useState(false);


const toggleNewPasswordVisibility = () => {
    setViewNewPassword(!viewNewPassword);
};

const toggleRepeatNewPasswordVisibility = () => {
    setViewRepeatNewPassword(!viewRepeatNewPassword);
};


  return (
    <>
      {!success && (
        <LoginInput
          claro={true}
          label={locale.form.input.newPassword}
          value={newPassword}
          onChange={value => {
            setNewPassword(value);
            error.type === "newPassword" &&
              setError({ message: null, type: null });
          }}
          error={error.type === "newPassword" ? error.message : ""}
          disabled={loading || success}
          type={viewNewPassword ? 'text' : 'password'}
        />
      )}
      <ShowPassword style={style} parentCallback={toggleNewPasswordVisibility} />
      {!success && (
        <LoginInput
          claro={true}
          label={locale.form.input.repeatNewPassword}
          value={repeatNewPassword}
          onChange={value => {
            setRepeatNewPassword(value);
            error.type === "repeatPassword" &&
              setError({ message: null, type: null });
          }}
          error={error.type === "repeatPassword" ? error.message : ""}
          disabled={loading || success}
          type={viewRepeatNewPassword ? 'text' : 'password'}
        />
      )}
        <ShowPassword style={style} parentCallback={toggleRepeatNewPasswordVisibility} />
    </>
  )
}
export default ViewResetPasswordButton;
