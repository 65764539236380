import { makeStyles, createStyles } from '@material-ui/styles';

export default makeStyles((theme) => createStyles({
    root: {
        padding: 8,
        display: 'inline-flex',
        position: 'relative',
        flexDirection: 'column',
        verticalAlign: 'top',
        width: '100%',
        fontFamily:"Montserrat",
        // margin:'0px 15px'
    },
    root2 :{
        padding: 8,
        display: 'inline-flex',
        position: 'relative',
        flexDirection: 'column',
        verticalAlign: 'top',
        width: '100%',
        fontFamily:"Montserrat",
        margin:"25px 0px 0px 25px"
    },

    selectLabel: {
        color: 'rgba(0,0,0,0.54)',
        margin: 0,
        position: 'absolute',
        fontSize: '0.75rem',
        lineHeight: 1,
        letterSpacing: '0.00938em',
        pointerEvents: 'none',
        zIndex: 1,
    },

    linkLabel: {
        color: 'rgba(0,0,0,0.54)',
        fontSize: '0.75rem'
    },

    selectBaseRoot: {
        cursor: 'pointer',
        border: 0,
        height: '1.1875em',
        outline: 0,
        padding: '6px 0 7px',
        boxSizing: 'content-box',
        marginTop: 16,
        borderBottom: '1px solid rgba(0, 0, 0, 0.42)',

        WebkitAppearance: 'none',
        lineHeight: 'normal',
        zIndex: 1,
        backgroundColor: 'transparent',

        '&:hover ~ $outline': {
            borderColor: 'rgba(0,0,0,0.87)'
        },

        '&:focus ~ $outline': {
            borderColor: `${theme.palette.terragene.darkMain}!important`,
            borderWidth: 2,
        },

        '&:focus ~ $selectLabel': {
            color: `${theme.palette.terragene.darkMain}!important`,
        }
    },

    disabled: {
        '& $selectBaseRoot': {
            cursor: 'auto'
        }
    },

    errorMessage: {
        color: theme.palette.terragene.requiredField,
        margin: 0,
        fontSize: '0.75em',
        marginTop: 8,
        minHeight: '1em',
        textAlign: 'left',
        lineHeight: '1em',
        letterSpacing: '0.03333em'
    },

    outline: {
        top: 13,
        left: 0,
        right: 0,
        bottom: 27,
        margin: 0,
        padding: 0,
        position: 'absolute',
        transition: 'padding-left 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,border-width 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        borderColor: 'rgba(0,0,0,0.23)',
        borderStyle: 'solid',
        borderWidth: 1,
        borderRadius: 4,
        pointerEvents: 'none',
    },

    outlinedRoot: {
        '& $selectLabel': {
            backgroundColor: '#f7f7f7',
            display: 'block',
            padding: '0 5px',
            position: 'absolute',
        },

        '& $selectBaseRoot': {
            padding: '12.5px 13px 10.5px 2px',
            borderBottom: 'none',
            marginTop: 6,
        }
    },

    outlinedNoError: {
        height: 44
    },

    adorment: {
        width: 24,
        height: 24,
        position: 'absolute',
        top: 24,
        right: 7,
        cursor: 'pointer'
    }
}))