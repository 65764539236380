import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles((theme) => createStyles(
  {
    tableRoot: {
      fontSize: "12px",
      fontFamily:"Montserrat",
      padding:'40px 40px',
      backgroundColor: "#f7f7f7",
    },

    btnTrazanto: {
      float: "right",
      marginTop: "5px",
      display: "inline-flex"
    },

    header: {
      background: theme.palette.terragene.lightMain,
      color: theme.palette.terragene.darkMain,
      borderRadius:10,
      textAlign: "center",
      textTransform: "uppercase",
    },

    headerText: {
      fontSize: 16,
      lineHeight: "35px",
      color: theme.palette.terragene.darkMain,
      fontWeight: "500"
    },

    tablePrimaryResponsive: {

    },

    notUpper:{
      textTransform: 'lowercase !important'
    },
    tableFooter:{
      background: theme.palette.terragene.lightMain,
      width:"100%",
      height:"2rem",
      marginTop:"5rem"
    },
    tablePrimary: {
      marginBottom: 0,

      "& thead": {
        color: theme.palette.terragene.darkMain,
        backgroundColor: "#f7f7f7",

        "& th": {
          cursor: "pointer",

          "& i": {
            margin: "0 10px",
            width: 16,
            height: 16,
            transition: "transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms"
          },

          "& $desc": {
            transform: "rotate(180deg)"
          },

          "& $prevSortArrow": {
            transition: "opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
            opacity: 0,
            color: "#757575"
          },

          "&:hover $prevSortArrow": {
            opacity: 1
          }
        }
      },

      "& td": {
        fontWeight: "500",
        borderTop: "none !important",
        // borderRight: `1px solid ${theme.palette.terragene.darkThird}`,
        borderBottom: "none !important",
        verticalAlign: "middle",
        textTransform: "uppercase",
        padding: 6
      },

      "& th": {
        fontWeight: "bold",
        backgroundColor: "#f7f7f7",
        borderTop: "none !important",
        // borderRight: `1px solid ${theme.palette.terragene.darkThird}`,
        borderBottom: "none !important",
        verticalAlign: "middle",
        textTransform: "uppercase",
        padding: 6
      },

      "& $hasEditAction th:last-child": {
        borderRight: "none !important"
      },

      "& td:nth-last-child(-n+1), & $hasEditAction td:nth-last-child(-n+2)": {
        borderRight: "none !important"
      },

      "& tbody tr": {
        "&:nth-child(odd)": {
          "& $actionsRoot": {
            backgroundColor: "inherit"
          }
        },
        "&:nth-child(even)": {
          "& $actionsRoot": {
            backgroundColor: "inherit"
          }
        },

        "&:hover $actionsRoot": {
          display: "table-cell"
        }
      }
    },

    actionsRoot: {
      zIndex: 1,
      display: "none",
      position: "sticky",
      right: 0,
      padding: "0!important",

      "& div": {
        backgroundColor: "inherit",
        zIndex: 1,
        display: "flex"
      }
    },

    actionButton: {
      cursor: "pointer",
      width: 24,
      height: 24,
      fill: "currentcolor",
      margin: "0 5px",
      opacity: ".7",
      "&:hover": {
        opacity: 1
      }
    },

    rowRoot: {
     borderTop: "0.5px solid " + theme.palette.terragene.darkThird,
      "&:hover": {
        color:"#00B181 !important",
        backgroundColor:"transparent !important",
        "& $selectCheck": { opacity: 1 }
      },
    },

    rowRootNew: {
      backgroundColor: "#cfcfcf",
      fontWeight: '900',
      borderTop: "0.5px solid " + theme.palette.terragene.darkThird,
      "&:hover": {
        color:"#00B181 !important",
        "& $selectCheck": { opacity: 1 }
      },
      // border: '1.5px solid ' + theme.palette.terragene.lightMain,
    },

    adormentContainer: {
      padding: "0!important",
      borderRight: "0!important"
    },

    selectCheck: {
      margin: "0 10px 0 14px",
      opacity: 0,
      color: "rgb(32,33,36)",
      display: "flex",

      "& i": {
        fontSize: 20
      }
    },

    connectionStautsContainer: {
      padding: "0!important",
      borderRight: "0!important"
    },

    connectionStatus: {
      display: 'flex',
      justifyContent: 'center',
      paddingLeft: 5,

      '& svg': {
        width: 20,
        height: 20,

        '& circle': {
          fill: 'currentColor'
        }
      }
    },

    masterDetailContainer: {
      padding: "0!important",
      borderRight: "0!important",
      width: 25
    },

    masterDetail: {
      display: 'flex',
      justifyContent: 'center',
      paddingLeft: 2,

      '& svg': {
        width: 20,
        height: 20,

        '& circle': {
          fill: 'currentColor'
        }
      }
    },

    selectedRow: {
      backgroundColor: "rgba(0,177,129,0.5)!important",

      "& $selectCheck": { opacity: "1!important" },
    },

    desc: {},
    prevSortArrow: {},
    hasEditAction: {},

    circularProgressRoot: {
      float: 'left',
      margin: "5px 0 0 5px"
    }
  },

));
