import AppMain from "../routes/AppMain";
import Sector from "../routes/Sector";
import User from "../routes/User";
import ConnectedIncubators from "../routes/ConnectedIncubators";
import BILive from "../routes/BILive";
import WasherProvisory from "../routes/WasherProvisory";
import SterilizerProvisory from "../routes/SterilizerProvisory";
import Sterilizer from "../routes/Sterilizer";
import Washer from "../routes/Washer";
import Disinfector from "../routes/Disinfector";
import Instrument from "../routes/Intrument";
import AppParameter from "../routes/AppParameter";
import AppRPE from "../routes/AppRPE";
import IQAS from "../routes/IQAS";
import MyLicenses from "../routes/MyLicenses";
import ChangePassword from "../routes/ChangePassword";
import AppLog from "../routes/AppLog";
import ProLive from "../routes/ProLive";
import ProFinished from "../routes/ProFinished";
import Historical from "../routes/Historical";
import Exporter from "../routes/Exporter";
import Suscriptions from "../routes/Suscriptions";
import BIFinished from "../routes/BIFinished";
import AppMigrationDatabase from "../routes/AppMigrationDatabase";

import AppEula from "../routes/AppEula";
import UserResetPassword from "../routes/UserResetPassword";


const App = [
  { path: "/appmain", component: AppMain },

  { path: "/appsector", component: Sector },
  { path: "/appuser", component: User },

  { path: "/appconnectedincubators", component: ConnectedIncubators },

  { path: "/appbilive", component: BILive },
  { path: "/appbifinished", component: BIFinished },

  { path: "/AppPILive", component: ProLive },
  { path: "/AppPIProvisional", component: ProFinished },

  { path: "/appciwasherprovisional", component: WasherProvisory },
  { path: "/appcisterilizerprovisional", component: SterilizerProvisory },

  { path: "/appsterilizer", component: Sterilizer },
  { path: "/appwasher", component: Washer },
  { path: "/appdisinfector", component: Disinfector },
  { path: "/appinstrument", component: Instrument },

  { path: "/AppHistorical", component: Historical },
  { path: "/AppExporter", component: Exporter },

  { path: "/appparameters", component: AppParameter },
  { path: "/changepassword", component: ChangePassword },
  { path: "/AppLog", component: AppLog },
  { path: "/appmigrationdatabase", component: AppMigrationDatabase },

  { path: "/AppRPE", component: AppRPE },
  { path: "/AppHTM01-01", component: IQAS },

  { path: "/AppMyLicenses", component: MyLicenses },
  { path: "/subscriptions", component: Suscriptions },

  { path: "/appeula", component: AppEula},

  // {path: "/restore-password/:token/:email/:distId", component: UserResetPassword}
];

export default App;
