import React, { useState, useEffect } from "react";
import classNames from "classnames";
import './font.css'

import useStyles from "./MenuItemStyles";

export default function MenuItem(props) {
  const classes = useStyles();
  const { item, open, activeMenuOption, menuOption, group, appUser } = props;

  const [expand, setExpand] = useState(false);

  useEffect(() => {
    props.expandedOption === null
      ? item.Items.forEach(i => {
        const key = Object.keys(i)[0];

        if (i[key].Id === activeMenuOption) {
          setExpand(true);
          props.setExpandedOption(item.Id);
        }
      })
      : props.expandedOption === item.Id
        ? setExpand(true)
        : setExpand(false);
  }, [props.expandedOption, item, activeMenuOption, props]);

  const handleItemOpenClose = () => {
    item.Items.length === 0
      ? (window.location.href = item.Url)
      : props.expandedOption === item.Id
        ? props.setExpandedOption("")
        : props.setExpandedOption(item.Id);
  };

  return (
    <div
      className={classNames(
        classes.root,
        { [classes.expand]: expand },
        { [classes.open]: open }
      )}
      //style={{ height: expand ? 40 + 35 * item.Items.length : 40 }}
      style={{ height: expand ? 40 + 35 * (appUser ? menuOption[group].Items.length : item.Items.length) : 40 }}
    >
      <div
        className={classNames(classes.item, {
          [classes.active]: (item.Id === activeMenuOption && item.Items.length === 0)
        })}
        onClick={handleItemOpenClose}
        data-tip={!open ? item.Translation : ""}
        data-place="right"
      >
        <div className={classes.itemIcon}>
          <i className={classNames(item.Image, classes.iTag)}></i>
        </div>
        <div className={classes.itemText}>{item.Translation}</div>
        {item.Items.length > 0 && (
          <div className={classes.expandIcon}>
            <svg viewBox="0 0 24 24">
              <path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6z" />
            </svg>
          </div>
        )}
      </div>      
      <ul className={classes.subitemsList}>
        {item.Items
          .map(x => {
            const key = Object.keys(x)[0];
            
            if (appUser && (!menuOption[group] || !menuOption[group].Items.some(g => g === key))) return;

            return (
              <li
                key={x[key].Id}
                className={classNames(classes.subitem, {
                  [classes.subitemActive]: x[key].Id === activeMenuOption
                })}
                data-tip={!open ? x[key].Translation : ""}
                data-place="right"
                onClick={() =>
                  x[key].Id !== activeMenuOption &&
                  (window.location.href = x[key].Url)
                }
              >
                <div className={classes.subitemIcon}>
                  <i className={x[key].Image}></i>
                  {/* <svg viewBox="0 0 24 24">
                  <path d={x.svg} />
                </svg> */}
                </div>
                <div className={classes.subitemText}>{x[key].Translation}</div>
              </li>
            );
        })}
      </ul>
    </div>
  );
}
