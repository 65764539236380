import { makeStyles, createStyles } from '@material-ui/styles';

export default makeStyles((theme) => createStyles({
    label: {
        fontFamily: 'Montserrat',
        float: 'left',
        transform: 'translate(-2px, 0px) scale(0.75)',
        display: 'block',
        transformOrigin: 'top left',
        transition: 'color 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms,transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms',
        color: 'white',
        lineHeight: '1',
        letterSpacing: '0.00938em',
        marginBottom: 3,
        margin: 0,
        pointerEvents: 'none',
        padding: '0 5px'
    },

    errorMessage: {
        color: theme.palette.terragene.requiredField,
        margin: 0,
        fontSize: '0.75rem',
        minHeight: '1em',
        textAlign: 'left',
        lineHeight: '1em',
        letterSpacing: '0.03333em'
    }
}))