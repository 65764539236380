import { makeStyles, createStyles } from '@material-ui/styles';

export default makeStyles((theme) => createStyles({
    root: {
        display:'flex',
        height:'100vh',
        justifyContent:'center',
        alignItems:'center',
        background:theme.palette.terragene.darkSecondary,
    },
    
    baseRoot: {
        display:'flex',
        flexDirection:'column'
    },
    
    text: {
        color: theme.palette.terragene.lightThird,
    }
}))