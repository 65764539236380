import React, { useState, useEffect, useContext } from "react";

import useStyles from "./ConnectedIncubatorsStyles";
import request from "../../utils/request";
import {
  getUserId,
  getSession,
} from "../../utils/sessionHandler";
import LocalizationContext from "../../utils/LocalizationContext";
import t from "../../utils/translation";
import df from "../../utils/dateFormater";
import { flatten } from "flat";

import Modal from "../../components/Modal";
import Select from "../../components/Select";
import Progress from "../../components/Progress";
import Button from "../../components/Button";
import CRUD from "../../components/CRUD";
import PageContentLoading from "../../components/PageContentLoading";

export default function ConnectedIncubators() {
  const classes = useStyles();
  const locale = useContext(LocalizationContext);

  const [loadingContent, setLoadingContent] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [selectedIncubator, setSelectedIncubator] = useState(null);
  const [sectorToAssign, setSectorToAssign] = useState("");
  const [openAssign, setOpenAssign] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [succesMessage, setsuccesMessage] = useState("");

  useEffect(() => {
    request()
      .get(`/api/Sector/getSectorsToAssign?userId=${getUserId()}`)
      .then((response) => {
        setTimeout(() => {
          setSectors(response.data.map((s) => [s.id, s.name]));
          setLoadingContent(false);
        }, 250);
      })
      .catch((error) => console.log(error.response));
  }, []);

  useEffect(() => {
    updateData()
  }, [sectors]);

  const updateData = () => {
    setLoadingData(true);
    request()
      .get(`/api/Incubator?appUserId=${getUserId()}`)
      .then((response) => {
        setData(
          response.data
            .reduce((ciList, ci) => {
              let workSectorName = "";
              let discovererSectorName = "";
              for (let i = 0; i < sectors.length; i++) {
                if (
                  sectors[i][0] === ci.workSectorId &&
                  ci.incConnectionStatusDescription !== "DISCONNECTED"
                ) {
                  workSectorName = sectors[i][1];
                }
                if (sectors[i][0] === ci.discovererSectorId) {
                  discovererSectorName = sectors[i][1];
                }
              }

              ciList.push({
                ...ci,
                formatedConnectionDate: df(ci.dateConnection, "dateTime"),
                workSectorName: workSectorName,
                discovererSectorName: discovererSectorName,
              });

              return ciList;
            }, [])
            .map((i) => flatten(i))
        );
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => console.log(error.response));
  };

  const closeModal = () => {
    setSelectedIncubator(false);
    setSectorToAssign("");
    setOpenAssign(false);
    setOpenConfirm(false);
    setError("");
  };

  const lockIncubator = (incubator) => {
    setSelectedIncubator(incubator);
    setOpenAssign(true);
  };

  const setIncubatorSector = () => {
    if (sectorToAssign === "") {
      setError(t("RequiredField"));
      return;
    }

    setLoading(true);
    request()
      .post("/api/Incubator/SetSector", {
        Incubator: selectedIncubator,
        Sector: { id: sectorToAssign },
        UserLogged: getSession(),
      })
      .then((response) => {
        console.log(response);
        setLoading(false);
        closeModal();
        updateData();
        setsuccesMessage(response.data);
        setTimeout(() => {
          setsuccesMessage("");
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        error.response && setError(error.response.data.message);
      });
  };

  const unlockIncubator = (incubator) => {
    setSelectedIncubator(incubator);
    setOpenConfirm(true);
  };

  const freeIncubator = () => {
    setLoading(true);
    request()
      .post("/api/Incubator/FreeIncubator", {
        Incubator: selectedIncubator,
        UserLogged: getSession(),
      })
      .then((response) => {
        console.log(response);
        setLoading(false);
        closeModal();
        updateData();
        setsuccesMessage(response.data);
        setTimeout(() => {
          setsuccesMessage("");
        }, 3000);
      })
      .catch((error) => {
        setLoading(false);
        error.response && setError(error.response.data.message);
      });
  };

  const refresh = () => {
    updateData()
  }

  if (loadingContent) return <PageContentLoading />;

  return (
    <>
      {succesMessage && (
        <div className="alert alert-success">{succesMessage}</div>
      )}
      <CRUD
        migrationInfo={{enabled: true, field: "desktopMigrationId"}}
        counterDown={{
          initialMinute: 0,
          initialSeconds: 15,
          refresh: refresh,
          onceTime: false
        }}
        newOption={false}
        editOption={false}
        deleteOption={false}
        connectionStatus={true}
        url={""}
        title={t("Title", locale)}
        data={data}
        loadingData={loadingData}
        columns={[
          "toString",
          "formatedConnectionDate",
          "workSectorName",
        ]}
        headers={[
          t("NameLabel", locale),
          t("IncubatorTypeLabel", locale),
          t("WorkSectorLabel", locale),
        ]}
        order={{ dir: "asc", orderBy: "status" }}
        idField={"id"}
        tableActions={[
          {
            action: lockIncubator,
            tooltip: t("AssignSectorTooltip", locale),
            icon:
              "M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm-6 9c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2zm3.1-9H8.9V6c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2z",
            hasCondition: true,
            condition: [
              ["incubatorModel.type", "!=", 1],
              ["incConnectionStatusDescription", "!=", "DISCONNECTED"],
            ],
          },
          {
            action: unlockIncubator,
            tooltip: t("FreeIncubatorTooltip", locale),
            hasCondition: true,
            condition: [
              ["workSectorId", "!=", null],
              ["incubatorModel.type", "!=", 1],
            ],
            icon:
              "M12 17c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2zm6-9h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h1.9c0-1.71 1.39-3.1 3.1-3.1 1.71 0 3.1 1.39 3.1 3.1v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10z",
          },
        ]}
        detailFields={[
          { label: t("NameLabel", locale), name: "name" },
          { label: t("IncubatorTypeLabel", locale), name: "formatedConnectionDate" },
          { label: t("SerialNumberLabel", locale), name: "serialNumber" },
          { label: t("LotLabel", locale), name: "lote" },
          { label: t("DiscovererUserLabel", locale), name: "discovererAppUser.toString" },
          { label: t("WorkSectorLabel", locale), name: "workSectorName" },
          { label: t("ConnectionDateLabel", locale), name: "formatedConnectionDate" },
          { label: t("TemperatureStatusLabel", locale), name: "statusTemperatureDescription" },
          { label: t("ConnectionStatusLabel", locale), name: "incConnectionStatusDescription" }
        ]}
      />
      {openAssign && (
        <Modal
          closeModal={closeModal}
          header={
            <React.Fragment>
              <div className={classes.header}>
                <span className={classes.headerText}>
                  {t("AssignSectorTooltip", locale)} (
                  {selectedIncubator.toString})
                </span>
              </div>
              <Progress active={loading} />
            </React.Fragment>
          }
          footer={
            <div className={classes.footerAction}>
              <Button
                caption={t("CancelButton")}
                onClick={closeModal}
                variant={"text"}
                color={"error"}
                disabled={loading}
              />
              <Button
                caption={t("ConfirmButton")}
                variant={"text"}
                onClick={setIncubatorSector}
                disabled={loading}
              />
            </div>
          }
        >
          <div className={"container-fluid px-0"}>
            <div className={"row mx-0"}>
              <div className={"col-12"}>
                <Select
                  variant={"outlined"}
                  options={sectors}
                  label={t("SectorLabel", locale)}
                  emptyOptionText={t("ChooseASector", locale)}
                  value={sectorToAssign}
                  onChange={(val) => setSectorToAssign(val)}
                  disabled={loading}
                  error={error}
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
      {openConfirm && (
        <Modal
          closeModal={closeModal}
          header={
            <React.Fragment>
              <div className={classes.header}>
                <span className={classes.headerText}>
                  {t("FreeIncubatorTooltip", locale)} (
                  {selectedIncubator.toString})
                </span>
              </div>
              <Progress active={loading} />
            </React.Fragment>
          }
          footer={
            <div className={classes.footerAction}>
              <Button
                caption={t("CancelButton")}
                onClick={closeModal}
                variant={"text"}
                color={"error"}
                disabled={loading}
              />
              <Button
                caption={t("ConfirmButton")}
                variant={"text"}
                onClick={freeIncubator}
                disabled={loading}
              />
            </div>
          }
        >
          <div className={"container-fluid"}>
            <div className={"row"}>
              <div className={"col-12"} style={{ textAlign: "center" }}>
                {t("FreeIncubatorConfirmationText", locale)}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
