import React, { useState, useEffect, useContext } from "react";
import LocalizationContext from "../../utils/LocalizationContext";
import t from "../../utils/translation";
import request from "../../utils/request";
import { useLocation } from "react-router-dom";
import { getUserCompany, getSession } from "../../utils/sessionHandler";
import _ from "lodash";

import Select from "../../components/Select";
import CRUD from "../../components/CRUD";
import PageContentLoading from "../../components/PageContentLoading";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function Sterilizer() {
  const locale = useContext(LocalizationContext);
  const company = getUserCompany();
  const query = useQuery();

  const [loadingContent, setLoadingContent] = useState(true);
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState(
    query.get("sectorId") || ""
  );

  useEffect(() => {
    request()
      .get(`/api/sector?companyId=${company.Id}`)
      .then((response) => {
        setTimeout(() => {
          setSectors(
            response.data.reduce((sectorOptions, sector) => {
              sectorOptions.push([sector.id, sector.name]);
              return sectorOptions;
            }, [])
          );
          setLoadingContent(false);
        }, 250);
      })
      .catch((error) => console.log(error));
  }, [company.Id]);

  useEffect(() => {
    updateData();
  }, [selectedSector]);

  const updateData = () => {
    if (selectedSector) { 
      setLoadingData(true);
      request()
      .get(`/api/Sterilizer/GetBySector?CompanyId=${getUserCompany().Id}&SectorId=${selectedSector}`)
        .then(response => {
          setData(
            response.data.map(x =>
              _.mapKeys(x, (value, key) => {
                return "Sterilizer." + key;
              })
            )
          );
          setTimeout(() => {
            setLoadingData(false);
          }, 250);
        })
        .catch(error => console.log(error.response));
    }
  };

  if (loadingContent) return <PageContentLoading />;

  return (
    <div>
      <div className={"container-fluid px-0"}>
        <div className={"row mx-0 "}>
          <div style={{margin:'25px 0px 0px 25px'}} className={"col-12 col-md-5 px-0"}>
            <Select
              label={t("SectorLabel", locale) || "Sector"}
              options={sectors}
              emptyOptionText={t("ChooseASector", locale) || "Choose A Sector"}
              variant={"outlined"}
              value={selectedSector}
              onChange={(val) => setSelectedSector(val)}
            />
          </div>
        </div>
      </div>
      {selectedSector && (
      <CRUD
        data={data}
        loadingData={loadingData}
        url={"/api/Sterilizer"}
        updateData={updateData}
        title={t("Title", locale)}
        migrationInfo={{enabled: true, field: "Sterilizer.desktopMigrationId"}}
        columns={[
          "Sterilizer.name", 
          "Sterilizer.brand", 
          "Sterilizer.serial",
          ]}
        headers={[
          t("NameLabel", locale),
          t("BrandLabel", locale),
          t("SerialLabel", locale),
        ]}
        idField={"Sterilizer.id"}
        fieldsMap={[
          "Sterilizer.sectorId", 
          "Sterilizer.name", 
          "Sterilizer.brand", 
          "Sterilizer.serial",
          ]}
        formFields={[
          {
            label: t("SectorLabel", locale),
            class: "col-12 col-md-6 px-0",
            type: "select",
            options: sectors,
            defaultValue: selectedSector,
            updateOnly: true,
            required: true,
          },
          {
            label: t("NameLabel", locale),
            class: "col-12 col-md-6 px-0",
            required: true
          },
          {
            label: t("BrandLabel", locale),
            class: "col-12 col-md-6 px-0",
            required: true
          },
          {
            label: t("SerialLabel", locale),
            class: "col-12 col-md-6 px-0",
            required: true
          }
        ]}
        detailFields={[
          { label: t("NameLabel", locale), name: "Sterilizer.name" },
          { label: t("BrandLabel", locale), name: "Sterilizer.brand" },
          { label: t("SerialLabel", locale), name: "Sterilizer.serial" },
        ]}
        baseObject={{
          Sterilizer: {
            name: "",
            brand: "",
            serial: "",
            sectorId: selectedSector,
          },
          UserLogged: getSession()
        }}
      />
      )}
    </div>
  );
}
