import React, { useState } from 'react'
import LoginInput from '../../LoginInput';
import ShowPassword from './ShowPassword';

   const ViewPasswordButton = (props) => {
    const {locale,setNewPassword,newPassword,setError,error,loading,style,success,repeatNewPassword,setRepeatNewPassword,t} = props;


const [viewNewPassword, setViewNewPassword] = useState(false);
const [viewRepeatPassword, setViewRepeatPassword] = useState(false);

const toggleNewPasswordVisibility = () => {
  setViewNewPassword(!viewNewPassword);
};

const toggleRepeatPasswordVisibility = () => {
  setViewRepeatPassword(!viewRepeatPassword);
};

  return (
    <>
        {!success && (
        <LoginInput
          claro={true}
          label={locale.form.input.newPassword}
          value={newPassword}
          onChange={value => {
            setNewPassword(value);
            error.type === "newPassword" &&
              setError({ message: null, type: null });
          }}
          type={viewNewPassword ? 'text' : 'password'}
          error={error.type === "newPassword" ? error.message : ""}
          disabled={loading || success}
        />
      )}
      <ShowPassword style={style} parentCallback={toggleNewPasswordVisibility} />
      {!success && (
        <LoginInput
          claro={true}
          label={locale.form.input.repeatNewPassword} 
          value={repeatNewPassword}
          onChange={value => {
            setRepeatNewPassword(value);
            error.type === "repeatPassword" &&
              setError({ message: null, type: null });
          }}
          type={viewRepeatPassword ? 'text' : 'password'}
          error={error.type === "repeatPassword" ? error.message : ""}
          disabled={loading || success}
        />
      )}
        <ShowPassword style={style} parentCallback={toggleRepeatPasswordVisibility} />
    </>
  )
}
export default ViewPasswordButton;