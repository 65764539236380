import React, { useState, useEffect, useContext, useRef, useMemo } from "react";
import LocalizationContext from "../../utils/LocalizationContext";
import t from "../../utils/translation";
import request from "../../utils/request";
import df from "../../utils/dateFormater";
import {
  getUserCompany,
  getSession,
  getDist,
  getUserId,
} from "../../utils/sessionHandler";
import useStyles from "./LicenseStyles";
import { differenceInDays, parseISO } from "date-fns";

import CRUD from "../../components/CRUD";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import Progress from "../../components/Progress";
import Input from "../../components/Input";

const formatSelectObject = (objectData, emptyText) => {
  return objectData.reduce(
    (objectsList, object) => {
      objectsList.push([object.id, object.name || object.toString, object]);
      return objectsList;
    },
    [[null, emptyText, { id: "xx" }]]
  );
};

export default function License(props) {
  const locale = useContext(LocalizationContext);
  const classes = useStyles();
  const CRUDRef = useRef();

  const [data, setData] = useState([]);
  const [defaultPeriod, setDefaultPeriod] = useState("");
  // const [disabledPeriod, setDisabledPeriod] = useState(true);
  const [loadingData, setLoadingData] = useState(true);
  const [buyObject, setBuyObject] = useState(null);
  const [confirmationObject, setConfirmationObject] = useState(null);
  const [cancelationObject, setCancelationObject] = useState(null);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [periods, setPeriods] = useState([
    [null, t("ChooseAPeriodLabel", locale)],
  ]);
  const [products, setProducts] = useState([]);
  const [activationKey, setActivationKey] = useState("");
  const [error, setError] = useState(null);
  const [tableActions, setTableActions] = useState([]);
  const [toSuccessMessage, setToSuccessMessage] = useState({
    type: "none",
  });
  const [selectedLicense, setSelectedLicense] = useState("");
  const [daysToAlert, setDaysToAlert] = useState(null);
  const [selectedPeriod, setSelectedPeriod] = useState(null);

  useEffect(() => {
    Promise.all([
      request().get("/api/licenseperiod"),

      request().post(`/api/LicenseProduct/ProductPeriod`, {
        idDistributor: getSession().Company.DistributorId,
      }),
      ,
    ]).then(([periodData, newProductPeriod]) => {
      const periodoTrial = periodData.data.filter((x) => x.trial)[0];
      // setDefaultPeriod(periodoTrial);
      if (props?.product)
        onProductChange(
          newProductPeriod.data.find((x) => x.id == props?.product).id
        );
      setProducts(
        formatSelectObject(
          newProductPeriod.data,
          t("ChooseAProductLabel", locale)
        )
          .filter(
            (license) =>
              license[1].toUpperCase().includes("SM") || (
              !license[1].toUpperCase().includes("EXCHANGE") &&
              !license[1].toUpperCase().includes("EXCHANGE PLUS") &&
              !license[1].toUpperCase().includes("EXPORTER"))
          )
          .map((license) => {
            if (
              license[1].toUpperCase().replace(/\s+/g, "").includes("BIONOVACLOUD")
            ) {
              const newLicense = [...license];
              newLicense[1] =
                "Bionova Cloud (Exporter + Exchange + Exchange Plus )";
              return newLicense;
            }
            return license;
          })
      );
    });
  }, [locale]);

  console.log(products);

  useEffect(() => {
    updateData();
    getDaysExpiration();
  }, []);

  // useEffect(() => {
  //   request()
  //     .get(`/api/Distributor/IsOwnDistributor?distId=${getDist().id}`)
  //     .then((respo) => {
  //       const actions = [];
  //       if (respo.data) {
  //         /*actions.push({
  //         action: (r) => //buyLicense(r),
  //         setBuyObject({
  //           header: t("BuyLicense", locale),
  //           object: r,
  //           action: buyLicense,
  //         }),
  //         tooltip: t("BuyLicense", locale),
  //         icon: "M7 18c-1.1 0-1.99.9-1.99 2S5.9 22 7 22s2-.9 2-2-.9-2-2-2zM1 2v2h2l3.6 7.59-1.35 2.45c-.16.28-.25.61-.25.96 0 1.1.9 2 2 2h12v-2H7.42c-.14 0-.25-.11-.25-.25l.03-.12.9-1.63h7.45c.75 0 1.41-.41 1.75-1.03l3.58-6.49c.08-.14.12-.31.12-.48 0-.55-.45-1-1-1H5.21l-.94-2H1zm16 16c-1.1 0-1.99.9-1.99 2s.89 2 1.99 2 2-.9 2-2-.9-2-2-2z",
  //         hasCondition: true,
  //         condition: [["license.licenseStatus", "=", 0]],
  //       })*/
  //       } else {
  //         actions.push({
  //           action: (r) =>
  //             setConfirmationObject({
  //               header: t("ActivateLicenseHeader", locale),
  //               object: r,
  //               action: activateLicense,
  //             }),
  //           tooltip: t("ActivateTooltip", locale),
  //           icon: "M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z",
  //           hasCondition: true,
  //           condition: [["license.licenseStatus", "=", 0]],
  //         });
  //         actions.push({
  //           action: (r) =>
  //             setCancelationObject({
  //               header: t("CancelLicense", locale),
  //               object: r,
  //               action: cancelLicense,
  //             }),
  //           tooltip: t("CancelLicense", locale),
  //           icon: "M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm5 13.59L15.59 17 12 13.41 8.41 17 7 15.59 10.59 12 7 8.41 8.41 7 12 10.59 15.59 7 17 8.41 13.41 12 17 15.59z",
  //           hasCondition: true,
  //           condition: [["license.licenseStatus", "=", 0]],
  //         });
  //       }
  //       setTableActions(actions);
  //     });
  // }, []);

  const updateData = () => {
    setLoadingData(true);
    request()
      .post(`/api/LicenseCode/SeeLicenses`, getUserCompany())
      .then((response) => {
        const formattedData = response.data.map((license) => {
          let startDateFormatted = df(
            license.licenseDetails["0"].startDate,
            "date"
          );
          let endDateFormatted = df(
            license.licenseDetails["0"].endDate,
            "date"
          );

          let expirationDate = license.licenseDetails["0"].endDate;
          let nameFormatted = license.licenseDetails["0"].licenseProductName;
          let toString = license.licenseDetails["0"].licenseProductName;
          let statusFormatted =
            license.licenseDetails["0"].licenseStatusDescription;
          let periodFormatted =
            license.licenseDetails["0"].licensePeriodObject.periodName;
          let productId = license.licenseDetails["0"].licenseProductId;
          let observationFormatted = license.licenseDetails["0"].observations;

          return {
            ...license,
            nameFormatted,
            statusFormatted,
            startDateFormatted: startDateFormatted,
            endDateFormatted: endDateFormatted,
            periodFormatted,
            productId,
            expirationDate,
            observationFormatted,
            toString,
          };
        });
        setData(formattedData);

        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  // const buyLicense = (licenseCode) => {
  //   //refirigir al eCommerce.
  //   setLoadingData(true);
  //   request()
  //     .post(`/api/LicenseCode/buylicense?idLicenseCode=${licenseCode.id}`)
  //     .then((response) => {
  //       setTimeout(() => {
  //         setBuyObject(false);
  //         setLoadingData(false);
  //         setToSuccessMessage({
  //           type: "success",
  //           message: response.data,
  //         });

  //         updateData();
  //       }, 250);
  //       setTimeout(() => {
  //         setBuyObject(false);
  //         setLoadingData(false);
  //         setToSuccessMessage({ type: "none" });
  //       }, 3000);
  //     })
  //     .catch((error) => {
  //       console.log(error.response);
  //     });
  // };

  // const cancelLicense = (licenseCode, activationKey) => {
  //   const licenseCodeObject = unflatten(licenseCode);
  //   request()
  //     .put(`/api/LicenseCode/`, licenseCodeObject)
  //     .then(() => {
  //       setTimeout(() => {
  //         setLoadingConfirm(false);
  //         setActivationKey("");
  //         setError(null);
  //         setConfirmationObject(null);
  //         setCancelationObject(null);
  //         updateData();
  //       }, 500);
  //     })
  //     .catch((error) => {
  //       setLoadingConfirm(false);
  //       if (error.response.data) {
  //         if (error.response.data.message) {
  //           setError(error.response.data.message);
  //         }
  //       }
  //     });
  // };

  // const activateLicense = (licenseCode, activationKey) => {
  //   if (activationKey === "") {
  //     setError(t("RequiredFieldError"));
  //     return;
  //   }
  //   licenseCode.code = activationKey;
  //   const licenseCodeObject = unflatten(licenseCode);
  //   licenseCodeObject.License = null;
  //   licenseCodeObject.LicenseProduct = null;
  //   setLoadingConfirm(true);
  //   request()
  //     .post("/api/LicenseCode/ActivateLicense", licenseCodeObject)
  //     .then(() => {
  //       setTimeout(() => {
  //         setLoadingConfirm(false);
  //         setActivationKey("");
  //         setError(null);
  //         setConfirmationObject(null);
  //         updateData();
  //       }, 500);
  //     })
  //     .catch((error) => {
  //       setLoadingConfirm(false);
  //       if (error.response.data) {
  //         if (error.response.data.message) {
  //           setError(error.response.data.message);
  //         }
  //       }
  //     });
  // };

  useEffect(() => {
    if (props.product !== undefined) {
      CRUDRef.current.api({ type: "openNewObject" });
    }
  }, [CRUDRef, props.product]);

  const onProductChange = async (value) => {
    let item = products?.find((x) => x[0] == value);
    let newPeriods = item[2].periods;
    await request()
      .get(`/api/LicenseCode/ProductUsedTrial?licenseProductId=${value}`)
      .then((resp) => {
        if (resp.data == true) {
          const filteredPeriods = newPeriods
            .filter((x) => x.trial == false)
            .map((x) => [x.licensePeriodId, x.periodName]);
          setPeriods([
            [null, t("ChooseAPeriodLabel", locale)],
            ...filteredPeriods,
          ]);
        } else {
          const filteredPeriods = newPeriods
            .filter((x) => x.trial == true)
            .map((x) => [x.licensePeriodId, x.periodName]);
          setPeriods([
            [null, t("ChooseAPeriodLabel", locale)],
            ...filteredPeriods,
          ]);
        }
      });
  };

  const disabledPeriod = useMemo(() => {
    return periods.length <= 1;
  }, [periods, selectedLicense]);

  const handleLicenseChange = async (id) => {
    setSelectedLicense(id);
    onProductChange(id);
  };

  const expirationDate = useMemo(() => {
    let item = data.find(
      (i) =>
        i["productId"] == selectedLicense && i["statusFormatted"] == "Active"
    );

    return item && selectedPeriod ? item["expirationDate"] : null;
  }, [selectedLicense, selectedPeriod]);

  const getDaysExpiration = () => {
    request()
      .get(`/api/appparameter?userLoggedId=${getUserId()}`)
      .then((res) =>
        setDaysToAlert(
          parseInt(res.data.find((p) => p.appParameterType === 2).value)
        )
      );
  };

  const calculateDateDifference = () => {
    const differenceDays = differenceInDays(
      parseISO(expirationDate),
      new Date()
    );

    let newDate;

    if (selectedPeriod) {
      newDate = expirationDate
        ? differenceDays <= daysToAlert
          ? expirationDate
          : ""
        : new Date();
    }

    return newDate;
  };

  const finalDate = useMemo(
    () => calculateDateDifference(),
    [expirationDate, daysToAlert, selectedPeriod]
  );

  const handlePeriod = (id) => {
    setSelectedPeriod(id);
  };

  return (
    <>
      {toSuccessMessage.type === "success" && (
        <div className={`alert alert-${toSuccessMessage.type}`} role="alert">
          {toSuccessMessage.message}
        </div>
      )}
      <CRUD
        ref={CRUDRef}
        editOption={false}
        deleteOption={false}
        data={data}
        loadingData={loadingData}
        updateData={updateData}
        url={"/api/license/requestlicense"}
        onCloseModal={() => {
          setPeriods([[null, t("ChooseAPeriodLabel", locale)]]);
          setSelectedPeriod(null);
        }}
        title={t("Title", locale)}
        tableHeader={t("TableHeader", locale)}
        columns={[
          "nameFormatted",
          "periodFormatted",
          "statusFormatted",
          "startDateFormatted",
          "endDateFormatted",
        ]}
        headers={[
          t("ProductLabel", locale),
          t("PeriodLabel", locale),
          t("StatusLabel", locale),
          t("StartDateLabel", locale),
          t("EndDateLabel", locale),
        ]}
        idField={"licenseProduct"}
        fieldsMap={["LicenseProductId", "LicensePeriodId", "UtcStartDateInput"]}
        formFields={[
          {
            label: t("ProductLabel", locale),
            class: "col-12 col-md-6 px-0",
            type: "select",
            options: products,
            onSelectedChange: (row) => {
              {
                handleLicenseChange(row); // esto es el licenseId
              }
            },
            required: true,
            defaultValue: props?.product,
            objectField: false,
          },
          {
            label: t("PeriodLabel", locale),
            class: "col-12 col-md-6 px-0",
            type: "select",
            options: periods,
            objectField: false,
            disabled: disabledPeriod,
            defaultValue: selectedPeriod,
            required: true,

            onSelectedChange: (row) => {
              {
                handlePeriod(row);
              }
            },
          },
          {
            label: ` ${t("StartDateLabel", locale)}: ${df(finalDate, "date")}`,
            class: "col-12 col-md-6 px-0",
            type: "date",
            utcField: "UtcStartDate",
            disabled: true,
          },
        ]}
        detailFields={[
          {
            label: t("PeriodLabel", locale),
            name: "periodFormatted",
          },
          {
            label: t("InvoiceNumberLabel", locale),
            name: "license.invoiceNumber",
          },
          {
            label: t("StartDateLabel", locale),
            name: "startDateFormatted",
          },
          {
            label: t("EndDateLabel", locale),
            name: "endDateFormatted",
          },
          {
            label: t("ObservationsLabel", locale),
            name: "observationFormatted",
          },
          {
            label: t("StatusLabel", locale),
            name: "statusFormatted",
          },
          { label: t("LicenseCode", locale) || "License Code", name: "code" },
        ]}
        tableActions={tableActions}
        baseObject={{
          UserLogged: getSession(),
          DistributorId: getDist().id,
          LicenseProductId: props?.product,
          LicensePeriodId: selectedPeriod,
          UtcStartDateInput:
            finalDate == null || finalDate == ""
              ? new Date("1900-01-01")
              : finalDate,
          UtcStartDate:
            finalDate == null || finalDate == ""
              ? new Date("1900-01-01")
              : finalDate,
          UserLogged: getSession(),
        }}
      />
      {buyObject && (
        <Modal
          closeModal={() => setBuyObject(null)}
          header={
            <React.Fragment>
              <div className={classes.header}>
                <span className={classes.headerText}>{buyObject.header}</span>
              </div>
              <Progress active={loadingConfirm} />
            </React.Fragment>
          }
          footer={
            <div className={classes.footerAction}>
              <Button
                caption={t("CancelButton")}
                variant={"text"}
                onClick={() => setBuyObject(null)}
                disabled={loadingConfirm}
                color={"error"}
              />
              <Button
                caption={t("ConfirmButton")}
                variant={"text"}
                // onClick={() => buyObject.action(buyObject.object, buyLicense)}
                disabled={loadingConfirm}
              />
            </div>
          }
        >
          <div className={"container-fluid"}>
            <div className={"row"}>
              <div className={"col-12"} style={{ textAlign: "center" }}>
                {t("BuyLicenceConfirmationText") ||
                  "Are you sure you want to buy this license?"}
              </div>
            </div>
          </div>
        </Modal>
      )}
      {confirmationObject && (
        <Modal
          closeModal={() => setConfirmationObject(null)}
          header={
            <React.Fragment>
              <div className={classes.header}>
                <span className={classes.headerText}>
                  {confirmationObject.header}
                </span>
              </div>
              <Progress active={loadingConfirm} />
            </React.Fragment>
          }
          footer={
            <div className={classes.footerAction}>
              <Button
                caption={t("CancelButton")}
                variant={"text"}
                onClick={() => setConfirmationObject(null)}
                disabled={loadingConfirm}
                color={"error"}
              />
              <Button
                caption={t("ConfirmButton")}
                variant={"text"}
                onClick={() =>
                  confirmationObject.action(
                    confirmationObject.object,
                    activationKey
                  )
                }
                disabled={loadingConfirm}
              />
            </div>
          }
        >
          <div className={"container-fluid"}>
            <div className={"row"}>
              <div className={"col-12"}>
                <Input
                  label={t("ActivationCode", locale)}
                  value={activationKey}
                  onChange={(value) => setActivationKey(value)}
                  error={error}
                  isRequired
                />
              </div>
            </div>
          </div>
        </Modal>
      )}
      {cancelationObject && (
        <Modal
          closeModal={() => setCancelationObject(null)}
          header={
            <React.Fragment>
              <div className={classes.header}>
                <span className={classes.headerText}>
                  {cancelationObject.header}
                </span>
              </div>
              <Progress active={loadingConfirm} />
            </React.Fragment>
          }
          footer={
            <div className={classes.footerAction}>
              <Button
                caption={t("CancelButton")}
                variant={"text"}
                onClick={() => setCancelationObject(null)}
                disabled={loadingConfirm}
                color={"error"}
              />
              <Button
                caption={t("ConfirmButton")}
                variant={"text"}
                onClick={() =>
                  cancelationObject.action(
                    cancelationObject.object,
                    activationKey
                  )
                }
                disabled={loadingConfirm}
              />
            </div>
          }
        >
          {t("CancelLicenseMessage", locale) || `Delete`}
        </Modal>
      )}
    </>
  );
}
