import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles((theme) => createStyles({
  root: {
    fontSize: "0.800rem",
    fontWeight: "normal",
    fontFamily:"Montserrat",
    transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
    lineHeight: "1.75",
    borderRadius: 20,
    width:"fit content",
    alignSelf:"center",
    letterSpacing: "0.02857em",
    textTransform: "uppercase",
    border: "1px solid #00B189",
    outline: "0!important",
    margin:'0px 25px 25px 25px',
    
  },

  disabled: {
    cursor: "auto!important",
    color: "rgba(0,0,0,0.26)",
  },

  containedVariant: {
    // padding: "4px 10px",

    '&$disabled': {
      boxShadow: "none",
      backgroundColor: "rgba(0,0,0,0.12)"
    }
  },

  textVariant: {
    padding: '6px 8px',
    backgroundColor: 'transparent'
  },

  //colores
  primaryColor: {
    '&$containedVariant$active': {
      color: theme.palette.terragene.darkSecondary,
      backgroundColor: theme.palette.terragene.lightMain,
      // boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",

      "&:hover": {
        backgroundColor: theme.palette.terragene.darkSecondary,
        color:theme.palette.terragene.lightMain,
        transition:"all 0.4s ease-in-out"
      }
    },

    '&$textVariant$active': {
      color: theme.palette.terragene.darkMain,

      "&:hover": {
        backgroundColor: 'rgba(3, 69, 128, 0.08)'
      }
    }
  },

  secondaryColor: {
    '&$containedVariant$active': {
      color: theme.palette.terragene.lightThird,
      backgroundColor: theme.palette.terragene.darkSecondary,
      // boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",

      "&:hover": {
        backgroundColor: theme.palette.terragene.darkThird
      }
    },

    '&$textVariant$active': {
      color: theme.palette.terragene.darkMain,

      "&:hover": {
        backgroundColor: 'rgba(3, 69, 128, 0.08)'
      }
    }
  },

  errorColor: {
    '&$containedVariant$active': {
      color: "#fff",
      backgroundColor: theme.palette.error,
      boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",

      "&:hover": {
        backgroundColor: theme.palette.errorAction
      }
    },

    '&$textVariant$active': {
      color: theme.palette.errorAction,

      "&:hover": {
        backgroundColor: 'rgba(176, 0, 32, 0.08)'
      }
    }
  },

  fullwidth: {
    width: "100%",
  },

  verticalMargin: {
    margin: "10px 0"
  },

  lateralMargin: {
    margin: "0 10px"
  },

  dense: {
    margin: 0
  },

  active: {},

  prueba: {
    float: "right"
  }
}))
