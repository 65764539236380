import React, { useEffect, useState } from 'react';
import request from '../../utils/request';
import { getUserId } from '../../utils/sessionHandler';
import Button from '../Button';
import FixedAlert from '../FixedAlert/FixedAlert';
import t from "../../utils/translation";
import Input from '../Input';

const QuickWasher = ({ onClickCallBack }) => {
    const screenId = 300
    const [washer, setWasher] = useState({})
    const [error, setError] = useState("")
    const [errors, setErrors] = useState({});
    const [temporalTranslations, setTemporalTranslations] = useState({})

    useEffect(() => {
        request().get(`/api/screen/getjson?ScreenNameId=${screenId}&UserLoggedId=${getUserId()}`)
            .then(response => {
                setTemporalTranslations(response.data);
            })
    }, [])

    const isValidForm = () => {
        let localErrors = {}
        let isValid = true;
        if (!washer.Name) {
            localErrors.Name = t("RequiredFieldError")
            isValid = false
        }
        if (!washer.Brand) {
            localErrors.Brand = t("RequiredFieldError")
            isValid = false
        }
        if (!washer.Serial) {
            localErrors.Serial = t("RequiredFieldError")
            isValid = false
        }
        setErrors(localErrors)
        return isValid;
    }

    return (
        <div>
            <FixedAlert text={error} type={'error'} closeErrorCb={() => { setError("") }}></FixedAlert>
            <Input
                label={t("NameLabel", temporalTranslations)}
                value={washer.Name}
                onChange={(value) => setWasher({ ...washer, Name: value })}
                error={errors.Name}
            />
            <Input
                label={t("BrandLabel", temporalTranslations)}
                value={washer.Brand}
                onChange={(value) => setWasher({ ...washer, Brand: value })}
                error={errors.Brand}
            />
            <Input
                label={t("SerialLabel", temporalTranslations)}
                value={washer.Serial}
                onChange={(value) => setWasher({ ...washer, Serial: value })}
                error={errors.Serial}
            />
            <Button caption={t("ConfirmButton")} onClick={(e) => {
                if (isValidForm()) {
                    request().post(`/api/Washer/QuickSave`, { Washer: washer }).then(resp => {
                        washer.Id = resp.data
                        onClickCallBack(washer)
                    }).catch(err => {
                        setError(err?.response?.data?.Message)
                    })
                }
            }}></Button>
        </div>
    );
};

export default QuickWasher;