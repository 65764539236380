import { makeStyles, createStyles } from "@material-ui/styles";


export default makeStyles((theme) => createStyles({
    printButtonContainer: {
        display: 'flex',
        width: '100%'
    },

    iframe: {
        height: '500px',
        width: '100%',
        '@media(min-width:1920px)': {
            height: '820px',
        }
    }
}));

