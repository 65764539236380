import React, { useState, useEffect, useContext } from "react";
import request from "../../utils/request";
import CRUD from "../../components/CRUD";
import df from "../../utils/dateFormater";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";
import { getUserId } from "../../utils/sessionHandler";
export default function AppLog() {
  const locale = useContext(LocalizationContext);
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  

  useEffect(() => {
    updateData();
  }, []);

  const updateData = () => {
    setLoadingData(true);
    request()
      .get(`/api/applog?userLoggedId=${getUserId()}`)
      .then((response) => {
        setData(
          response.data.map((l) => {
            return { ...l, logDate: df(l.logDate, "dateTime") };
          })
        );
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })

      .catch((error) => {
        console.log(error.response);
      });
  };

  return (
    <CRUD
      detailOption={false}
      newOption={false}
      editOption={false}
      deleteOption={false}
      data={data}
      order={{ dir: "desc", orderBy: "logDate" }}
      url={"/api/applog"}
      title={t("Title", locale)}
      updateData={updateData}
      loadingData={loadingData}
      columns={["logDate", "appUser", "logDetail"]}
      headers={[t("DateLabel", locale), t("UserLabel", locale), t("DetailLabel", locale)]}
      idField={"id"}
    />
  );
}
