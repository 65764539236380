import { makeStyles } from "@material-ui/styles";

export default makeStyles({
  backdrop: {
    zIndex: 1300,
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    position: 'fixed',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
  },

  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    padding: '0 5%',

    '&$responsive': {
      '@media(max-width:800px)': {
        padding: 0
      }
    }
  },

  modalRoot: {
    height: 'auto',
    width: '100%',
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
    position: 'relative',
    backgroundColor: '#fff',
    boxShadow: '0 12px 15px 0 rgba(0,0,0,0.24)',
    overflow: 'hidden',
    flexShrink: 1,
    maxHeight: '100%',
    borderRadius: 10,
    maxWidth: 700,

    '$responsive &': {
      '@media(max-width:800px)': {
        borderRadius: 0,
        height: '100%',
        maxWidth: 'unset'
      }
    }
  },

  modal: {
    flexGrow: 2,
    flexShrink: 2,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    position: 'relative',
  },

  header: {
    position: 'relative',
    transition: 'box-shadow .1s ease-in-out',
    width: '100%',
    borderBottom: '1px solid #dadce0',
    overflow: 'hidden',
    flexShrink: 0,
  },

  headerOverlay: {
    boxShadow: '0 0 10px silver',
  },

  footer: {
    minHeight: 48,
    display: 'flex',
    padding: '8px 20px 5px 38px',
    borderTop: '1px solid rgba(0,0,0,0.12)',  
  },

  body: {
    overflow: 'auto',
    flexGrow: '1',
    width: '100%',
    padding: '10px 0 20px',
    position: 'relative',

    '@media(min-width:800px)': {
      maxHeight: 550,
    },
    '@media(max-width:1366px)': {
      maxHeight: 650,
    },
    '@media(min-width:1920px)': {
      maxHeight: 950,
    }
  },

  responsive: {},

  reporting: {
    maxWidth: 780
  },
});

