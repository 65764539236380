// import React, { useState, useContext } from "react";
// import request from "../../../utils/request";
// import LocalizationContext from "../../../utils/LocalizationContext";

// import Input from "../../Input";
// import Progress from "../../Progress";
// import Button from "../../Button";
// import { getDist } from "../../../utils/sessionHandler";
// import Button2 from "../../Button2";

// export default function ResetPassword(props) {
//   const { classes, dispatch, email, id, images } = props;

//   const [newPassword, setNewPassword] = useState("");
//   const [repeatNewPassword, setRepeatNewPassword] = useState("");
//   const [error, setError] = useState({ message: null, type: null });
//   const [loading, setLoading] = useState(false);
//   const [success, setSuccess] = useState(false);

//   const locale = useContext(LocalizationContext);

//   const checkRequiredFields = () => {
//     if (email.length === 0) {
//       setError({ type: "email", message: "This field is required" });
//       return false;
//     }

//     if (newPassword.length === 0) {
//       setError({ type: "newPassword", message: "This field is required" });
//       return false;
//     }

//     if (repeatNewPassword !== newPassword) {
//       setError({
//         type: "repeatPassword",
//         message: "The passwords do not match."
//       });
//       return false;
//     }

//     return true;
//   };

//   const resetPasswordSubmit = e => {
//     document.activeElement.blur();
//     e.preventDefault();

//     if (!checkRequiredFields()) {
//       return;
//     }

//     setLoading(true);

//     if (success) {
//       dispatch({ type: "loginClick" });
//       return;
//     }

//     const user = {
//       AppUser: {
//         email: email,
//         id: id
//       },
//       Password: newPassword,
//       DistributorId: getDist().id
//     };

//     request()
//       .post(`/api/appuser/restorepassword`, user)
//       .then(response => {
//         setSuccess(true);
//         setLoading(false);
//         setError({ type: "success", message: response.data });
//       })
//       .catch(error => {
//         if (!error.response) {
//           return;
//         }
//         console.log(error.response.data.message);
//         setError({ type: "error", message: error.response.data.message });
//         setLoading(false);
//       });
//   };

//   return (
//     <form className={classes.loginForm} onSubmit={e => resetPasswordSubmit(e)}>
//       <Progress class={classes.loading} active={loading} />
//       <div>
//         <img
//           src={images.logo}
//           alt=""
//           className={classes.logoTerragene}
//         ></img>
//       </div>
//       <h1 className={classes.loginText}>{locale.header.changePassword}</h1>
//       {(error.type === "error" || error.type === "success") && (
//         <div
//           className={`alert ${
//             error.type === "error" ? "alert-danger" : "alert-primary"
//           }`}
//           role="alert"
//         >
//           {error.message}
//         </div>
//       )}
//       {!success && (
//         <Input
//           claro={true}
//           label={locale.form.input.email}
//           value={email}
//           onChange={value => {
//             dispatch({ type: "setEmail", payload: value });
//             error.type === "email" &&
//               setError({ message: null, type: null });
//           }}
//           type={"text"}
//           error={error.type === "email" ? error.message : ""}
//           disabled={loading || success}
//         />
//       )}
//       {!success && (
//         <Input
//           claro={true}
//           label={locale.form.input.newPassword}
//           value={newPassword}
//           onChange={value => {
//             setNewPassword(value);
//             error.type === "newPassword" &&
//               setError({ message: null, type: null });
//           }}
//           type={"password"}
//           error={error.type === "newPassword" ? error.message : ""}
//           disabled={loading || success}
//         />
//       )}
//       {!success && (
//         <Input
//           claro={true}
//           label={locale.form.input.repeatNewPassword}
//           value={repeatNewPassword}
//           onChange={value => {
//             setRepeatNewPassword(value);
//             error.type === "repeatPassword" &&
//               setError({ message: null, type: null });
//           }}
//           type={"password"}
//           error={error.type === "repeatPassword" ? error.message : ""}
//           disabled={loading || success}
//         />
//       )}
//       <Button2
//         type={"submit"}
//         caption={
//           success ? locale.form.submit.login : locale.form.submit.changePassword
//         }
//         disabled={loading}
//       />
//     </form>
//   );
// }

////////////////// NEW RESET PASSWORD //////////////////////////////


import React, { useState, useContext } from "react";
import request from "../../../utils/request";
import LocalizationContext from "../../../utils/LocalizationContext";

import Progress from "../../Progress";
import Button from "../../Button";
import { getDist } from "../../../utils/sessionHandler";
import Button2 from "../../Button2";
import LoginInput from "../../LoginInput";
import ResetPasswordButton from "./ResetPasswordButton";

const iconStyle={
  position:'relative',
  display:'block',
  bottom:'3rem',
  color:'#D6D6D6',
  cursor:'pointer',
  height:'20px',
  left:'470%'
}

export default function ResetPassword(props) {
  const { classes, dispatch, email, id, images } = props;

  const [newPassword, setNewPassword] = useState("");
  const [repeatNewPassword, setRepeatNewPassword] = useState("");
  const [error, setError] = useState({ message: null, type: null });
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);

  const locale = useContext(LocalizationContext);

  const checkRequiredFields = () => {
    // if (email.length === 0) {
    //   setError({ type: "email", message: "This field is required" });
    //   return false;
    // }

    if (newPassword.length === 0) {
      setError({ type: "newPassword", message: "This field is required" });
      return false;
    }

    if (repeatNewPassword !== newPassword) {
      setError({
        type: "repeatPassword",
        message: "The passwords do not match."
      });
      return false;
    }

    return true;
  };

  const resetPasswordSubmit = e => {
    document.activeElement.blur();
    e.preventDefault();

    if (!checkRequiredFields()) {
      return;
    }

    setLoading(true);

    if (success) {
      dispatch({ type: "loginClick" });
      return;
    }

    const user = {
      AppUser: {
        email: email,
        id: id
      },
      Password: newPassword,
      DistributorId: getDist().id
    };

    request()
      .post(`/api/appuser/restorepassword`, user)
      .then(response => {
        setSuccess(true);
        setLoading(false);
        setError({ type: "success", message: response.data });
      })
      .catch(error => {
        if (!error.response) {
          return;
        }
        console.log(error.response.data.message);
        setError({ type: "error", message: error.response.data.message });
        setLoading(false);
      });
  };

  return (
    <form className={classes.loginForm} onSubmit={e => resetPasswordSubmit(e)}>
      <Progress class={classes.loading} active={loading} />
      <div>
      </div>
      <h1 className={classes.loginText}>{locale.header.changePassword}</h1>
      {(error.type === "error" || error.type === "success") && (
        <div
          className={`alert ${
            error.type === "error" ? "alert-danger" : "alert-primary"
          }`}
          role="alert"
        >
          {error.message}
        </div>
      )}
      {!success && (
        <LoginInput
          claro={true}
          label={locale.form.input.email}
          value={email}
          onChange={value => {
            dispatch({ type: "setEmail", payload: value });
            error.type === "email" &&
              setError({ message: null, type: null });
          }}
          type={"text"}
          error={error.type === "email" ? error.message : ""}
          disabled={true}
        />
      )}
      {!success && (
      <ResetPasswordButton
      locale={locale}
      setNewPassword={setNewPassword}
      newPassword={newPassword}
      setError={setError}
      error={error}
      loading={loading}
      style={iconStyle}
      success={success}
      setRepeatNewPassword={setRepeatNewPassword}
      repeatNewPassword={repeatNewPassword}
      /> 
      )}
      <Button2
        type={"submit"}
        caption={
          success ? locale.form.submit.login : locale.form.submit.changePassword
        }
        disabled={loading}
      />
      <img
          src={images.logo}
          alt=""
          className={classes.logoTerragene}
        ></img>
    </form>
  );
}
