import React, {
  useState,
  useEffect,
  useReducer,
  useContext,
  useMemo,
  useRef,
} from "react";
import request from "../../utils/request";
import df from "../../utils/dateFormater";
import _ from "lodash";
import {
  getSession,
  getUserId,
  getUserCompany,
  getUserType,
  getDist,
} from "../../utils/sessionHandler";
import useStyles from "./WasherProvisoryStyles";
import { flatten } from "flat";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";
import { unflatten } from "flat";

import CRUD from "../../components/CRUD";
import PageContentLoading from "../../components/PageContentLoading";
import Modal from "../../components/Modal";
import CircularProgress from "../../components/CircularProgress";
import Progress from "../../components/Progress";
import Button from "../../components/Button";
import TrazantoIndicators from "../../components/TrazantoIndicators/TrazantoIndicators";
import { IndicatorType } from "../../dictionaries/indicatorTypes";
import QuickWasher from "../../components/QuickWasher/QuickWasher";
import GroupedDetail from "../../components/GroupedDetail/GroupedDetail";
import reducer from "./WasherProvisoryReducer";
import { ProductType } from "../../dictionaries/productType";

const formatSelectObject = (objectData, emptyText) => {
  return objectData.reduce(
    (objectsList, object) => {
      objectsList.push([object.id, object.description || object.toString]);
      return objectsList;
    },
    [[null, emptyText]]
  );
};
const formatSelectObjectWas = (objectData, emptyText) => {
  return objectData.reduce(
    (objectsList, object) => {
      objectsList.push([
        object.id,
        object.description || object.toString,
        object.sectorId,
      ]);
      return objectsList;
    },
    [[null, emptyText]]
  );
};

const descriptionFromArray = (items, id) => {
  let foundDescription = "";
  for (let i = 0; i < items.length; i++) {
    if (items[i][0] === id) {
      foundDescription = items[i][1];
    }
  }
  return foundDescription;
};

const initialState = {
  loadingContent: true,
  products: [],
  washers: [],
  detergentTypes: [],
  visualResults: [],
  requiredFields: [],
  formFields: [],
  commonFormFields: [],
  detailFields: [],
  extraDetailFields: [],
};

const Provisory = () => {
  const classes = useStyles();
  const locale = useContext(LocalizationContext);
  const CRUDRef = useRef(null);
  const groups = [
    { Description: t("ProductGroup"), position: 1, Index: 0, visible: true },
    { Description: t("ProcessGroup"), position: 2, Index: 1, visible: true },
    { Description: t("OtherGroup"), position: 3, Index: 2, visible: true },
    { Description: t("AnalysisGroup"), position: 0, Index: 3, visible: true },
  ];
  const indexProductGroup = groups[0].Index;
  const indexProcessGroup = groups[1].Index;
  const indexOtherGroup = groups[2].Index;
  const indexAnalysisGroup = groups[3].Index;

  const [state, dispatch] = useReducer(reducer, initialState);
  const [loadingData, setLoadingData] = useState(true);
  const [data, setData] = useState([]);
  const [loadingDetail, setLoadingDetail] = useState(false);
  const [detailObject, setDetailObject] = useState(null);
  const [toHistoricalObject, setToHistoricalObject] = useState(null);
  const [toHistoricalLoading, setToHistoricalLoading] = useState(false);
  const [toHistoricalMessage, setToHistoricalMessage] = useState({
    type: "none",
  });

  const detailFields = useMemo(
    () => [
      //Analysis group
      {
        label: t("UserLabel", locale),
        name: "appUser.toString",
        position: 0,
        groupIndex: indexAnalysisGroup,
      },
      {
        label: t("SectorLabel", locale),
        name: "sector.name",
        position: 1,
        groupIndex: indexAnalysisGroup,
      },
      {
        label: t("ResultLabel", locale),
        name: "resultDescription",
        position: 2,
        groupIndex: indexAnalysisGroup,
      },
      {
        label: t("VisualResultLabel", locale),
        name: "visualResultDescription",
        position: 3,
        groupIndex: indexAnalysisGroup,
      },
      {
        label: t("TestDateTimeLabel", locale),
        name: "creationTest",
        type: "dateTime",
        position: 4,
        groupIndex: indexAnalysisGroup,
      },
      {
        label: t("ImageLabel", locale),
        name: "image.base64",
        type: "image",
        position: 5,
        groupIndex: indexAnalysisGroup,
      },
      //Product group
      {
        label: t("ProductLabel", locale),
        name: "product.name",
        position: 0,
        groupIndex: indexProductGroup,
      },
      {
        label: t("BrandLabel", locale),
        name: "brand",
        position: 1,
        groupIndex: indexProductGroup,
      },
      {
        label: t("ProductLotLabel", locale),
        name: "loteProduct",
        position: 2,
        groupIndex: indexProductGroup,
      },
      {
        label: t("ManufactureDateLabel", locale),
        name: "manufactureDate",
        type: "date",
        position: 3,
        groupIndex: indexProductGroup,
      },
      {
        label: t("ExpirationDateLabel", locale),
        name: "expirationDate",
        type: "date",
        position: 4,
        groupIndex: indexProductGroup,
      },
      //Process group
      {
        label: t("CycleLabel", locale),
        name: "cycle",
        position: 0,
        groupIndex: indexProcessGroup,
      },
      {
        label: t("TemperatureLabel", locale),
        name: "temperature",
        position: 1,
        groupIndex: indexProcessGroup,
      },
      {
        label: t("WasherLabel", locale),
        name: "washer.toString",
        position: 2,
        groupIndex: indexProcessGroup,
      },
      {
        label: t("DetergentConcentrationLabel", locale),
        name: "detergentConcentration",
        position: 3,
        groupIndex: indexProcessGroup,
      },
      {
        label: t("DetergentTypeLabel", locale),
        name: "detergentTypeDescription",
        position: 4,
        groupIndex: indexProcessGroup,
      },
      {
        label: t("WaterPressureLabel", locale),
        name: "waterPressure",
        position: 5,
        groupIndex: indexProcessGroup,
      },
      {
        label: t("WaterHardnessLabel", locale),
        name: "waterHardness",
        position: 6,
        groupIndex: indexProcessGroup,
      },
      {
        label: t("LocationLabel", locale),
        name: "location",
        position: 7,
        groupIndex: indexProcessGroup,
      },
      {
        label: t("WashingTimeLabel", locale),
        name: "washingTime",
        position: 8,
        groupIndex: indexProcessGroup,
      },
      {
        label: t("ProgramLabel", locale),
        name: "programNumber",
        position: 9,
        groupIndex: indexProcessGroup,
      },
      //Other group
      {
        label: t("BookNumberLabel", locale),
        name: "bookNumber",
        position: 0,
        groupIndex: indexOtherGroup,
      },
      {
        label: t("PageNumberLabel", locale),
        name: "pageNumber",
        position: 1,
        groupIndex: indexOtherGroup,
      },
      {
        label: t("NotesLabel", locale),
        name: "notes",
        position: 2,
        groupIndex: indexOtherGroup,
      },
    ],
    [locale]
  );

  const extraDetailFields = useMemo(() => [], [locale]);

  // Form Fields
  const commonFormFields = () => [
    {
      position: 0,
      groupIndex: indexProcessGroup,
      label: t("CycleLabel", locale),
      propOnObject: "ReadingCHEWasher.cycle",
      propOnMassiveObject: "ReadCHEMasive.cycle",
      class: "col-12 col-md-6 px-0",
      required: state.requiredFields
        ? state.requiredFields.find((x) => x.objectName === 3050)
          ? true
          : false
        : false,
      type: "specialInput",
    },
    {
      position: 1,
      groupIndex: indexProcessGroup,
      label: t("TemperatureLabel", locale),
      propOnObject: "ReadingCHEWasher.temperature",
      propOnMassiveObject: "ReadCHEMasive.temperature",
      class: "col-12 col-md-6 px-0",
      required: state.requiredFields
        ? state.requiredFields.find((x) => x.objectName === 3060)
          ? true
          : false
        : false,
      type: "number",
    },
    {
      position: 2,
      groupIndex: indexProcessGroup,
      label: t("WasherLabel", locale),
      propOnObject: "ReadingCHEWasher.washerId",
      propOnMassiveObject: "ReadCHEMasive.washerId",
      component: (
        <a
          style={{ fontSize: 11 }}
          onClick={(e) => {
            e.preventDefault();
            dispatch({ type: "SHOW_ABM_WASHER" });
          }}
          href="#"
        >
          {t("AddWasher", locale)}
        </a>
      ),
      class: "col-12 col-md-6 px-0",
      required: state.requiredFields
        ? state.requiredFields.find((x) => x.objectName === 3070)
          ? true
          : false
        : false,
      type: "select",
      options: state.washers,
      id: "machine",
      userType: getUserType(),
      defaultValue: state.selectedWasher,
    },
    {
      position: 3,
      groupIndex: indexProcessGroup,
      label: t("LocationLabel", locale),
      propOnObject: "ReadingCHEWasher.location",
      propOnMassiveObject: "ReadCHEMasive.location",
      class: "col-12 col-md-6 px-0",
      required: state.requiredFields
        ? state.requiredFields.find((x) => x.objectName === 3080)
          ? true
          : false
        : false,
    },
    {
      position: 4,
      groupIndex: indexProcessGroup,
      label: t("WashingTimeLabel", locale),
      propOnObject: "ReadingCHEWasher.washingTime",
      propOnMassiveObject: "ReadCHEMasive.washingTime",
      class: "col-12 col-md-6 px-0",
      required: state.requiredFields
        ? state.requiredFields.find((x) => x.objectName === 3090)
          ? true
          : false
        : false,
      type: "specialInput",
    },
    {
      position: 5,
      groupIndex: indexProcessGroup,
      label: t("DetergentConcentrationLabel", locale),
      propOnObject: "ReadingCHEWasher.detergentConcentration",
      propOnMassiveObject: "ReadCHEMasive.detergentConcentration",
      class: "col-12 col-md-6 px-0",
      required: state.requiredFields
        ? state.requiredFields.find((x) => x.objectName === 3100)
          ? true
          : false
        : false,
      type: "number",
    },
    {
      position: 6,
      groupIndex: indexProcessGroup,
      label: t("DetergentTypeLabel", locale),
      propOnObject: "ReadingCHEWasher.detergentType",
      propOnMassiveObject: "ReadCHEMasive.detergentType",
      class: "col-12 col-md-6 px-0",
      required: state.requiredFields
        ? state.requiredFields.find((x) => x.objectName === 3110)
          ? true
          : false
        : false,
      type: "select",
      options: state.detergentTypes,
    },
    {
      position: 7,
      groupIndex: indexProcessGroup,
      label: t("ProgramLabel", locale),
      propOnObject: "ReadingCHEWasher.programNumber",
      propOnMassiveObject: "ReadCHEMasive.programNumber",
      class: "col-12 col-md-6 px-0",
      required: state.requiredFields
        ? state.requiredFields.find((x) => x.objectName === 3120)
          ? true
          : false
        : false,
    },
    {
      position: 0,
      groupIndex: indexAnalysisGroup,
      label: t("VisualResultLabel", locale),
      propOnObject: "ReadingCHEWasher.visualResult",
      propOnMassiveObject: "ReadCHEMasive.visualResult",
      class: "col-12 col-md-6 px-0",
      type: "select",
      options: state.visualResults,
      required: state.requiredFields
        ? state.requiredFields.find((x) => x.objectName === 3172)
          ? true
          : false
        : false,
    },
    {
      position: 1,
      groupIndex: indexAnalysisGroup,
      label: t("ResultLabel", locale),
      disabled: true,
      propOnObject: "ReadingCHEWasher.resultDescription",
      propOnMassiveObject: "ReadCHEMasive.resultDescription",
      class: "col-12 col-md-6 px-0",
    },
    {
      position: 8,
      groupIndex: indexProcessGroup,
      label: t("WaterPressureLabel", locale),
      propOnObject: "ReadingCHEWasher.waterPressure",
      propOnMassiveObject: "ReadCHEMasive.waterPressure",
      class: "col-12 col-md-6 px-0",
      required: state.requiredFields
        ? state.requiredFields.find((x) => x.objectName === 3130)
          ? true
          : false
        : false,
      type: "number",
    },
    {
      position: 9,
      groupIndex: indexProcessGroup,
      label: t("WaterHardnessLabel", locale),
      propOnObject: "ReadingCHEWasher.waterHardness",
      propOnMassiveObject: "ReadCHEMasive.waterHardness",
      class: "col-12 col-md-6 px-0",
      required: state.requiredFields
        ? state.requiredFields.find((x) => x.objectName === 3140)
          ? true
          : false
        : false,
      type: "number",
    },
    {
      position: 0,
      groupIndex: indexOtherGroup,
      label: t("BookNumberLabel", locale),
      propOnObject: "ReadingCHEWasher.bookNumber",
      propOnMassiveObject: "ReadCHEMasive.bookNumber",
      class: "col-12 col-md-6 px-0",
      required: state.requiredFields
        ? state.requiredFields.find((x) => x.objectName === 3150)
          ? true
          : false
        : false,
    },
    {
      position: 1,
      groupIndex: indexOtherGroup,
      label: t("PageNumberLabel", locale),
      propOnObject: "ReadingCHEWasher.pageNumber",
      propOnMassiveObject: "ReadCHEMasive.pageNumber",
      class: "col-12 col-md-6 px-0",
      required: state.requiredFields
        ? state.requiredFields.find((x) => x.objectName === 3160)
          ? true
          : false
        : false,
    },
    {
      position: 2,
      groupIndex: indexOtherGroup,
      label: t("NotesLabel", locale),
      class: "col-12 col-md-6 px-0",
      propOnObject: "ReadingCHEWasher.notes",
      propOnMassiveObject: "ReadCHEMasive.notes",
      required: state.requiredFields
        ? state.requiredFields.find((x) => x.objectName === 3170)
          ? true
          : false
        : false,
    },
  ];

  const extraFormFields = () => [];

  const setFieldsByProductType = (product, rowSelected) => {
    let extraForm = [];
    let extraDetails = [];
    // Indicadores que tienen información extra
    const withExtraInfo = [];
    if (withExtraInfo.includes(product.productModel.name.toUpperCase())) {
      extraForm = [...extraForm, ...extraFormFields()];
      extraDetails = [...extraDetails, ...extraDetailFields];
    }
    // Colocamos valores por defecto a los campos requeridos que no se muestran
    _.differenceWith(
      extraFormFields(),
      extraForm,
      (a, b) => a.propOnObject === b.propOnObject
    ).forEach((f) => {
      if (f.required && rowSelected)
        rowSelected[f.propOnObject] = f.type
          ? f.type === "select"
            ? f.options[1][0]
            : 0
          : "-";
    });
    dispatch({
      type: "FORMAT_ARRAY_FORM_FIELD",
      payload: {
        commonFormFields: commonFormFields(),
        extraFormFields: extraForm,
        detailCommonFields: detailFields,
        extraDetailsFields: extraDetails,
      },
    });
  };

  useEffect(() => {
    Promise.all([
      request().get(
        `/api/Product/GetByDistributorEncrypted?distributorId=${getDist().id}`
      ),
      request().get(`/api/Washer?CompanyId=${getUserCompany().Id}`),
      request().get(`/api/Enum/getDetergentTypes`),
      request().get(`/api/Enum/getUserEvaluation`),
      request().get(`/api/Screen/GetRequiredFields?Screen=600`),
    ])
      .then(
        ([
          productsData,
          washersData,
          detergentTypesData,
          visualResultsData,
          requiredFields,
        ]) => {
          const compatiblesTrazanto = productsData.data.filter(
            (x) =>
              x.productModel.compatibleTrazanto &&
              x.productModel.type === ProductType.ChemicalWasher
          );
          setTimeout(() => {
            dispatch({
              type: "SET_CONTENT",
              payload: {
                compatiblesTrazanto: compatiblesTrazanto,
                requiredFields: requiredFields.data,
                products: formatSelectObject(
                  productsData.data,
                  t("ChooseAProduct", locale)
                ),
                washers: formatSelectObjectWas(
                  washersData.data,
                  t("ChooseAWasher", locale)
                ),
                detergentTypes: formatSelectObject(
                  detergentTypesData.data,
                  t("ChooseADetergentType", locale)
                ),
                visualResults: formatSelectObject(
                  visualResultsData.data,
                  t("ChooseAVisualResult", locale)
                ),
              },
            });
          }, 250);
        }
      )
      .catch((error) => console.log(error.response));
  }, [locale]);

  useEffect(() => {
    if (!state.loadingContent) {
      updateData();
    }
  }, [
    state.loadingContent,
    state.products,
    state.washers,
    state.detergentTypes,
  ]);

  const updateData = () => {
    setLoadingData(true);
    request()
      .get(`/api/ReadingCHEWasher/getprovisional?UserLoggedId=${getUserId()}`)
      .then((response) => {
        setData(
          response.data
            .reduce((ciList, ci) => {
              ciList.push({
                ...ci,
                creationTestformatted: df(ci.creationTest, "dateTime"),
                manufactureDateformatted: df(ci.manufactureDate, "date"),
                expirationDateformatted: df(ci.expirationDate, "date"),
                productDescription: ci.productId
                  ? descriptionFromArray(state.products, ci.productId)
                  : "",
                washerDescription: descriptionFromArray(
                  state.washers,
                  ci.washerId
                ),
                detergentTypeDescription: descriptionFromArray(
                  state.detergentTypes,
                  ci.detergentType
                ),
              });
              return ciList;
            }, [])
            .map((x) =>
              _.mapKeys(x, (value, key) => {
                return "ReadingCHEWasher." + key;
              })
            )
        );
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => console.log(error.response));
  };

  const getDetail = (r) => {
    setLoadingDetail(true);
    request()
      .get(`/api/ReadingCHEWasher/getDetail?Id=${r["ReadingCHEWasher.id"]}`)
      .then((response) =>
        setTimeout(() => {
          const row = data.find(
            (x) => x["ReadingCHEWasher.id"] === response.data.id
          );
          const product = state.indicatorsCompatiblesTrazanto.find(
            (p) => p.id == row["ReadingCHEWasher.productId"]
          );
          row["ReadingCHEWasher.viewed"] = true;
          setData(data);
          setLoadingDetail(false);
          setDetailObject(flatten(response.data));
          setFieldsByProductType(product);
        }, 500)
      );
  };

  const toHistorical = (read) => {
    setToHistoricalLoading(true);
    request()
      .post("/api/ReadingCHEWasher/toHistorical", {
        ...unflatten(read),
        UserLogged: getSession(),
      })
      .then((response) => {
        if (response.data.result === "ERRORS") {
          setTimeout(() => {
            setToHistoricalObject(false);
            setToHistoricalLoading(false);
            setToHistoricalMessage({
              type: "error",
              message: response.data.errors.map((e) => <p key={e}>{e}</p>),
            });
            CRUDRef.current.api({ type: "clearSelected" });
            updateData();
          }, 500);
        } else {
          setTimeout(() => {
            setToHistoricalObject(false);
            setToHistoricalLoading(false);
            setToHistoricalMessage({
              type: "success",
              message: response.data.message,
            });
            CRUDRef.current.api({ type: "clearSelected" });
            updateData();
          }, 500);
          setTimeout(() => {
            setToHistoricalMessage({ type: "none" });
          }, 3000);
        }
      })
      .catch((error) => {
        setToHistoricalObject(false);
        setToHistoricalLoading(false);
        setToHistoricalMessage({
          type: "error",
          message: error.response?.data?.message || error.response?.data,
        });
      });
  };

  const toHistoricalMassive = (readings, ids) => {
    setToHistoricalLoading(true);
    request()
      .post("/api/ReadingCHEWasher/masiveToHistorical", {
        ReadCHEMasive: { ReadIds: ids, ...unflatten(readings[0]) },
        UserLogged: getSession(),
      })
      .then((response) => {
        if (response.data.result === "ERRORS") {
          setTimeout(() => {
            setToHistoricalObject(false);
            setToHistoricalLoading(false);
            setToHistoricalMessage({
              type: "error",
              message: response.data.errors.map((e) => <p key={e}>{e}</p>),
            });
            CRUDRef.current.api({ type: "clearSelected" });
            updateData();
          }, 500);
        } else {
          setTimeout(() => {
            setToHistoricalObject(false);
            setToHistoricalLoading(false);
            setToHistoricalMessage({
              type: "success",
              message: response.data.message,
            });
            CRUDRef.current.api({ type: "clearSelected" });
            updateData();
          }, 500);
          setTimeout(() => {
            setToHistoricalMessage({ type: "none" });
          }, 3000);
        }
      })
      .catch((error) => {
        setToHistoricalObject(false);
        setToHistoricalLoading(false);
        setToHistoricalMessage({
          type: "error",
          message: error.response?.data?.message || error.response?.data,
        });
      });
  };

  if (state.loadingContent) return <PageContentLoading />;

  return (
    <div>
      {toHistoricalMessage.type === "success" && (
        <div className={`alert alert-${toHistoricalMessage.type}`} role="alert">
          {toHistoricalMessage.message}
        </div>
      )}
      <TrazantoIndicators
        products={state.indicatorsCompatiblesTrazanto}
      ></TrazantoIndicators>
      <CRUD
        counterDown={{
          initialMinute: 0,
          initialSeconds: 10,
          refresh: updateData,
          onceTime: false,
        }}
        resultStatus={true}
        ref={CRUDRef}
        newOption={false}
        detailOption={false}
        deleteOption={false}
        multiSelect={true}
        editedInfo={{
          IndicatorType: IndicatorType.ChemicalWasher,
          enabled: true,
        }}
        viewedMark={true}
        massiveActions={[
          {
            caption: t("MultipleToHistoricalButton"),
            action: (a, b) =>
              setToHistoricalObject({
                object: a,
                action: () => toHistoricalMassive(a, b),
              }),
          },
        ]}
        data={data}
        loadingData={loadingData}
        url={"/api/ReadingCHEWasher"}
        massiveURL={"/api/ReadingCHEWasher/masiveModify"}
        title={t("Title", locale)}
        tableHeader={t("WasherProvisoryHeader", locale)}
        order={{ dir: "desc", orderBy: "ReadingCHEWasher.readNumber" }}
        method={"put"}
        idField={"ReadingCHEWasher.id"}
        splitButtonExtraOptions={[
          {
            text: t("ConfirmAndSave"),
            onClickOption: undefined,
            disabled: false,
            moveToSaved: true,
            useCommonFunction: true,
          },
        ]}
        btnTrazantoVisible={true}
        tableActions={[
          {
            icon: "M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z",
            tooltip: t("DetailTooltip"),
            action: getDetail,
          },
          {
            icon: "M21 10.12h-6.78l2.74-2.82c-2.73-2.7-7.15-2.8-9.88-.1-2.73 2.71-2.73 7.08 0 9.79s7.15 2.71 9.88 0C18.32 15.65 19 14.08 19 12.1h2c0 1.98-.88 4.55-2.64 6.29-3.51 3.48-9.21 3.48-12.72 0-3.5-3.47-3.53-9.11-.02-12.58s9.14-3.47 12.65 0L21 3v7.12zM12.5 8v4.25l3.5 2.08-.72 1.21L11 13V8h1.5z",
            tooltip: t("ToHistoricalTooltip"),
            action: (r) =>
              setToHistoricalObject({
                object: r,
                action: () => toHistorical(r),
              }),
          },
        ]}
        updateData={updateData}
        columns={[
          "ReadingCHEWasher.readNumber",
          "ReadingCHEWasher.creationTestformatted",
          "ReadingCHEWasher.resultDescription",
          "ReadingCHEWasher.visualResultDescription",
          "ReadingCHEWasher.manufactureDateformatted",
          "ReadingCHEWasher.productDescription",
          "ReadingCHEWasher.loteProduct",
          "ReadingCHEWasher.brand",
        ]}
        headers={[
          t("ReadNumberLabel", locale),
          t("TestDateTimeLabel", locale),
          t("ResultLabel", locale),
          t("VisualResultLabel", locale),
          t("ManufactureDateLabel", locale),
          t("ProductLabel", locale),
          t("ProductLotLabel", locale),
          t("BrandLabel", locale),
        ]}
        grouped={groups}
        useFieldMap={false}
        onEditClick={(rowSelected) => {
          const product = state.indicatorsCompatiblesTrazanto.find(
            (p) => p.id == rowSelected["ReadingCHEWasher.productId"]
          );
          setFieldsByProductType(product, rowSelected);
        }}
        formFields={state.formFields}
        formFieldsMachine={[state.washers]}
        baseObject={{
          ReadingCHEWasher: {
            cycle: null,
            temperature: null,
            washerId: null,
            location: null,
            washingTime: null,
            detergentConcentration: null,
            detergentType: null,
            programNumber: null,
            visualResult: null,
            waterPressure: null,
            waterHardness: null,
            bookNumber: null,
            pageNumber: null,
            notes: null,
          },
          UserLogged: getSession(),
        }}
        massiveBaseObject={{
          ReadCHEMasive: {
            ReadIds: [],
            cycle: null,
            temperature: null,
            washerId: null,
            location: null,
            washingTime: null,
            detergentConcentration: null,
            detergentType: null,
            programNumber: null,
            visualResult: null,
            waterPressure: null,
            waterHardness: null,
            bookNumber: null,
            pageNumber: null,
            notes: null,
          },
          UserLogged: getSession(),
        }}
      />

      {(loadingDetail || detailObject) && (
        <Modal
          header={
            <div className={classes.header}>
              <div className={classes.headerText}>{`${t("Title", locale)} ${
                detailObject ? `(${detailObject.readNumber})` : ""
              }`}</div>
              <span
                className={classes.modalCloseButton}
                onClick={() => setDetailObject(null)}
              >
                <svg viewBox="0 0 24 24">
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </svg>
              </span>
            </div>
          }
          closeModal={() => setDetailObject(null)}
          responsive
        >
          {!detailObject && (
            <div className={classes.detailLoading}>
              <CircularProgress />
            </div>
          )}
          {detailObject && (
            <GroupedDetail
              groups={groups}
              detailFields={detailFields}
              detailObject={detailObject}
            ></GroupedDetail>
          )}
        </Modal>
      )}
      {toHistoricalObject && (
        <Modal
          closeModal={() => setToHistoricalObject(null)}
          header={
            <React.Fragment>
              <div className={classes.header}>
                <span className={classes.headerText}>
                  {t("ToHistoricalHeader", locale)}{" "}
                  {toHistoricalObject.object.length > 0
                    ? "(" +
                      toHistoricalObject.object.reduce((name, item, index) => {
                        return (
                          name +
                          item["ReadingCHEWasher.toString"] +
                          (index === toHistoricalObject.object.length - 1
                            ? ""
                            : ", ")
                        );
                      }, "") +
                      ")"
                    : toHistoricalObject.object["ReadingCHEWasher.toString"]}
                </span>
              </div>
              <Progress active={toHistoricalLoading} />
            </React.Fragment>
          }
          footer={
            <div className={classes.footerAction}>
              <Button
                caption={t("CancelButton")}
                onClick={() => setToHistoricalObject(null)}
                variant={"text"}
                color={"error"}
                disabled={toHistoricalLoading}
              />
              <Button
                caption={t("ConfirmButton")}
                variant={"text"}
                onClick={toHistoricalObject.action}
                disabled={toHistoricalLoading}
              />
            </div>
          }
        >
          <div className={"container-fluid"}>
            <div className={"row"}>
              <div className={"col-12"} style={{ textAlign: "center" }}>
                {t("ToHistoricalConfirmationText", locale)}
              </div>
            </div>
          </div>
        </Modal>
      )}

      {toHistoricalMessage.type === "error" && (
        <Modal
          header={
            <div className={classes.header}>
              <div className={classes.headerText}></div>
              <span
                className={classes.modalCloseButton}
                onClick={() => setToHistoricalMessage({ type: "none" })}
              >
                <svg viewBox="0 0 24 24">
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </svg>
              </span>
            </div>
          }
          closeModal={() => setToHistoricalMessage({ type: "none" })}
        >
          <div className={`alert alert-danger`} role="alert">
            {toHistoricalMessage.message}
          </div>
        </Modal>
      )}
      {state.abmWasherVisible && (
        <Modal
          header={
            <div className={classes.header}>
              <div className={classes.headerText}>{t("AddWasher", locale)}</div>
              <span
                className={classes.modalCloseButton}
                onClick={() => dispatch({ type: "HIDE_ABM_WASHER" })}
              >
                <svg viewBox="0 0 24 24">
                  <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                </svg>
              </span>
            </div>
          }
          closeModal={() => dispatch({ type: "HIDE_ABM_WASHER" })}
        >
          <QuickWasher
            onClickCallBack={(e) => {
              const newWasher = [e.Id, e.Name, getSession().SectorId];
              state.washers.push(newWasher);
              dispatch({
                type: "ADD_WASHER",
                payload: { Washers: state.washers, Selected: e.Id },
              });
            }}
          ></QuickWasher>
        </Modal>
      )}
    </div>
  );
};

export default Provisory;
