import { makeStyles, createStyles } from "@material-ui/styles";
import index from '../../assets/Theme/index';

export default makeStyles((theme) => createStyles({
  root: {
    backgroundColor: theme.palette.terragene.darkSecondary,
    top: 0,
    left: "auto",
    right: 0,
    position: "fixed",
    width: "100%",
    display: "flex",
    flexShrink: "0",
    flexDirection: "row",
    zIndex: 1000,
    justifyContent: "space-between",
    alignItems: "center",
    height: "50px",
    fontFamily:"Montserrat",
    marginBottom:'50px',

  },

  navbar: {
    minHeight: 50,
    padding: "0 16px",
    display: "flex",
    position: "relative",
    alignItems: "center",


    [`@media (min-width: ${index.breakpoints["sm"]}px)`]: {
      minHeight: 50,
      padding: "0 24px"
    }
  },

  hamburgerButtonRoot: {
    marginLeft: -12,
    flex: "0 0 auto",
    padding: 12,
    fontSize: "1.5rem",
    textAlign: "center",
    borderRadius: "50%",
    border: 0,
    cursor: "pointer",
    margin: 0,
    display: "inline-flex",
    outline: 0,
    position: "relative",
    alignItems: "center",
    userSelect: "none",
    justifyContent: "center",
    textDecoration: "none",
    backgroundColor: "transparent",

    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)"
    }
  },

  hamburgerButtonContent: {
    color: "#fff",
    width: "100%",
    display: "flex"
  },

  hamburgerButtonSvg: {
    fill: "currentColor",
    width: "1em",
    height: "1em",
    display: "inline-block",
    fontSize: "1.5rem",
    flexShrink: 0,
    userSelect: "none"
  },

  user: {
    display: "flex",
    color: "#fff",
    height: "100%",
    alignItems: "center",
    margin: "0 16px",
    userSelect: "none",
    cursor: "pointer",
  },

  userName: {
    fontWeight: 700,
    margin: "0 16px",
  },

  userIcon: {
    width: 24,
    height: 24,
    "& svg": {
      fill: theme.palette.terragene.lightMain
    }
  },

  content: {
    display: "flex",
    transition: "margin 225ms cubic-bezier(0,0,.2,1) 0ms",
    [`@media (min-width: ${index.breakpoints["md"]}px)`]: {
      marginLeft: 75
    }
  },

  menuOpenGutter: {
    [`@media (min-width: ${index.breakpoints["md"]}px)`]: {
      marginLeft: 240
    }
  },

  contentChild: {
    flexGrow: 1,
    margin: "52px 10px 0px 1px",
    overflowX: "hidden",
    minHeight: "calc(100vh - 86px)",
    height: "100vh",
    

    [`@media (min-width: ${index.breakpoints["sm"]}px)`]: {
      minHeight: "calc(100vh - 94px)"
    }
  },

  mainDropdown: {
    width: 230,
    backgroundColor: theme.palette.terragene.darkSecondary,
    color: theme.palette.terragene.lightThird,
    '&:hover':{
      display: 'block'
    }
  },

  btnLogout: {
    marginTop: 10,
    backgroundColor: theme.palette.terragene.darkSecondary,
    fontFamily:"Montserrat",
    color: theme.palette.terragene.lightThird,
    borderTop: "2px solid " + theme.palette.terragene.lightMain,
    borderBottom: "2px solid " + theme.palette.terragene.lightMain,
    "&:hover": {
      backgroundColor: theme.palette.terragene.lightMain
    },
  },

  companyInfo: {
    backgroundColor: theme.palette.terragene.darkSecondary,
    color: theme.palette.terragene.lightThird,
    padding: 9,
    
    fontSize: 14,
    textTransform: 'uppercase',
    fontFamily:"Montserrat"
  },

  title: {
    backgroundColor: theme.palette.terragene.darkSecondary,
    color: theme.palette.terragene.lightThird,
    fontSize: 12,
    borderBottom: 'solid 1px '+theme.palette.terragene.lightThird,
    height: 20,
    fontFamily:"Montserrat"

  },

  rightContainer: {
    display: 'flex'
  },

  logoBionova: {
    width: theme.palette.images.widthLogoBionova ? theme.palette.images.widthLogoBionova : 110
  },

  logoTerragene:{
    width: theme.palette.images.widthLogo ? theme.palette.images.widthLogo : 170,
    marginBottom: 8
  }
}))
