import { makeStyles, createStyles } from "@material-ui/styles";
import index from '../../../assets/Theme/index';

export default makeStyles((theme) => createStyles({
  root: {
    position: "fixed",
    zIndex: 1300,
    

    [`@media(min-width:${index.breakpoints["md"]}px)`]: {
      zIndex: 1,
      height: "100vh",
    }
  },

  open: {
    [`@media(max-width:${index.breakpoints["md"]}px)`]: {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,

      "& $menuDrawer": {
        transform: "translateX(0)",
        // boxShadow:
        //   "0px 8px 10px -5px rgba(0,0,0,.2), 0px 16px 24px 2px rgba(0,0,0,.14), 0px 6px 30px 5px rgba(0,0,0,.12)"
      },

      "& $backdrop": {
        opacity: 1,
        display: "block"
      }
    },

    [`@media(min-width:${index.breakpoints["md"]}px)`]: {
      "& $menuDrawer": {
        width: 240
      }
    },
  },

  backdrop: {
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: "-1",
    position: "fixed",
    backgroundColor: "rgba(0,0,0,.5)",
    opacity: 0,
    transition: "opacity 225ms cubic-bezier(.4,0,.2,1) 0ms",
    display: "none",

    [`@media(min-width:${index.breakpoints["md"]}px)`]: {
      display: "none!important"
    }
  },

  menuDrawer: {
    position: "fixed",
    fontFamily:"Montserrat",
    backgroundColor: theme.palette.terragene.darkSecondary,
    display: "flex",
    flexDirection: "column",
    flex: "1 0 auto",
    top: 0,
    left: 0,
    right: "auto",
    height: "100%",
    overflow: "hidden",
    width: "100%",

    [`@media (min-width:${index.breakpoints["sm"]}px)`]: {
      width: "66%"
    },

    [`@media(max-width:${index.breakpoints["md"]}px)`]: {
      transform: "translateX(-100%)",
      transition: "transform 225ms cubic-bezier(0,0,.2,1) 0ms"
    },

    [`@media (min-width:${index.breakpoints["md"]}px)`]: {
      width: 75,
      transform: "translateX(0)",
      transition: "width 225ms cubic-bezier(0,0,.2,1) 0ms",
      position: "relative",
      height: "100%",
      // boxShadow:
      //   "0px 8px 10px -5px rgba(0,0,0,.2), 0px 16px 24px 2px rgba(0,0,0,.14), 0px 6px 30px 5px rgba(0,0,0,.12)"
    }
    
  },

  menuHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: "8px 16px",
    height: 50,
    borderBottom: "1px solid rgba(0,0,0,.12)"
  },

  menuCloseButton: {
    color: "#9e9e9e",
    padding: 12,
    borderRadius: "50%",
    cursor: "pointer",
    userSelect: "none",

    "&:hover": {
      background: "rgba(0,0,0,.08)"
    },

    "& svg": {
      fill: "currentcolor",
      width: "1em",
      height: "1em",
      fontSize: "1.5rem",
      userSelect: "none"
    }
  },
}))
