import React from "react";
import ReactDOM from "react-dom";
import { Router, Route, Redirect, Switch } from "react-router-dom";
import history from "./utils/history";
import { checkToken, getDist, saveDist, sessionExpired } from "./utils/sessionHandler";
import * as serviceWorker from "./utils/serviceWorker";

import "./index.css";
import Login from "./routes/Login";
import Home from "./routes/Home";

import App from "./Modules/App";
import Sys from "./Modules/Sys";
import Dist from "./Modules/Dist";
import Common from "./Modules/Common";
import SessionExpired from "./routes/SessionExpired";
import Test from "./pages/Test/Test";
import { CircularProgress, createMuiTheme, ThemeProvider } from "@material-ui/core";
import request from "./utils/request";
import FixedAlert from "./components/FixedAlert/FixedAlert";
import Screen from "./pages/Screen";
import {getSysUserLanguage} from './utils/sessionHandler'
import PageDownloader from "./pages/Downloader/PageDownloader";
import { isUndefined } from "lodash";
import PrivacyPolicy from "./routes/PrivacyPolicy";
import Resources from "./pages/Resources/Resources";
import UserResetPassword from "./routes/UserResetPassword";

const getThemeDomain = (theme) => {
  return createMuiTheme(theme);
}

const loadDynamicTheme = (distributor) => {
  import(`./assets/Theme/${distributor.themeFileName}`).then(theme => {
    document.title = theme.default.title ? theme.default.title : 'Bionova Cloud - Terragene';
    const favicon = document.getElementById("favicon");    
    favicon.href = theme.default.favicon ? `/${theme.default.favicon}` : "/favicon.ico"
    ReactDOM.render(<AppTerragene theme={theme.default} />, document.getElementById("root"));
  }).catch(err => {
    console.log(err)
  })
}

const searchDistributorByDomain = () => {
  const distributor = getDist();
  if (!distributor) {
    request().post(`/api/Distributor/PostByDomain`, {
      Domain: window.location.origin
    }).then((respo) => {
      saveDist(respo.data)
      loadDynamicTheme(respo.data);
    })
      .catch((err) => {
        ReactDOM.render(<FixedAlert
          text={err?.response?.data?.Message}
          type={"error"}
          closeErrorCb={() => { }}
        ></FixedAlert>, document.getElementById("root"));
      });
  } else {
    loadDynamicTheme(distributor);
  }
}

const AppTerragene = ({ theme }) => {
  return (
    <ThemeProvider theme={getThemeDomain(theme)}>
      <Router history={history}>
        <Switch>
          {App.map((r) => (
            <Route
              exact
              path={r.path}
              key={r.path}
              render={(props) =>
                checkToken() ? (
                  <r.component {...props} />
                ) : (
                    <Redirect to="/login" />
                  )
              }
            />
          ))}
          {Sys.map((r) => (
            <Route
              exact
              path={r.path}
              key={r.path}
              render={(props) =>
                checkToken() ? (
                  <r.component {...props} />
                ) : (
                    <Redirect to="/login" />
                  )
              }
            />
          ))}
          {Common.map((r) => (
            <Route
              exact
              path={r.path}
              key={r.path}
              render={(props) =>
                checkToken() ? (
                  <r.component {...props} />
                ) : (
                    <Redirect to="/login" />
                  )
              }
            />
          ))}
          {Dist.map((r) => (
            <Route
              exact
              path={r.path}
              key={r.path}
              render={(props) =>
                checkToken() ? (
                  <r.component {...props} />
                ) : (
                    <Redirect to="/login" />
                  )
              }
            />
          ))}
          <Route exact path="/accesstotranslate"
            render={(props) => 
            !isUndefined(getSysUserLanguage()) ? (
                <Screen {...props} />
              ) : (
                  <Redirect to="/login" />
                )
            }
          />

          <Route exact path="/downloadagent" render={() => <PageDownloader></PageDownloader>} />

          <Route
            exact
            path="/login/:mode/:email"
            render={() => <Login />}
          />
          <Route
            exact
            path="/login/:mode"
            render={() => <Login />}
          />
          <Route
            exact
            path="/login"
            render={() => (checkToken() ? <Redirect to="/" /> : <Login />)}
          />

          <Route
            exact
            path="/login/passwordrecovery/:token/:email/:distId"
            render={() => <UserResetPassword />}
          />

          <Route
            exact
            path="/sessionExpired"
            render={() =>
              !sessionExpired() ? <Redirect to="/" /> : <SessionExpired />
            }
          />

          <Route
            exact
            path="/privacyPolicy"
            render={() => < PrivacyPolicy/>}
          />

          <Route
            exact
            path="/test"
            render={() => <Test />}
          />
          <Route
            exact
            path="/resources"
            render={() => <Resources />}
          />
          <Route
            render={() => (checkToken() ? <Home /> : <Redirect to="/login" />)}
          />
        </Switch>
      </Router>
    </ThemeProvider>
  );
};

ReactDOM.render(<div style={{ textAlign: 'center', marginTop: 200 }}><CircularProgress /></div>, document.getElementById("root"));
searchDistributorByDomain();

serviceWorker.unregister();
