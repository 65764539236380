import { makeStyles, createStyles } from '@material-ui/styles';

export default makeStyles((theme) => createStyles({
    parameterActionsRoot: {
        '& button': {
            margin: '8px 16px 0 0'
        }
    },

    labelCss: {
        borderRadius: 5,
        width: '300px',
        height: '32px',
        textAlign: 'center',
        padding: 4,
        margin: '8px 0 4px 0',
        display: 'block'
    },

    modalCloseButton: {
        color: "#9e9e9e",
        borderRadius: "50%",
        cursor: "pointer",
        userSelect: "none",

        '&:hover': {
            color: '#5a5a5d'
        },

        "& svg": {
            fill: "currentcolor",
            width: "1em",
            height: "1em",
            fontSize: "1.5rem",
            userSelect: "none"
        }
    },

    header: {
        display: 'flex',
        flexDirection: 'row-reverse',
        padding: '16px 24px',
    },

    headerText: {
        fontSize: 17,
        fontWeight: 500,
        lineHeight: '1.5rem',
        color: '#5a5a5d',
        position: 'absolute',
        left: 25
    },

    footerAction: {
        position: 'absolute',
        right: 20,
        '& *': {
            margin: '0 8px'
        }
    },

    root: {
        fontSize: "0.875em",
        fontWeight: 700,
        transition: "background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
        lineHeight: "1.75",
        borderRadius: 4,
        letterSpacing: "0.02857em",
        textTransform: "uppercase",
        border: "0!important",
        outline: "0!important"
    },

    disabled: {
        cursor: "auto!important",
        color: "rgba(0,0,0,0.26)",
    },

    containedVariant: {
        padding: "4px 10px",

        '&$disabled': {
            boxShadow: "none",
            backgroundColor: "rgba(0,0,0,0.12)"
        }
    },

    textVariant: {
        padding: '6px 8px',
        backgroundColor: 'transparent'
    },

    primaryColor: {
        '&$containedVariant$active': {
            color: theme.palette.terragene.lightThird,
            backgroundColor: theme.palette.terragene.lightMain,
            boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",

            "&:hover": {
                backgroundColor: theme.palette.terragene.darkThird
            }
        },

        '&$textVariant$active': {
            color: theme.palette.terragene.darkMain,

            "&:hover": {
                backgroundColor: 'rgba(3, 69, 128, 0.08)'
            }
        }
    },

    secondaryColor: {
        '&$containedVariant$active': {
            color: theme.palette.terragene.lightThird,
            backgroundColor: theme.palette.terragene.darkMain,
            boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",

            "&:hover": {
                backgroundColor: theme.palette.terragene.darkThird
            }
        },

        '&$textVariant$active': {
            color: theme.palette.terragene.darkMain,

            "&:hover": {
                backgroundColor: 'rgba(3, 69, 128, 0.08)'
            }
        }
    },

    errorColor: {
        '&$containedVariant$active': {
            color: "#fff",
            backgroundColor: theme.palette.error,
            boxShadow: "0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12)",

            "&:hover": {
                backgroundColor: theme.palette.errorAction
            }
        },

        '&$textVariant$active': {
            color: theme.palette.errorAction,

            "&:hover": {
                backgroundColor: 'rgba(176, 0, 32, 0.08)'
            }
        }
    },

    fullwidth: {
        width: "100%",
    },

    verticalMargin: {
        margin: "10px 0"
    },

    lateralMargin: {
        margin: "0 10px"
    },

    dense: {
        margin: 0
    },

    active: {},
}))