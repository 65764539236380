import React, { useReducer, useEffect, useContext, useState } from "react";
import request from "../../utils/request";
import { getUserCompany, getSession } from "../../utils/sessionHandler";
import df from "../../utils/dateFormater";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";

import Button from "../../components/Button";
import Select2 from "../../components/Select2";
import DateInput from "../../components/DateInput";
import Input from "../../components/Input";
import Chart from "../../components/Chart";
import PageContentLoading from "../../components/PageContentLoading";
import CircularProgress from "../../components/CircularProgress";
import { ChartModel } from '../../dictionaries/chartModel'
import reducer from './IQASReducer'
import FixedAlert from "../../components/FixedAlert/FixedAlert";
import Button2 from "../../components/Button2";
import MgInput from "../../components/MgInput";

const formatSelectObject = (objectData, emptyText) => {
  return objectData.reduce(
    (objectsList, object) => {
      objectsList.push([
        object.id,
        object.description || object.toString,
        object.brand,
        object.serial,
      ]);
      return objectsList;
    },
    [[null, emptyText]]
  );
};

const initialState = {
  pageContentLoading: true,
  washers: [],
  chartModels: [],
  selectedWasher: "",
  selectedChartModel: "",
  dateFrom: null,
  dateTo: null,
  measurementGrouping: null,
  chartData: [],
};

export default function IQAS() {
  const locale = useContext(LocalizationContext);
  const [state, dispatch] = useReducer(reducer, initialState);
  const {
    pageContentLoading,
    washers,
    selectedWasher,
    chartModels,
    selectedChartModel,
    dateFrom,
    dateTo,
    measurementGrouping,
    chartData,
  } = state;
  const [loadingChartData, setLoadingChartData] = useState(false);
  const [showsChart, setShowsChart] = useState(false);
  const [errors, setErrors] = useState({});
  const [chartErrorMessage, setChartErrorMessage] = useState(null);

  useEffect(() => {
    Promise.all([
      request().get(`/api/washer/GetByUserLogged`),
      request().get(`/api/HTM/GetChartModels`),
    ])
      .then(([washersData, chartModelData]) => {
        var date = new Date();
        var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
        var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);
        dispatch({ type: "SET_DATEFROM", payload: firstDay })
        dispatch({ type: "SET_DATETO", payload: lastDay })
        dispatch({type: "SET_WASHERS", payload: formatSelectObject(washersData.data, t("ChooseAWasherText", locale))});
        dispatch({type: "SET_CHARTMODELS", payload: formatSelectObject(chartModelData.data, t("ChooseAChartModelText", locale))});
        dispatch({ type: "SET_CONTENTLOADING", payload: false });
        dispatch({ type: "SET_MEASUREMENTSGROUPING", payload: { measurementGrouping: 1 } });
        dispatch({ type: "SELECT_CHARTMODEL", payload: "0" });
      })
      .catch((error) => console.log(error));
  }, [locale]);

  useEffect(() => {
    setErrors({});
    setShowsChart(false);
  }, [
    selectedWasher,
    dateFrom,
    dateTo,
    measurementGrouping,
    selectedChartModel,
  ]);

  const showIgnoredReadings = (requestObject) => {
      request().post(`/api/HTM/GetIgnoredReadings`, requestObject).then((resp) => {
        if(resp.data.countIgnoredReadings > 0){
          dispatch({ type: "SET_IGNOREDREADINGS", payload: resp.data.countIgnoredReadings + ' ' + resp.data.message });
        }
      })
      .catch((error) => console.log(error));
  }

  const validateRequiredFields = () => {
    let error = {};

    if (selectedWasher === "") error.selectedWasher = t("RequiredFieldError");
    if (!dateFrom) error.dateFrom = t("RequiredFieldError");
    if (!dateTo) error.dateTo = t("RequiredFieldError");
    if (!measurementGrouping)
      error.measurementGrouping = t("RequiredFieldError");
    if (selectedChartModel === "")
      error.selectedChartModel = t("RequiredFieldError");

    return error;
  };

  const generateChart = () => {
    setChartErrorMessage(null);
    const error = validateRequiredFields();

    if (Object.keys(error).length > 0 || false) {
      setErrors(error);
    } else {
      setLoadingChartData(true);
      setShowsChart(false);
      const requestUrl =
        selectedChartModel == "0" || selectedChartModel == "1"
          ? "/api/HTM/calculateIMR"
          : "/api/HTM/calculateXbarR";
      const requestObject = {
        Washer: { Id: selectedWasher },
        DateFrom: dateFrom.toISOString(),
        DateTo: dateTo.toISOString(),
        ChartModel: selectedChartModel,
        SizeGroup: measurementGrouping,
        UserLogged: getSession(),
      };
      showIgnoredReadings(requestObject)
      request()
        .post(requestUrl, requestObject)
        .then((response) => {
          dispatch({ type: "SET_CHARTDATA", payload: response.data });
          setLoadingChartData(false);
          setShowsChart(true);
        })
        .catch((error) => {
          setChartErrorMessage(error?.response?.data?.message);
          setLoadingChartData(false);
        });
    }
  };

  const formatChartData = (data) => {
    const readsKey =
      selectedChartModel == "0" || selectedChartModel == "1"
        ? "reads"
        : "groupReads";

    if (!data[readsKey]) return [[], [], []];
    const excludeTooltip = [];
    const references = {
      xvalue: data.xvalue,
      lcLvalue: data.lcLvalue,
      ucLvalue: data.ucLvalue,
    };

    let patternCount = 0;
    const newData = data[readsKey].reduce((chartData, modifiedRead) => {
      const value =
        selectedChartModel == "0"
          ? modifiedRead.logarithm
          : selectedChartModel == "1"
          ? modifiedRead.absoluteDiff
          : selectedChartModel == "2"
          ? modifiedRead.avergeLogs
          : selectedChartModel == "3"
          ? modifiedRead.range
          : null;

      modifiedRead.resultDateFormatted = df(modifiedRead.resultDate, "date");

      if (modifiedRead.pattern === true) {
        modifiedRead.patternValue = value;
        modifiedRead.patternTypeDesc = data.patternTypeDescription;

        excludeTooltip.push(
          readsKey === "reads"
            ? modifiedRead.readNumber
            : modifiedRead.groupNumber
        );

        patternCount++;
      }

      modifiedRead.value = value;

      if (modifiedRead.show === false) {
        modifiedRead.patternValue = null;
        modifiedRead.value = null;
      }

      return chartData.concat(modifiedRead);
      // if (patternCount > 1) {
      //   return chartData;
      // } else {
      //   return chartData.concat(modifiedRead);
      // }
    }, []);
    return [newData, excludeTooltip, references];
  };

  if (pageContentLoading) return <PageContentLoading />;

  const [chartDataFormatted, excludePoints, references] = formatChartData(chartData);

  return (
    <div style={{fontFamily:"Montserrat"}} className="container-fluid px-0">
      {chartErrorMessage && (
        <div className="row mx-0" style={{ marginTop: 15 }}>
          <div className="col-12">
            <div className="alert alert-danger" role="alert">
              {chartErrorMessage}
            </div>
          </div>
        </div>
      )}

      <div className="row mx-0">
        <div className="col-md-6">
          <Select2
            label={t("WasherLabel", locale)}
            value={selectedWasher}
            options={washers}
            onChange={(val) =>
              dispatch({ type: "SELECT_WASHER", payload: val })
            }
            variant="outlined"
            disabled={loadingChartData}
            error={errors.selectedWasher}
          />
        </div>
        <div className="col-md-3 col-6">
          <DateInput
            value={dateFrom}
            onChange={(val) => dispatch({ type: "SET_DATEFROM", payload: val._d })}
            label={t("DateFromLabel", locale)}
            variant="outlined"
            gutter="38px 0px 0px"
            disabled={loadingChartData}
            error={errors.dateFrom}
          />
        </div>
        <div className="col-md-3 col-6">
          <DateInput
            value={dateTo}
            onChange={(val) => {
              dispatch({ type: "SET_DATETO", payload: val.endOf('day')._d })}

            }
            label={t("DateUntilLabel", locale)}
            variant="outlined"
            gutter="38px 0px 0px"
            disabled={loadingChartData}
            error={errors.dateTo}
          />
        </div>
        <div className="col-md-6">
          <MgInput
            label={t("MGLabel", locale)}
            value={measurementGrouping}
            onChange={(val) => {
              let newChartModel;
              const value = parseInt(val)
              switch(true) {
                case value == 1:
                  newChartModel = (state.selectedChartModel == 0 || state.selectedChartModel == 1) ? state.selectedChartModel : ChartModel.Individual
                  break;
                case value >= 2:
                  newChartModel = (state.selectedChartModel == 2 || state.selectedChartModel == 3) ? state.selectedChartModel : ChartModel.XBAR
                  break;
                default:
                  break;
              }
              dispatch({ type: "SET_MEASUREMENTSGROUPING", payload: { measurementGrouping: val, newChartModel: newChartModel} })
            }}
            variant="outlined"
            type="number"
            gutter="38px 0px 0px 25px"
            min={1}
            max={10}
            disabled={loadingChartData}
            error={errors.measurementGrouping}
          />
        </div>
        <div className="col-md-6">
          <Select2
            label={t("ChartModelLabel", locale)}
            value={selectedChartModel}
            options={
              measurementGrouping < 2
                ? chartModels
                    .filter((x) => x[0] !== 2)
                    .filter((x) => x[0] !== 3)
                : chartModels
                    .filter((x) => x[0] !== 0)
                    .filter((x) => x[0] !== 1)
            }
            onChange={(val) =>
              dispatch({ type: "SELECT_CHARTMODEL", payload: val })
            }
            variant="outlined"
            disabled={loadingChartData}
            error={errors.selectedChartModel}
          />
        </div>
      </div>
      <div className="row mx-0">
        <div className="col-12" style={{paddingBottom: 10}}>
          <FixedAlert
            type={'info'}
            text={state.countIgnoredReadings}
            dispatch={dispatch}
          />
        </div>
      </div>
      <div className="row mx-0">
        <div className="col-12"                      >
          <Button2
            caption={t("GenerateButton")}
            onClick={generateChart}
            loading={loadingChartData}
          />
        </div>
      </div>

      {loadingChartData && (
        <div className="row mx-0" style={{ marginTop: 20 }}>
          <div
            style={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <CircularProgress size={50} />
          </div>
        </div>
      )}

      {showsChart && (
        <div className="row mx-0" style={{ marginTop: 15 }}>
          <div className="col-12">
            <Chart
              data={chartDataFormatted}
              yAxisLabel={t("ProLevelLabel", locale)}
              xAxisLabel={t("ReadNumberLabel", locale)}
              XAxisKey={
                selectedChartModel === "0" || selectedChartModel === "1"
                  ? "readNumber"
                  : "groupNumber"
              }
              line={[
                {
                  key: "value",
                  color: "black",
                  label: t("ProLevelLabel", locale),
                  tooltip: [
                    { value: "value", label: t("ProLevelLabel", locale) },
                    {
                      value: "readNumber",
                      label: t("ReadNumberLabel", locale),
                    },
                    {
                      value: "resultDateFormatted",
                      label: t("ResultDateLabel", locale),
                    },
                    {
                      value: "groupNumber",
                      label: t("GroupNumberLabel", locale),
                    },
                  ],
                  excludeTooltip: excludePoints,
                },
                {
                  key: "patternValue",
                  color: "red",
                  label: t("ProLevelLabel", locale),
                  hideLegend: true,
                  tooltip: [
                    { value: "value", label: t("ProLevelLabel", locale) },
                    {
                      value: "readNumber",
                      label: t("ReadNumberLabel", locale),
                    },
                    {
                      value: "resultDateFormatted",
                      label: t("ResultDateLabel", locale),
                    },
                    {
                      value: "groupNumber",
                      label: t("GroupNumberLabel", locale),
                    },
                    {
                      value: "patternTypeDesc",
                      label: t("PatternTypeLabel", locale),
                    },
                  ],
                },

                { key: "", color: "green", label: t("CenterLabel", locale) },
                { key: "", color: "blue", label: t("LCLLabel", locale) },
                { key: "", color: "yellow", label: t("UCLLabel", locale) },
              ]}
              referenceLine={[
                { value: references.xvalue, label: "", color: "green" },
                { value: references.lcLvalue, label: "", color: "blue" },
                { value: references.ucLvalue, label: "", color: "yellow" },
              ]}
            />
          </div>
        </div>
      )}
    </div>
  );
}
