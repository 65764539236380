import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles((theme) => createStyles({
  root: {
    width: "30%",
    display: "flex",
    height: 35,
    backgroundColor: "#f7f7f7",
    padding: "25px 8px",
    position: "relative",
    alignItems: "center",
    transition: "box-shadow 0.18s ease-out, background-color 0.25s ease-out",
    fontFamily:"Montserrat",
  },

  rootActive: {
    backgroundColor: "#f7f7f7",
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.07)",

    "& $searchIcon": {
      opacity: 0,
      transform: "scale(0)"
    },

    "& $clearIcon": {
      opacity: 1,
      transform: "scale(1)"
    },

    "& $iconHover:hover": {
      cursor: "pointer",
      borderRadius: "50%",
      backgroundColor: "#f8f8f8"
    }
  },

  iconHover: {},

  iconRoot: {
    width: 30,
    height: 25,
    //display: "flex",
    //alignItems: "center",
    //justifyContent: "center",
    position: "absolute",
    left: 15,

    "& svg": {
      width: 20,
      height: 20,
      marginLeft: 3
    },

    "& path": {
      width: 20,
      height: 20,
      fill: "currentcolor"
    }
  },

  searchIcon: {
    color: theme.palette.terragene.darkMain,
    opacity: 1,
    transition: "all 0.24s cubic-bezier(0.4, 0, 0.2, 1)"
  },

  clearIcon: {
    color: theme.palette.primaryLight,
    opacity: 0,
    transform: "scale(0)",
    transition: "all 0.3s cubic-bezier(0.4, 0, 0.2, 1)"
  },

  inputRoot: {
    width: "100%",
    height: 25,
    flexGrow: 1,
    borderRadius: 20,
    outline: 0,
    border: 0,
    backgroundColor: "#fff",
    paddingLeft: 45,
    paddingRight: 15,
    fontFamily:"Montserrat"
  }
}))