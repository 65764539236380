import React, { useEffect, useContext, useReducer } from "react";
import request from "../../utils/request";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";
import PageContentLoading from "../../components/PageContentLoading";
import CRUD from "../../components/CRUD/CRUD";
import { getSession, getUserCompany, getSectorId } from "../../utils/sessionHandler";
import reducer from './AppMigrationDataBaseReducer';
import dateFormater from "../../utils/dateFormater";
import Select from "../../components/Select/Select";
import Uploader from "../../components/Uploader";
import Button from "../../components/Button";
import useStyles from './AppMigrationDatabaseStyles';
import FixedAlert from "../../components/FixedAlert/FixedAlert";
import Modal from "../../components/Modal/Modal";

const initialState = {
    loadingContent: true,
    loadingData: true,
    loadingSave: false,
    migrations: [],
    file: null,
    success: "",
    error: "",
    disableSave: true,
    sectors: [],
    sectorSelected: getSectorId(),
    openModal: false,
    clearFileSelected: false,
    disableSelectFile: false
}

const formatMigrations = (migrations) => {
    migrations.forEach((migration) => {
        migration.migrationDate = dateFormater(migration.migrationDate, "dateTime")
    })
    return migrations;
}

export default function AppMigrationDatabase() {
    const classes = useStyles();
    const locale = useContext(LocalizationContext);
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect(() => {
        searchMigrations();
        request().get(`/api/sector/GetIdNameSectorsByCompanyId?companyId=${getUserCompany().Id}`).then((response) => {
            const sectorList = response.data.reduce((sectorsList, sector) => {
                sectorsList.push([sector.id, sector.description]);
                return sectorsList;
            }, []);
            dispatch({ type: 'SET_SECTORS', payload: { sectors: sectorList } });
        });
    }, [])

    const searchMigrations = () => {
        request().get(`/api/DesktopMigrator/${getUserCompany().Id}`).then((response) => {
            dispatch({ type: 'SET_MIGRATIONS', payload: { migrations: formatMigrations(response.data) } })
            
            let processInProgress = response.data.find(x => x.statusEnum == 1)
            if (processInProgress)
                dispatch({ type: 'SET_BUTTON_SELECTFILE', payload: { disableSelectFile: true } })
        })
    }

    const onChangeSectors = (id) => {
        id = parseInt(id);
        dispatch({ type: 'SET_SECTOR_SELECTED', payload: { sectorSelected: id } });
    }

    const save = async (sectorId) => {
        dispatch({ type: 'SET_SAVING', payload: { disableSave: true, loadingSave: true } })
        dispatch({ type: 'SET_MODAL', payload: { openModal: false } })
        dispatch({ type: 'SET_BUTTON_SELECTFILE', payload: { disableSelectFile: true } })
        const file = new FormData();
        file.append("file", state.file);
        file.append("sectorId", sectorId);
        try {
            let newResponse = await request().post(`/api/desktopmigrator`, file)
            newResponse.data.migration.migrationDate = dateFormater(newResponse.data.migration.migrationDate, "dateTime")
            updateExistingMigration(newResponse.data.migration)
            dispatch({ type: 'SET_SAVING', payload: { disableSave: true, loadingSave: true } })
            dispatch({ type: 'SET_MIGRATIONS', payload: { migrations: state.migrations, alertText: newResponse.data.message, alertType: 'success' } })
            //---------------------------------x-------------------------------------------//
            let updatedResponse = await request().post(`/api/desktopmigrator/processMigration`, newResponse.data.migration.id)
            const migration = state.migrations.find(x => x.id === updatedResponse.data.migration.id)
            migration.totalImportedResults = updatedResponse.data.migration.totalImportedResults
            migration.status = updatedResponse.data.migration.status
            migration.statusEnum = updatedResponse.data.migration.statusEnum
            dispatch({ type: 'SET_FILE', payload: { clearFileSelected: true } })
            dispatch({ type: 'SET_BUTTON_SELECTFILE', payload: { disableSelectFile: false } })
            dispatch({ type: 'SET_ALERT', payload: { disableSave: true } })
            dispatch({ type: 'SET_MIGRATIONS', payload: { migrations: state.migrations, alertText: updatedResponse.data.message, alertType: 'success' } })

        } catch (error) {
            if (error?.response?.data?.data)
                updateExistingMigration(error?.response?.data?.data)
            dispatch({ type: "SET_ALERT", payload: { message: error?.response?.data?.message, type: 'error', disableSave: false } })
            dispatch({ type: "SET_BUTTON_SELECTFILE", payload: { disableSelectFile: false } })
        }
    }

    const updateExistingMigration = (migration) => {
        let existingMigration = state.migrations.find(x => x.id == migration.id)
        if (existingMigration) {
            existingMigration.status = migration.status
            existingMigration.statusEnum = migration.statusEnum
            existingMigration.sectorName = migration.sectorName ? migration.sectorName : existingMigration.sectorName

        } else {
            state.migrations.push(migration)
        }
    }

    const header = (
        <React.Fragment>
            <div className={classes.header}>
                <span className={classes.modalCloseButton} onClick={() => dispatch({ type: 'SET_MODAL', payload: { openModal: false } })}>
                    <svg viewBox="0 0 24 24">
                        <path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z" />
                    </svg>
                </span>
                <div className={classes.headerText}>
                    {t("WarningMigrationTitle", locale)}
                </div>
            </div>
        </React.Fragment>
    );

    const footer = (
        <div className={classes.footerAction}>
            <Button
                caption={t("ConfirmButton", locale)}
                dense
                onClick={() => save(state.sectorSelected)}
            />
        </div>
    );

    if (state.loadingContent) return <PageContentLoading />;

    return (
        <>
            <FixedAlert text={state.alertText} type={state.alertType} closeErrorCb={() => { dispatch({ type: "SET_ALERT", payload: { message: "", disableSave: state.alertType == 'error' ? false : true } }) }}></FixedAlert>
            <div className={"row"}>
                <div className={"col-lg-4"} style={{ paddingTop: 12, paddingBottom: 12 }}>
                    <Uploader
                        file={state.file}
                        dispatch={dispatch}
                        action={'SET_FILE'}
                        translation={{
                            title: t("DatabaseImporter", locale),
                            uploadButtonText: t("UploadDatabaseLabel", locale),
                            saveButtonText: t("Save"),
                            loadingText: t("Saving", locale),
                            invalidExtensionError: t("ValidationExtensionText", locale),
                            info: [
                                t("SizeCompanyLogoLabel", locale),
                                t("AspectCompanyLogoLabel", locale)
                            ]
                        }}
                        options={{
                            showPreViewImage: false,
                            showFileName: true,
                            extensionsAllowed: [".db"],
                            useBase64FormattedFile: false,
                            clearFileSelected: state.clearFileSelected,
                            disableButton: state.disableSelectFile
                        }}
                    />
                </div>
                <div className={"col-lg-3"}>
                    <Select
                        label={t("SelectBoxSectorsLabel", locale)}
                        value={state.sectorSelected}
                        options={state.sectors}
                        onChange={val => onChangeSectors(val)}
                        noError={true}
                        variant={"outlined"}
                        disabled={state.disableSave}
                    />
                    <Button
                        caption={t("Import", locale)}
                        loading={state.loadingSave}
                        loadingText={t("Saving", locale)}
                        onClick={() => dispatch({ type: 'SET_MODAL', payload: { openModal: true } })}
                        disabled={state.disableSave}
                    />
                </div>
            </div>
            <div className={"row"}>

                <div className={"col-lg-12"}>
                    <CRUD
                        order={{ dir: "desc", orderBy: "migrationDate" }}
                        newOption={false}
                        editOption={false}
                        deleteOption={false}
                        detailOption={false}
                        data={state.migrations}
                        loadingData={state.loadingData}
                        url={"/api/appmigrationdatabase"}
                        title={t("MigrationHistory", locale)}
                        columns={[
                            "migrationDate",
                            "dbId",
                            "status",
                            "sectorName",
                            "totalImportedResults"
                        ]}
                        headers={[
                            t("MigrationDate", locale),
                            t("DataBaseId", locale),
                            t("Status", locale),
                            t("Sector", locale),
                            t("TotalResultsImported", locale)
                        ]}
                        idField={"id"}
                        fieldsMap={[]}
                        formFields={[]}
                        detailFields={[]}
                        baseObject={{
                            UserLogged: getSession(),
                        }}
                    ></CRUD>
                </div>
            </div >

            {state.openModal &&
                <Modal header={header} footer={footer} closeModal={() => dispatch({ type: 'SET_MODAL', payload: { openModal: false } })} >
                    <div className={'container-fluid'}>
                        <div className={'row'}>
                            <div className={'col-12'} style={{ textAlign: 'center', fontSize: '1.08em' }}>
                                <ul>{t("QuestionConfirmMigration", locale)}</ul>
                            </div>
                            <div className={'col-12'} style={{ textAlign: 'left' }}>
                                <ul>
                                    <li>{t("WarningMigrationTextDatabaseCanOnlyBeImportedOnce", locale)}</li>
                                    <li>{t("WarningMigrationTextProcessIrreversible", locale)}</li>
                                    <li>{t("WarningMigrationTextReadingsAssignedToTheSector", locale)}</li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Modal>
            }
        </>
    );
}