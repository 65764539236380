import React, { useState, useEffect } from "react";
import request from "../../utils/request";
import {
  EditorState,
  ContentState,
  convertFromHTML,
  convertToRaw,
  Modifier,
} from "draft-js";
import useStyles from "./MailConfigurationStyles";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import draftToHtml from "draftjs-to-html";

import PageContentLoading from "../../components/PageContentLoading";
import CircularProgress from "../../components/CircularProgress";
import Select from "../../components/Select";
import Button from "../../components/Button";
import classnames from "classnames";

import { getSysUserLanguage } from '../../utils/sessionHandler'
import Select2 from "../../components/Select2";

export default function MailConfiguration() {
  const languagePermitted = getSysUserLanguage()
  const classes = useStyles();
  const [loadingContent, setLoadingContent] = useState(true);
  const [languages, setLanguages] = useState([]);
  const [mailNames, setMailNames] = useState([]);
  const [responseMessage, setResponseMessage] = useState();

  const [mailId, setMailId] = useState();
  const [subjectState, setSubjectState] = useState();
  const [subjectParameters, setSubjectParameters] = useState([]);
  const [bodyState, setBodyState] = useState();
  const [bodyParameters, setBodyParameters] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const [saving, setSaving] = useState(false);

  const [selectedLanguage, setSelectedLanguage] = useState();
  const [selectedMailName, setSelectedMailName] = useState();

  useEffect(() => {
    Promise.all([
      request().get("/api/mail/getmailnames"),
      request().get("/api/language"),
    ]).then(([mailNamesData, languagesData]) => {
      if(languagePermitted){
        let permittedLanguages = []
        languagesData.data.forEach((value) => {
          if(value.id === languagePermitted || value.id === 0 ){
            permittedLanguages.push(value);
          }
        })
        setLanguages(permittedLanguages.map((l) => [l.id, l.description]));
      } else {
        setLanguages(languagesData.data.map((l) => [l.id, l.description]));
      }
      setMailNames(mailNamesData.data.map((m) => [m.id, m.description]));

      setSelectedLanguage(languagesData.data[0].id);
      setSelectedMailName(mailNamesData.data[0].id);

      setTimeout(() => {
        setLoadingContent(false);
      }, 500);
    });
  }, []);

  useEffect(() => {
    if (selectedLanguage !== undefined && selectedMailName !== undefined) {
      request()
        .get(
          `/api/mail/GetMailConfiguration?MailNameId=${selectedMailName}&LanguageId=${selectedLanguage}`
        )
        .then((response) => {
          setLoadingData(true);
          setSubjectState(
            response.data.subject
              ? htmlToEditorObject(response.data.subject)
              : EditorState.createEmpty()
          );
          setSubjectParameters(response.data.subjectParams);
          setBodyState(
            response.data.body
              ? htmlToEditorObject(response.data.body)
              : EditorState.createEmpty()
          );
          setBodyParameters(response.data.bodyParams);
          setMailId(response.data.id);

          setTimeout(() => {
            setLoadingData(false);
          }, 500);
        });
    }
  }, [selectedLanguage, selectedMailName]);

  const htmlToEditorObject = (htmlValue) => {
    const blocksFromHTML = convertFromHTML(htmlValue);
    const contentState = ContentState.createFromBlockArray(
      blocksFromHTML.contentBlocks,
      blocksFromHTML.entityMap
    );
    return EditorState.createWithContent(contentState);
  };

  const saveMail = () => {
    let [missingParameter, resultMessage] = validateParameters(
      subjectState,
      subjectParameters
    );

    if (missingParameter) {
      setResponseMessage({
        type: "danger",
        message:
          "<p>Mail Subject has some missing parameters:</p>" + resultMessage,
      });
      window.scrollTo(0, 0);
      setTimeout(() => {
        setResponseMessage();
      }, 3000);
      return;
    }

    [missingParameter, resultMessage] = validateParameters(
      bodyState,
      bodyParameters
    );

    if (missingParameter) {
      setResponseMessage({
        type: "danger",
        message:
          "<p>Mail Body has some missing parameters:</p>" + resultMessage,
      });
      window.scrollTo(0, 0);
      setTimeout(() => {
        setResponseMessage();
      }, 3000);
      return;
    }
    console.log(convertToRaw(subjectState.getCurrentContent()));
    setSaving(true);
    request()
      .post("/api/mail/save", {
        subject: convertToRaw(
          subjectState.getCurrentContent()
        ).blocks[0].text.replace(/<.*?>/gm, ""),
        body: draftToHtml(convertToRaw(bodyState.getCurrentContent())),
        mailName: selectedMailName,
        language: selectedLanguage,
        id: mailId,
      })
      .then((response) => {
        setTimeout(() => {
          setSaving(false);
          setResponseMessage({ type: "success", message: response.data });
          window.scrollTo(0, 0);
          setTimeout(() => {
            setResponseMessage();
          }, 3000);
        }, 500);
      });
  };

  const validateParameters = (mailState, parameters) => {
    const htmlValue = draftToHtml(convertToRaw(mailState.getCurrentContent()));

    let missingParameter = false;
    let resultMessage = `<ul>`;

    for (let i = 0; i < parameters.length; i++) {
      if (!htmlValue.match(parameters[i])) {
        resultMessage += `<li>${parameters[i]}</li>`;
        missingParameter = true;
      }
    }

    resultMessage += `</ul>`;

    return [missingParameter, resultMessage];
  };

  const checkParameterInText = (param, mailState) => {
    if (mailState) {
      const htmlValue = draftToHtml(
        convertToRaw(mailState.getCurrentContent())
      );
      if (htmlValue.match(param)) {
        return true;
      } else {
        return false;
      }
    }
  };

  const sendTextToEditor = (text, mailState, setMailState) => {
    if (!checkParameterInText(text, mailState) && selectedLanguage !== 0) {
      setMailState(insertText(text, mailState));
    }
  };

  const insertText = (text, editorState) => {
    const currentContent = editorState.getCurrentContent(),
      currentSelection = editorState.getSelection();

    const newContent = Modifier.replaceText(
      currentContent,
      currentSelection,
      text
    );

    const newEditorState = EditorState.push(
      editorState,
      newContent,
      "insert-characters"
    );
    return EditorState.forceSelection(
      newEditorState,
      newContent.getSelectionAfter()
    );
  };

  if (loadingContent) return <PageContentLoading />;

  return (
    <>
      <div className={classes.selectsContainer}>
        <Select2
          label={"Language"}
          options={languages}
          variant={"outlined"}
          value={selectedLanguage}
          onChange={(val) => setSelectedLanguage(val)}
          disabled={saving}
        />
        <Select2
          label={"Mail"}
          options={mailNames}
          variant={"outlined"}
          value={selectedMailName}
          onChange={(val) => setSelectedMailName(val)}
          disabled={saving}
        />
      </div>
      {responseMessage && (
        <div
          className={`alert alert-${responseMessage.type}`}
          role="alert"
          dangerouslySetInnerHTML={{ __html: responseMessage.message }}
        />
      )}
      {loadingData ? (
        <div className={classes.loadingDataContainer}>
          <CircularProgress size={75} />
        </div>
      ) : (
        <>
          <div className={classes.editorWrapper}>
            <h5>Subject</h5>
            <p>
              {subjectParameters.map((p) => (
                <span
                  className={classnames(classes.parameterTag, {
                    [classes.selectedParameterTag]: checkParameterInText(
                      p,
                      subjectState
                    ),
                  })}
                  key={p}
                  onClick={() =>
                    sendTextToEditor(p, subjectState, setSubjectState)
                  }
                >
                  {p}
                </span>
              ))}
            </p>
            <Editor
              toolbarHidden
              editorStyle={{ padding: "0 10px" }}
              editorState={subjectState}
              onEditorStateChange={(newState) => setSubjectState(newState)}
              readOnly={saving || selectedLanguage === 0}
            />
          </div>
          <div className={classes.editorWrapper}>
            <h5>Body</h5>
            <p>
              {bodyParameters.map((p) => (
                <span
                  className={classnames(classes.parameterTag, {
                    [classes.selectedParameterTag]: checkParameterInText(
                      p,
                      bodyState
                    ),
                  })}
                  key={p}
                  onClick={() => sendTextToEditor(p, bodyState, setBodyState)}
                >
                  {p}
                </span>
              ))}
            </p>
            <Editor
              editorState={bodyState}
              onEditorStateChange={(newState) => setBodyState(newState)}
              readOnly={saving || selectedLanguage === 0}
            />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 col-md-4 offset-md-4">
                <Button
                  caption={"Save"}
                  onClick={saveMail}
                  loading={saving}
                  disabled={selectedLanguage === 0}
                  fullwidth
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
}
