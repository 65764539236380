import React, { useState, useContext } from 'react';
import Progress from "../../components/Progress";
import Button from "../../components/Button";
import useStyles from "../Login/LoginStyles";
import Paper from '../../components/Paper';
import LocalizationContext from "../../utils/LocalizationContext";
import t from "../../utils/translation";
import { getUserId, getSession, getDist } from "../../utils/sessionHandler";
import request from "../../utils/request";
import CurrentPasswordView from './CurrentPasswordView';
import NewPasswordView from './NewPasswordView';
import ConfirmPasswordView from './ConfirmPasswordView';
import Button2 from '../../components/Button2';

const changePasswordViewStyle ={
    position:'relative',
    display:'block',
    bottom:'3.5rem',
    left:'85%',
    color:'#D6D6D6',
    height:'20px',
    cursor:'pointer',
}


export default function ChangePassword() {
    const classes = useStyles();
    const locale = useContext(LocalizationContext)

    const [error, setError] = useState({ type: "", message: "" });
    const [loading, setLoading] = useState(false);

    const [passwordActual, setPasswordActual] = useState("");
    const [passwordNuevo, setPasswordNuevo] = useState("");
    const [passwordConfirma, setPasswordConfirma] = useState("");

    const validarDatos = () => {

        if (passwordActual === "") {
            console.log('error actual');
            setError({
                type: "passwordActual",
                message: "This field is required"
            });
            setLoading(false);
            return false;
        }

        if (passwordNuevo === "") {
            setError({
                type: "passwordNuevo",
                message: "This field is required"
            });
            setLoading(false);
            return false;
        }

        if (passwordConfirma === "") {
            setError({
                type: "passwordConfirma",
                message: "This field is required"
            });
            setLoading(false);
            return false;
        }

        else if (passwordNuevo !== passwordConfirma) {
            setError({
                type: "passwordConfirma",
                message: "Password does not match"
            });
            setLoading(false);
            return false;
        };


        return true;
    }

    const enviarCambioPassword = e => {
        document.activeElement.blur();
        e.preventDefault();

        if (!validarDatos()) return;

        const parametrosPost = {
            Appuser: { Email: getSession().Email, id: getUserId() },
            Password: passwordActual,
            NewPassword: passwordNuevo,
            DistributorId: getDist().id
        }

        setLoading(true);
        
        request().post('/api/appuser/changepassword', parametrosPost)
            .then(response => {
                setTimeout(() => { window.location.href = '/' }, 1000);
            })
            .catch(error => {
                if (!error.response) {
                    setError({ type: "alert", message: error.message });
                } else {
                    setError({ type: "alert", message: error.response.data.message });
                }
                setLoading(false);
            })
    }

    const cancelarCambioPassword = (e) => {
        e.preventDefault()
        window.location.href = '/'
    }

    return (
        <div className={classes.root} style={{ height: '100%', display: 'flex', backgroundColor: '#1A1A1A', justifyContent: 'center', alignItems: 'top' }}>
            <div className={classes.loginCard}>
                <Paper>
                    <form className={classes.loginForm} onSubmit={e => enviarCambioPassword(e)} >

                        <Progress class={classes.loading} active={loading} />

                        <h1 className={classes.loginText}> {t("Title", locale)} </h1>
                        {error.type === 'alert' && <div className={"alert alert-danger"}>{error.message}</div>}

                        <CurrentPasswordView 
                        locale={locale}
                        passwordActual={passwordActual}
                        setPasswordActual={setPasswordActual}
                        setError={setError}
                        error={error}
                        loading={loading}
                        t={t}
                        style={changePasswordViewStyle}
                        />
                        <NewPasswordView
                        locale={locale}
                        passwordNuevo={passwordNuevo}
                        setPasswordNuevo={setPasswordNuevo}
                        setError={setError}
                        error={error}
                        loading={loading}
                        t={t}
                        style={changePasswordViewStyle} />
                        <ConfirmPasswordView 
                        locale={locale}
                        passwordConfirma={passwordConfirma}
                        setPasswordConfirma={setPasswordConfirma}
                        setError={setError}
                        error={error}
                        loading={loading}
                        t={t}
                        style={changePasswordViewStyle}
                        />

                        <Button2
                            caption={t("AcceptButton")}
                            onClick={(e) => { enviarCambioPassword(e) }}
                            loading={loading}
                        />
                        <span style={{ marginTop: 10 }} />
                        <Button2 caption={t("CancelButton")}
                            onClick={(e) => { cancelarCambioPassword(e) }}
                            disabled={loading}
                        />
                    </form>
                </Paper>
            </div>
        </div>

    )


}