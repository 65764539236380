import React, { useState } from 'react'
import ShowPassword from '../../components/Form/UserResetPassword/ShowPassword.js';
import LoginInput from '../../components/LoginInput';

const CurrentPasswordView = (props) => {
    const {locale,passwordActual,setPasswordActual,setError,error,loading,t,style} = props
    const [viewPasswordActual, setViewPasswordActual] = useState(false);
const togglePasswordActualVisibility = () => {
    setViewPasswordActual(!viewPasswordActual);
};

   return (
    <div>
        <LoginInput label={t("CurrentPasswordLabel", locale)} // 'Current Password'
            claro={true}
            value={passwordActual}
            onChange={value => {
                setPasswordActual(value);
                setError({ type: "", message: "" });
                }}

                error={error.type === "passwordActual" ? error.message : ""}
                type={viewPasswordActual ? 'text' : 'password'}
                disabled={loading}
                style={{color:"white",backgroundColor:"gray"}}
                />
              <ShowPassword style={style} parentCallback={()=>{togglePasswordActualVisibility()}} />

    </div>
  )
}

export default CurrentPasswordView