import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles((theme) => createStyles({
    groupedTitle: {
        fontSize: 18,
        fontWeight: '500',
        // borderBottom: '0.5px solid ' + theme.palette.terragene.lightMain,
        fontFamily:"Montserrat",
        textTransform: 'uppercase',
        backgroundColor: theme.palette.terragene.lightMain,
        borderRadius:10,
        padding:5,
        textAlign: 'center',
    },
    groupedDiv: {
        margin: '0px 8px',
        marginBottom: 12,
    },
    imgSize: {
        width: '55%',
        height: 'auto'
    },
}))
