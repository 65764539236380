import React, { useState, useEffect, useContext, useReducer } from "react";
import request from "../../utils/request";
import { getUserId, getSession, getUserType, getUserCompany, getToken } from "../../utils/sessionHandler";
import t from "../../utils/translation";
import LocalizationContext from "../../utils/LocalizationContext";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import PageContentLoading from "../../components/PageContentLoading";
import Select from "../../components/Select";
import FormattedField from '../../components/FormattedField'
import useStyles from "./AppParameterStyles";
import Uploader from "../../components/Uploader/Uploader";
import { UserType } from "../../dictionaries/userTypes";
import FixedAlert from "../../components/FixedAlert/FixedAlert";
import reducer from './AppParameterReducer';
import Header from "../../components/Modal/Header/Header";
import hubConnection from "../../utils/hubs/hubConnection";
import { WorkingMode } from "../../dictionaries/workingMode";
import { Parameters, ParametersType } from "../../dictionaries/parameters";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { sortByField } from "../../utils/functions/Sorter"
import { parseJwtData} from "../../utils/functions/ParserJwt"
import {
  setGeneralObjects,
  setUserLanguage,
  setRegionalFormat,
} from "../../utils/sessionHandler";
import Card from '@material-ui/core/Card';
import ThresholdsISO from "../../components/ThresholdsISO/ThresholdsISO";
import Button2 from "../../components/Button2";

const modos = [
  {
    id: WorkingMode.HTM01,
    proteinAlertVisible: false,
    proteinActionVisible: false,
    proteinUmbralVisible: true,
    posProteinUmbral: 5,
    posProteinAlert: 6,
    posProteinAction: 7
  },
  {
    id: WorkingMode.ISO,
    proteinAlertVisible: true,
    proteinActionVisible: true,
    proteinUmbralVisible: false,
    posProteinAlert: 6,
    posProteinAction: 7,
    posProteinUmbral: 10
  }
]

const initialState = {
  file: null,
  disableSave: true, //bloquea botón guardar logo compañía.
  disableDelete: false, //bloquea botón eliminar logo.
  popUpDeleteFileVisible: false
}
export default function AppParameter() {
  const classes = useStyles();
  const loggedUserId = getUserId();

  const locale = useContext(LocalizationContext);
  const userType = getUserType();

  const [loadingContente, setLoadingContent] = useState(true);
  const [languages, setLanguages] = useState([]);
  const [securityModes, setSecurityModes] = useState([]);
  const [parameters, setParameters] = useState([]);
  const [resettingAllValues, setResettingAllValues] = useState(false);
  const [success, setsuccess] = useState("");
  const [successWorkingMode, setSuccessWorkingMode] = useState("");
  const [error, setError] = useState("");
  const [verifyTicketWarning, setVerifyTicketWarning] = useState(false);
  const [modal, setModal] = useState({ open: false });
  const [savingParametersId, setSavingParametersId] = useState([]);
  const [resettingParametersId, setResettingParametersId] = useState([]);
  const [workingModes, setWorkingModes] = useState([]);
  const [stateFile, dispatch] = useReducer(reducer, initialState);
  const [workingModeState, setWorkingModeWarning] = useState({ workingDialogVisible: false });
  const [regionalFormats, setRegionalFormats] = useState([]);
  const [temperatureUnits, setTemperatureUnits] = useState([]);

  const [connection, setConnection] = useState(null);

  useEffect(() => {
    loadLogo()
  }, [])

  useEffect(() => {
    const newConnection = hubConnection('/hubs/parameter', getToken());
    setConnection(newConnection);
  }, [])

  useEffect(() => {
    if (connection) {
      connection.start()
        .then(result => {
          console.log('Connected!');
          // connection.on('ProteinThresholdChanged', message => {
          // });
        })
        .catch(e => console.log('Connection failed: ', e));
    }
  }, [connection]);

  useEffect(() => {
    Promise.all([
      request().get(`/api/language/GetEnabledLanguages?userLoggedId=${loggedUserId}`),
      request().get(`/api/securitymode/GetByUser?userLoggedId=${loggedUserId}`),
      request().get(`/api/workingmode`),
      request().get(`/api/appparameter?userLoggedId=${loggedUserId}`),
      request().get(`/api/regionalformat/GetRegionalFormatsForLanguage?userLoggedId=${loggedUserId}`),
      request().get(`/api/temperatureunit/GetByUser?userLoggedId=${loggedUserId}`),
    ])
      .then(([languagesData, securityModeData, workingModeData, parametersData, regionalFormatsData, temperatureUnitData]) => {
        setLanguages(
          languagesData.data.reduce((languagesList, lang) => {
            languagesList.push([lang.id, lang.description, lang.enabled]);
            return languagesList;
          }, [])
        );
        setSecurityModes(
          securityModeData.data.reduce((smList, sm) => {
            smList.push([sm.id, sm.description]);
            return smList;
          }, [])
        );
        setWorkingModes(
          workingModeData.data.reduce((wmList, wm) => {
            wmList.push([wm.id, wm.description]);
            return wmList;
          }, [])
        ); 
        setParameters(updateWorkingMode(parametersData.data));
        setRegionalFormats(
          regionalFormatsData.data.reduce((regionalFormatsList, regFmt) => {
            regionalFormatsList.push([regFmt.languageCode, regFmt.description]);
            return regionalFormatsList;
          }, [["", t("FromLanguageAndCountry", locale)]])
        );
        setTemperatureUnits(
          temperatureUnitData.data.reduce((tuList, tu) => {
            tuList.push([tu.id, tu.description]);
            return tuList;
          }, [])
        );
        setLoadingContent(false);
      })
      .catch(error => console.log(error.response))
  }, [loggedUserId]);

  const updateWorkingMode = (parameters) => {
    const value = parameters.find(x => x.appParameterType === Parameters.WorkingMode).value;
    const modoSeleccionado = modos.find(x => x.id == value);
    parameters.forEach((parameter) => {
      if (parameter.appParameterType === Parameters.ProteinUmbral) {
        parameter.visible = modoSeleccionado.proteinUmbralVisible
        parameter.order = modoSeleccionado.posProteinUmbral
      } else if (parameter.appParameterType === Parameters.ProteinAlertUmbral) {
        parameter.visible = modoSeleccionado.proteinAlertVisible
        parameter.order = modoSeleccionado.posProteinAlert
      } else if (parameter.appParameterType === Parameters.ProteinActionUmbral) {
        parameter.visible = modoSeleccionado.proteinActionVisible
        parameter.order = modoSeleccionado.posProteinAction
      }
    })
    sortByField(parameters, "order")
    return parameters;
  }

  const updateData = () => {
    request()
      .get(`/api/appparameter?userLoggedId=${loggedUserId}`)
      .then(response => setParameters(response.data));
  };

  const resetDefaultValues = () => {
    setModal({ open: false });
    setResettingAllValues(true);

    request()
      .post("/api/appparameter/defaultvalues", getSession())
      .then(response => {
        setResettingAllValues(false);
        setLoadingContent(true);

        Promise.all([updateData()]).then(() => {
          setTimeout(() => {
            setLoadingContent(false);
            setsuccess(response.data);
            setTimeout(() => {
              setsuccess("");
            }, 3000);
            applyTranslation()
          }, 500);
        });
      })
      .catch(error => console.log(error.response));
  };

  const updateParameterValue = (val, id) => {
    val = parseInt(val)
    let newParameters = parameters;
    for (let i = 0; i < newParameters.length; i++) {
      if (newParameters[i].id === id) newParameters[i].value = val;
    }
    setParameters([...newParameters]);
  };

  const updateParameter = (val, id) => {
    let param = parameters.find(x => x.id === id)
    param.value = val;
    setParameters([...parameters])
  }

  const applyTranslation = () => {
    let jwt = localStorage.getItem("__token")
    localStorage.removeItem("menuOptions");
    localStorage.removeItem("__generalObjects");
    localStorage.removeItem("__userLanguage");

    const screensId = localStorage.getObject("screensId");
    screensId &&
      Object.keys(screensId).map((id) => localStorage.removeItem(id));
    localStorage.removeItem("screensId");

    const payloadFromToken = parseJwtData(jwt);
    const userId = payloadFromToken.Id;

    Promise.all([
      request().get(
        `/api/GeneralObject/getgeneralobjectjson?UserLoggedId=${userId}`
      ),
      request().get(
        `/api/language/GetUserLanguage?userLoggedId=${userId}`
      ),
      request().get(
        `/api/regionalformat/GetByUser?userLoggedId=${userId}`
      ),
    ])
      .then(([generalObjectsData, languageData, regionalFormatData]) => {
        setUserLanguage(languageData.data);
        setGeneralObjects(generalObjectsData.data);
        setRegionalFormat(regionalFormatData.data);
        sendHubDateTimeFormat(regionalFormatData.data);
        setTimeout(() => {
          window.location.reload();
        }, 1000)
      })
      .catch(error => console.log(error?.response?.data?.message))
  }

  const applyRegionalFormat = () => {
    let jwt = localStorage.getItem("__token")
    localStorage.removeItem("__regionalFormat");

    const payloadFromToken = parseJwtData(jwt);
    const userId = payloadFromToken.Id;
      
    request().get(
      `/api/regionalformat/GetByUser?userLoggedId=${userId}`
    )
    .then((regionalFormatData) => {
      setRegionalFormat(regionalFormatData.data);
      sendHubDateTimeFormat(regionalFormatData.data);
    })
    .catch(error => console.log(error?.response?.data?.message))
  }

  const sendChanges = (param) => {
    try {
      if (param.appParameterType === Parameters.WorkingMode) {
        connection.send('ChangeWorkingMode', param.value);
      } else if (param.appParameterType === Parameters.ProteinUmbral) {
        connection.send('ChangeProteinThreshold', param.value);
      } else if (param.appParameterType === Parameters.TemperatureUnit){
        connection.send('ChangeTemperatureUnit', param.value);
      }

    }
    catch (e) {
      console.log(e);
    }
  }

  const sendHubDateTimeFormat = (data) => {
    try {
      if (connection.connectionStarted) {
        connection.send('ChangeDateTimeFormat', data.dateFormat, data.timeFormat);
      }
    }
    catch (e) {
      console.log(e);
    }
  }

  const handleSaveParameter = id => {
    let AppParameter = parameters.find(p => p.id === id)
    setSavingParametersId(savingParametersId.concat(id));
    request()
      .put("/api/appparameter", { UserLogged: getSession(), AppParameter })
      .then(async response => {
        if (connection.connectionStarted) {
          sendChanges(AppParameter)
        }
        setTimeout(() => {
          if (AppParameter.appParameterType === Parameters.WorkingMode) {
            setParameters(updateWorkingMode(parameters))
          }
          setSavingParametersId(savingParametersId.filter(p => p.id !== id));
          if (AppParameter.appParameterType === Parameters.WorkingMode
            || AppParameter.appParameterType === Parameters.ProteinUmbral) {
            setSuccessWorkingMode(response.data);
            setTimeout(() => {
              setSuccessWorkingMode("");
            }, 3000);
          } else {
            setsuccess(response.data);
            setTimeout(() => {
              setsuccess("");
            }, 3000);
          }
          if (AppParameter.appParameterType === Parameters.CompanyLanguage) {
            //al modificar el idioma aplica las traducciones correspondientes.
            applyTranslation()
          }
          if (AppParameter.appParameterType === Parameters.RegionalFormat) {
            //al modificar la región, actualiza el local storage
            applyRegionalFormat();
          }
        }, 500);
      })
      .catch(error => {
        console.log(error?.response?.data?.message)
        if (AppParameter.appParameterType === Parameters.WorkingMode || AppParameter.appParameterType === Parameters.ProteinUmbral ||
          AppParameter.appParameterType === Parameters.ProteinAlertUmbral || AppParameter.appParameterType === Parameters.ProteinActionUmbral) {
          setError(error?.response?.data?.message);
          setSavingParametersId([]);
          setTimeout(() => {
            setError("");
          }, 7500);
        }
      })
  };

  const handleResetParameter = id => {
    setModal({ open: false });  
    setResettingParametersId(resettingParametersId.concat(id));
    let AppParameter = parameters.find(p => p.id === id)
    request()
      .post("/api/appparameter/defaultvalue", { UserLogged: getSession(), AppParameter })
      .then(response => {
        Promise.all([
          request().get(`/api/appparameter?userLoggedId=${loggedUserId}`)
        ]).then(([newParameters]) => {
          let paramsUpdated = parameters.reduce((parametersList, parameter) => {
            parameter.id === id
              ? parametersList.push(
                newParameters.data.find(np => np.id === id)
              )
              : parametersList.push(parameter);
            return parametersList;
          }, [])
          setParameters(updateWorkingMode(paramsUpdated))
          setTimeout(() => {
            setResettingParametersId(
              resettingParametersId.filter(p => p.id !== id)
            );
            setsuccess(response.data);
            setTimeout(() => {
              setsuccess("");
            }, 3000);
            if (AppParameter.appParameterType === Parameters.CompanyLanguage) {
              applyTranslation()
            }
            if (AppParameter.appParameterType === Parameters.RegionalFormat) {
              applyRegionalFormat();
            }
            if (AppParameter.appParameterType === Parameters.TemperatureUnit) {
              if (connection.connectionStarted) {
                let updatedParam = paramsUpdated.find(p => p.id === id)
                connection.send('ChangeTemperatureUnit', parseInt(updatedParam.value));
              }
            }
          }, 500);
        });
      })
      .catch(error => console.log(error.response));
  };

  const footer = (
    <div className={classes.footerAction}>
      <Button2 caption={t("ConfirmButton")} dense onClick={modal.action} />
    </div>
  );

  const resolveTranslation = (parameter) => {
    let translation;
    switch (parameter.appParameterType) {
      case Parameters.CompanySecurityMode:
        translation = t("CompanySecurityModeLabel", locale)
        break;
      case Parameters.CompanyLanguage:
        translation = t("CompanyLanguageLabel", locale)
        break;
      case Parameters.DaysLicenseExpiration:
        translation = t("DaysForLicenseExpirationNotificationLabel", locale)
        break;
      case Parameters.ProteinUmbral:
        translation = t("ProteinUmbralLabel", locale) + " [μg]"
        break;
      case Parameters.WorkingMode:
        translation = t("ModeLabel", locale)
        break;
      case Parameters.ProteinAlertUmbral:
        translation = t("ProteinAlertUmbralLabel", locale) + " [μg/cm²]"
        break;
      case Parameters.ProteinActionUmbral:
        translation = t("ProteinActionUmbralLabel", locale) + " [μg/cm²]"
        break;
      case Parameters.RegionalFormat:
        translation = t("RegionalFormat", locale)
        break;
      case Parameters.TemperatureUnit:
        translation = t("TemperatureModeLabel", locale)
    }
    return translation
  }

  const loadLogo = async () => {
    dispatch({ type: "LOADING" })
    const fileCompany = await request().get(`/api/companyfile/${getUserCompany().Id}`);
    if (fileCompany.data) {
      dispatch({ type: "SET_DBFILE", payload: { dbFile: fileCompany.data.logo } })
      dispatch({ type: "SET_FILE", payload: { id: fileCompany.data.id, file: fileCompany.data.logo } })
    } else {
      dispatch({ type: "DISABLE_DELETEBUTTON" })
      dispatch({ type: "CLEAR" })
    }
    dispatch({ type: "LOADED" })
  }

  const saveLogo = async () => {
    dispatch({ type: 'LOADING' })
    const formData = {
      CompanyFile: {
        Id: stateFile.id,
        CompanyId: getUserCompany().Id,
        Logo: stateFile.file
      }
    }
    try {
      const result = await request().put(`/api/companyfile`, formData);
      dispatch({ type: 'LOADED' })
      dispatch({ type: 'SET_DBFILE', payload: { dbFile: stateFile.file } })
      dispatch({ type: 'SET_ALERT', payload: { type: 'success', alertText: result.data } })
      dispatch({ type: "DISABLE_SAVEBUTTON" })
      dispatch({ type: "ENABLE_DELETEBUTTON" })
    } catch (error) {
      dispatch({ type: 'SET_ALERT', payload: { type: 'error', alertText: error?.response?.data?.message } })
      dispatch({ type: "DISABLE_SAVEBUTTON" })
      dispatch({ type: "DISABLE_DELETEBUTTON" })
      loadLogo();
    }
  }

  const handleAlertISOThresholds = (response) => {
    if (response.type === "error") {
      setError(response.msg)
      setTimeout(() => {
        setError("");
      }, 4500);
    } else if (response.type === "success") {
      setSuccessWorkingMode(response.msg);
      setTimeout(() => {
        setSuccessWorkingMode("");
      }, 4500);
    }
  }

  if (loadingContente) return <PageContentLoading />;
  return (
    <>
      <div className={"container-fluid px-0"}>
        {success && (
          <div className={"row mx-0 justify-content-md-center"}>
            <div className={"col-12 col-md-8"}>
              <div className={"alert alert-success"}>{success}</div>
            </div>
          </div>
        )}

        <div>
          <div className={"row mx-0 justify-content-md-center"}>
            <div className={"col-12 col-md-10"}>
              <Card variant="outlined" className={classes.ParametersAllowedResetDefault}>
                <div className={classes.titleSectionParameters}>
                  {t("ParametersTitle", locale)}
                </div>
                <Button2
                  onClick={() =>
                    setModal({
                      open: true,
                      header: t("ResetAllValuesButton", locale),
                      body: t("ResetAllValuesConfirmation", locale),
                      action: resetDefaultValues
                    })
                  }
                  caption={t("ResetAllValuesButton", locale)}
                  loading={resettingAllValues}
                  loadingText={t("ResettingAllValuesText", locale)}
                />
                {parameters.map(p => {
                  if (p.appParameterType !== Parameters.WorkingMode && p.appParameterType !== Parameters.ProteinUmbral &&
                    p.appParameterType !== Parameters.ProteinAlertUmbral && p.appParameterType !== Parameters.ProteinActionUmbral) {
                    if (p.fieldType === ParametersType.Float) {
                      return (
                        <div className={classes.parameterRoot} key={p.id} style={{ display: p.visible ? "block" : "none" }}>
                          <FormattedField
                            label={resolveTranslation(p)}
                            object={p}
                            updateValue={updateParameter}
                            min={1}
                            max={50}
                            maxLength={4}
                            step={0.1}
                            isUmbral={true}
                          >
                          </FormattedField>
                          <div className={classes.parameterActionsRoot}>
                            <Button2
                              onClick={() => handleSaveParameter(p.id)}
                              caption={t("SaveButton", locale)}
                              loading={savingParametersId.includes(p.id)}
                              loadingText={t("SavingText", locale)}
                              disabled={
                                p.disabled ||
                                resettingAllValues ||
                                resettingParametersId.includes(p.id)
                              }
                            />
                            <Button2
                              hidden={p.appParameterType === Parameters.ProteinUmbral ||
                                p.appParameterType === Parameters.ProteinAlertUmbral ||
                                p.appParameterType === Parameters.ProteinActionUmbral ? true : false}
                              caption={t("ResetValueButton", locale)}
                              onClick={() =>
                                setModal({
                                  open: true,
                                  header: p.appParameterTypeDescription,
                                  body: t("ResetDefaultValueConfirmation", locale),
                                  action: () => handleResetParameter(p.id)
                                })
                              }
                              loading={resettingParametersId.includes(p.id)}
                              loadingText={t("ResettingValueText", locale)}
                              disabled={
                                resettingAllValues ||
                                savingParametersId.includes(p.id)
                              }
                            />
                          </div>
                        </div>
                      )
                    }
                    if (p.fieldType === ParametersType.Enum) {
                      return (
                        <div className={classes.parameterRoot} key={p.id}>
                          <Select
                            label={resolveTranslation(p)}
                            value={p.value}
                            onChange={val => updateParameterValue(val, p.id)}
                            options={
                              p.appParameterType === Parameters.CompanySecurityMode
                                ? securityModes
                              : p.appParameterType === Parameters.TemperatureUnit
                                ? temperatureUnits
                              : languages
                            }
                            noError={true}
                            variant={"outlined"}
                            disabled={
                              resettingAllValues ||
                              savingParametersId.includes(p.id) ||
                              resettingParametersId.includes(p.id)
                            }
                          />
                          <div className={classes.parameterActionsRoot}>
                            <Button2
                              onClick={() => handleSaveParameter(p.id)}
                              caption={t("SaveButton", locale)}
                              loading={savingParametersId.includes(p.id)}
                              loadingText={t("SavingText", locale)}
                              disabled={
                                resettingAllValues ||
                                resettingParametersId.includes(p.id)
                              }
                            />
                            <Button2
                              hidden={p.appParameterType === Parameters.WorkingMode ? true : false}
                              caption={t("ResetValueButton", locale)}
                              onClick={() =>
                                setModal({
                                  open: true,
                                  header: p.appParameterTypeDescription,
                                  body: t("ResetDefaultValueConfirmation", locale),
                                  action: () => handleResetParameter(p.id)
                                })
                              }
                              loading={resettingParametersId.includes(p.id)}
                              loadingText={t("ResettingValueText", locale)}
                              disabled={
                                resettingAllValues ||
                                savingParametersId.includes(p.id)
                              }
                            />
                          </div>
                        </div>
                      );
                    }
                    if (p.fieldType === ParametersType.String && p.appParameterType === Parameters.RegionalFormat) {
                      return (
                        <div className={classes.parameterRoot} key={p.id}>
                          <Select
                            label={resolveTranslation(p)}
                            value={p.value}
                            onChange={val => updateParameter(val, p.id)}
                            options={regionalFormats}
                            noError={true}
                            variant={"outlined"}
                            disabled={
                              resettingAllValues ||
                              savingParametersId.includes(p.id) ||
                              resettingParametersId.includes(p.id)
                            }
                          />
                          <div className={classes.parameterActionsRoot}>
                            <Button2
                              onClick={() => handleSaveParameter(p.id)}
                              caption={t("SaveButton", locale)}
                              loading={savingParametersId.includes(p.id)}
                              loadingText={t("SavingText", locale)}
                              disabled={
                                resettingAllValues ||
                                resettingParametersId.includes(p.id)
                              }
                            />
                            <Button2
                              hidden={p.appParameterType === Parameters.WorkingMode ? true : false}
                              caption={t("ResetValueButton", locale)}
                              onClick={() =>
                                setModal({
                                  open: true,
                                  header: p.appParameterTypeDescription,
                                  body: t("ResetDefaultValueConfirmation", locale),
                                  action: () => handleResetParameter(p.id)
                                })
                              }
                              loading={resettingParametersId.includes(p.id)}
                              loadingText={t("ResettingValueText", locale)}
                              disabled={
                                resettingAllValues ||
                                savingParametersId.includes(p.id)
                              }
                            />
                          </div>
                        </div>
                      );
                    }
                    return (
                      <div className={classes.parameterRoot} key={p.id}>
                        <FormattedField
                          label={resolveTranslation(p)}
                          object={p}
                          updateValue={updateParameterValue}
                          min={15}
                          max={365}
                          maxLength={3}
                          step={1}
                          isUmbral={false}
                        ></FormattedField>
                        <div className={classes.parameterActionsRoot}>
                          <Button2
                            onClick={() => handleSaveParameter(p.id)}
                            caption={t("SaveButton", locale)}
                            loading={savingParametersId.includes(p.id)}
                            loadingText={t("SavingText", locale)}
                            disabled={
                              p.disabled ||
                              resettingAllValues ||
                              resettingParametersId.includes(p.id)
                            }
                          />
                          <Button2
                            caption={t("ResetValueButton", locale)}
                            onClick={() =>
                              setModal({
                                open: true,
                                header: p.appParameterTypeDescription,
                                body: t("ResetDefaultValueConfirmation", locale),
                                action: () => handleResetParameter(p.id)
                              })
                            }
                            loading={resettingParametersId.includes(p.id)}
                            loadingText={t("ResettingValueText", locale)}
                            disabled={
                              resettingAllValues || savingParametersId.includes(p.id)
                            }
                          />
                        </div>
                      </div>
                    );
                  }
                })}
                {
                  userType == UserType.Administrator &&
                  <div>
                    <FixedAlert text={stateFile.alertText} type={stateFile.type} closeErrorCb={() => { dispatch({ type: "CLOSE_ALERT" }) }}></FixedAlert>
                    <Uploader
                      file={stateFile}
                      dispatch={dispatch}
                      action={'SET_FILE'}
                      translation={{
                        title: t("CompanyLogoLabel", locale),
                        uploadButtonText: t("UploadCompanyLogoLabel", locale),
                        saveButtonText: t("SaveButton", locale),
                        info: [
                          t("SizeCompanyLogoLabel", locale),
                          t("AspectCompanyLogoLabel", locale)
                        ]
                      }}
                      options={{
                        widthFile: 340,
                        heightFile: 65,
                        showPreViewImage: true,
                        showFileName: true,
                        useBase64FormattedFile: true,
                        extensionsAllowed: [".png", ".jpg", ".JPG", ".PNG"],
                        clearFileSelected: stateFile.clearFileSelected
                      }}
                    />
                    <div className={classes.parameterActionsRoot}>
                      <Button2
                        onClick={() => saveLogo()}
                        caption={t("SaveButton", locale)}
                        loading={stateFile.loading}
                        loadingText={t("SavingText", locale)}
                        disabled={stateFile.loading || stateFile.disableSave}
                      />
                      <Button2
                        caption={t("DeleteText", locale)}
                        loadingText={t("DeleteText", locale)}
                        disabled={stateFile.loading || stateFile.disableDelete}
                        onClick={() => {
                          dispatch({ type: 'SHOW_DELETE_MODAL' })
                        }}
                      />
                    </div>
                  </div>
                }
              </Card>
            </div>
          </div>
        </div>
      </div>


      <div>
        {error && (
          <div className={"row mx-0 justify-content-md-center"}>
            <div className={"col-12 col-md-8"}>
              <div className={"alert alert-danger"}>{error}</div>
            </div>
          </div>
        )}
        {verifyTicketWarning && (
          <div className={"row mx-0 justify-content-md-center"}>
            <div className={"col-12 col-md-8"}>
              <div className={"alert alert-warning"}>
                {t("VerifyWorkingModeTicket", locale)}
                <div>
                  <Button
                    onClick={() => { setVerifyTicketWarning(false) }}
                    caption={t("Close")}
                    isVerticalMargined={true}
                    color={"secondaryColor"}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        {successWorkingMode && (
          <div className={"row mx-0 justify-content-md-center"}>
            <div className={"col-12 col-md-8"}>
              <div className={"alert alert-success"}>{successWorkingMode}</div>
            </div>
          </div>
        )}

        <div className={"row mx-0 justify-content-md-center"}>
          <div className={"col-12 col-md-10"}>
            <Card variant="outlined" className={classes.ParametersNotAllowedResetDefault}>
              <div className={classes.titleSectionWorkingMode}>
                {t("WorkingModeTitle", locale)}
              </div>
              {parameters.map(p => {
                if (p.appParameterType === Parameters.WorkingMode || p.appParameterType === Parameters.ProteinUmbral ||
                  p.appParameterType === Parameters.ProteinAlertUmbral || p.appParameterType === Parameters.ProteinActionUmbral) {
                  if (p.fieldType === ParametersType.Float && p.appParameterType === Parameters.ProteinAlertUmbral) {
                    let actionThreshold = parameters.find(x => x.appParameterType === Parameters.ProteinActionUmbral);
                    let objectsArray = [p, actionThreshold];
                    let translationObjects = [resolveTranslation(p), resolveTranslation(actionThreshold)]
                    return (
                      <div className={classes.parameterRoot} key={p.id} style={{ display: p.visible ? "block" : "none" }}>
                        <ThresholdsISO
                          label={translationObjects}
                          object={objectsArray}
                          updateValue={updateParameter}
                          min={1}
                          max={50}
                          maxLength={4}
                          step={0.1}
                          isUmbral={true}
                          handleAlertISOThresholds={handleAlertISOThresholds}
                        >
                        </ThresholdsISO>
                      </div>
                    )
                  } else if (p.fieldType === ParametersType.Float && p.appParameterType !== Parameters.ProteinActionUmbral) {
                    return (
                      <div className={classes.parameterRoot} key={p.id} style={{ display: p.visible ? "block" : "none" }}>
                        <FormattedField
                          label={resolveTranslation(p)}
                          object={p}
                          updateValue={updateParameter}
                          min={1}
                          max={50}
                          maxLength={4}
                          step={0.1}
                          isUmbral={true}
                        >
                        </FormattedField>
                        <div className={classes.parameterActionsRoot}>
                          <Button2
                            onClick={() => handleSaveParameter(p.id)}
                            caption={t("SaveButton", locale)}
                            loading={savingParametersId.includes(p.id)}
                            loadingText={t("SavingText", locale)}
                            disabled={
                              p.disabled ||
                              resettingAllValues ||
                              resettingParametersId.includes(p.id)
                            }
                          />
                          <Button2
                            hidden={p.appParameterType === Parameters.ProteinUmbral ||
                              p.appParameterType === Parameters.ProteinAlertUmbral ||
                              p.appParameterType === Parameters.ProteinActionUmbral ? true : false}
                            caption={t("ResetValueButton", locale)}
                            onClick={() =>
                              setModal({
                                open: true,
                                header: p.appParameterTypeDescription,
                                body: t("ResetDefaultValueConfirmation", locale),
                                action: () => handleResetParameter(p.id)
                              })
                            }
                            loading={resettingParametersId.includes(p.id)}
                            loadingText={t("ResettingValueText", locale)}
                            disabled={
                              resettingAllValues ||
                              savingParametersId.includes(p.id)
                            }
                          />
                        </div>
                      </div>
                    )
                  }
                  if (p.fieldType === ParametersType.Enum) {
                    return (
                      <div className={classes.parameterRoot} key={p.id}>
                        <Select
                          label={resolveTranslation(p)}
                          value={p.value}
                          onChange={val => updateParameterValue(val, p.id)}
                          options={ workingModes }
                          noError={true}
                          variant={"outlined"}
                          disabled={
                            resettingAllValues ||
                            savingParametersId.includes(p.id) ||
                            resettingParametersId.includes(p.id)
                          }
                        />
                        <div className={classes.parameterActionsRoot}>
                          <Button2
                            onClick={() => {
                              if (p.appParameterType == Parameters.WorkingMode)
                                setWorkingModeWarning({ workingDialogVisible: true, ParameterId: p.id })
                              else
                                handleSaveParameter(p.id)
                            }}
                            caption={t("SaveButton", locale)}
                            loading={savingParametersId.includes(p.id)}
                            loadingText={t("SavingText", locale)}
                            disabled={
                              resettingAllValues ||
                              resettingParametersId.includes(p.id)
                            }
                          />
                          <Button2
                            hidden={p.appParameterType === Parameters.WorkingMode ? true : false}
                            caption={t("ResetValueButton", locale)}
                            onClick={() =>
                              setModal({
                                open: true,
                                header: p.appParameterTypeDescription,
                                body: t("ResetDefaultValueConfirmation", locale),
                                action: () => handleResetParameter(p.id)
                              })
                            }
                            loading={resettingParametersId.includes(p.id)}
                            loadingText={t("ResettingValueText", locale)}
                            disabled={
                              resettingAllValues ||
                              savingParametersId.includes(p.id)
                            }
                          />
                        </div>
                      </div>
                    );
                  }
                }
              })}
            </Card>
          </div>
        </div>
      </div>


      {modal.open && (
        <Modal
          header={<Header headerText={modal.header} close={() => setModal({ open: false })}></Header>}
          footer={footer}
          closeModal={() => setModal({ open: false })}
        >
          <div className={"container-fluid"}>
            <div className={"row"}>
              <div className={"col-12"} style={{ textAlign: "center" }}>
                {modal.body}
              </div>
            </div>
          </div>
        </Modal>
      )}
      {stateFile.popUpDeleteFileVisible && (
        <Modal
          header={<Header headerText={t("DeleteText") + " " + t("CompanyLogoLabel", locale)} close={() => dispatch({ type: 'CLOSE_MODAL' })}></Header>}
          footer={
            <div className={classes.footerAction}>
              <Button2 caption={t("CancelButton")} dense onClick={() => dispatch({ type: 'CLOSE_MODAL' })} />
              <Button2 caption={t("ConfirmButton")} dense onClick={
                async () => {
                  try {
                    const response = await request().delete(`/api/CompanyFile`, getUserCompany().id);
                    dispatch({ type: 'SET_ALERT', payload: { type: 'success', alertText: response.data, clearFileSelected: true } })
                    dispatch({ type: 'SET_DBFILE', payload: { dbFile: undefined } })
                    dispatch({ type: "DISABLE_DELETEBUTTON" })
                  } catch (error) {
                    dispatch({ type: 'SET_ALERT', payload: { type: 'error', alertText: error?.response?.data?.message, clearFileSelected: false } })
                  }
                }} />
            </div>
          }
          closeModal={() => setModal({ open: false })}
        >
          <div className={"container-fluid"}>
            <div className={"row"}>
              <div className={"col-12"} style={{ textAlign: "center" }}>
                {t("DeleteConfirmationText")}{" " + t("CompanyLogoLabel", locale) + "?"}
              </div>
            </div>
          </div>
        </Modal>
      )}
      <Dialog
        open={workingModeState.workingDialogVisible}
        onClose={() => { setWorkingModeWarning({ workingDialogVisible: false }) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{t("FilterEmptyTitle", locale)}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("WorkingModeChanging", locale)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button2 caption={t("CancelButton")} onClick={() => setWorkingModeWarning({ workingDialogVisible: false })} />
          <Button2 caption={t("AcceptButton")} onClick={() => {
            handleSaveParameter(workingModeState.ParameterId)
            setWorkingModeWarning({ workingDialogVisible: false })
            setVerifyTicketWarning(true)
          }} />
        </DialogActions>
      </Dialog>
    </>
  );
}
