import { makeStyles } from "@material-ui/styles";

export default makeStyles({
  buttonContianer: {
    margin: "25px 25px"
  },

  importExportContainer: {
    '& button': { margin: '10px 30px' }
  },

  progressContainer: {
    position: 'absolute',
    top:0,
    left:0,
    width:'100%',
    height:'100%',
    backgroundColor:'rgba(0,0,0,0.7)',
    zIndex:1000,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },

  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '16px 24px',
  },
  
  headerText: {
      fontSize: 16,
      fontWeight: 500,
      lineHeight: '1.5rem',
      color: '#5a5a5d'
  },

  footerAction: {
    position: 'absolute',
    right: 20,
    '& *': {
        margin: '0 8px'
    }
  }
});

