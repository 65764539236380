import React, { useContext } from 'react';
import LocalizationContext from "../../../utils/LocalizationContext";
import Input from '../../Input';
import Autocomplete from '../../Autocomplete';
import request from '../../../utils/request';
import { allowOnlyCharactersAndNumbers, phoneCharAllowed } from '../../../utils/functions/Regex';
import LoginInput from '../../LoginInput';

const Company = (props) => {
    const { company, setCompany, error, setError, countries, loadingCountries, setEulaAccepted, languages, language, setLanguage } = props;
    const locale = useContext(LocalizationContext);

    return (
        <div>
            {error.type === "alert" && (
                <div className={"alert alert-danger"}>{error.message}</div>
            )}
            {error.type === "warning" && (
                <div className={"alert alert-warning"}>{error.message}</div>
            )}
            <LoginInput
                gutter={"0px"}
                claro={true}
                label={locale.companyForm.name}
                value={company.Name}
                error={error.companyName ? error.message : ""}
                onFocusOut={(param) => {
                    if (param)
                        request().get(`/api/Company/CompareCompaniesNames?companyName=${param}`).then((response) => {

                        }).catch((error) => {
                            setError({ type: 'warning', message: error.response?.data.Message })
                        })
                }}
                onChange={value => {
                    setCompany({ ...company, Name: value })
                    setError({})
                }}
            />
            <Autocomplete
                header={true}
                label={locale.companyForm.country}
                descriptionField={"toString"}
                white={true}
                options={countries}
                defaultValue={company.Country}
                onChange={value => {
                    setEulaAccepted(false)
                    setCompany({ ...company, Country: value, CountryId: value?.id })
                    setError({})
                }}
                loading={loadingCountries}
                error={error.country ? error.message : ""}
            />
            <Autocomplete
                header={true}
                label={locale.companyForm.language}
                descriptionField={"description"}
                white={true}
                options={languages}
                defaultValue={language}
                onChange={value => {
                    setLanguage(value)
                    setError({})
                }}
                loading={loadingCountries}
                error={error.language ? error.message : ""}
            />
            <LoginInput
                gutter={"0px"}
                claro={true}
                label={locale.companyForm.province}
                value={company.Province}
                error={error.province ? error.message : ""}
                onChange={value => {
                    setCompany({ ...company, Province: allowOnlyCharactersAndNumbers(value) })
                    setError({})
                }}
            />
            <LoginInput
                gutter={"0px"}
                claro={true}
                label={locale.companyForm.city}
                value={company.City}
                error={error.city ? error.message : ""}
                onChange={value => {
                    setCompany({ ...company, City: allowOnlyCharactersAndNumbers(value) })
                    setError({})
                }}
            />
            <LoginInput
                gutter={"0px"}
                claro={true}
                label={locale.companyForm.postalCode}
                value={company.PostalCode}
                error={error.postalCode ? error.message : ""}
                onChange={value => {
                    setCompany({ ...company, PostalCode: allowOnlyCharactersAndNumbers(value) })
                    setError({})
                }}
            />
            <LoginInput
                gutter={"0px"}
                claro={true}
                label={locale.companyForm.address}
                value={company.Addres}
                error={error.address ? error.message : ""}
                onChange={value => {
                    setCompany({ ...company, Addres: allowOnlyCharactersAndNumbers(value) })
                    setError({})
                }}
            />
            <LoginInput
                gutter={"0px"}
                claro={true}
                label={locale.companyForm.phone}
                value={company.Phone}
                onChange={value => {
                    setCompany({ ...company, Phone: phoneCharAllowed(value) })
                    setError({})
                }}
                type={"text"}
                error={error.phone ? error.message : ""}
            />
            <LoginInput
                gutter={"0px"}
                claro={true}
                label={locale.companyForm.companyRepresentative}
                value={company.Contact}
                onChange={value => {
                    setCompany({ ...company, Contact: allowOnlyCharactersAndNumbers(value) })
                    setError({})
                }}
                type={"text"}
                error={error.contact ? error.message : ""}
            />
        </div>
    );
};

export default Company;