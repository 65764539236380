import { makeStyles, createStyles } from "@material-ui/styles";

export default makeStyles((theme) => createStyles({
  root: {
    backgroundColor: theme.palette.terragene.darkSecondary,
    textAlign: 'center',
    height: "100vh"
  },

  loginCard: {
    display: 'inline-grid',
    root: {
      backgroundColor: 'red'
    },
    
  },
   "@media only screen and (min-width: 1366px)": {
    loginCard :{
      marginTop: 50,
    },
    root: {
      height:'100vh'
    },
  },

  title:{
    color: theme.palette.terragene.lightThird,
    textTransform: 'uppercase',
  },  
  
  subTitle:{
    color: theme.palette.terragene.lightThird,
    fontSize: "1rem",
    lineHeight: "1.33",
    letterSpacing: "0em",
    fontWeight: 400,
    alignSelf: "center",
    marginBottom: 8,
    marginLeft: 10
  },

  loginCardSignUp: {
    display: 'inline-grid',
  },

  loginForm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    
  },



  inputColor:{
    color:'white'
  },

  "@media (min-width: 448px)": {
    loginCard: {
      width: 300,
    },
    loginCardSignUp: {
      width: 800
    }

  },
  
  logoTerragene: {
    height: 40,
    position: 'fixed',
    bottom: 0,
    left: '50%',
    transform: 'translateX(-50%)',
  },

  loginText: {
    textTransform: 'uppercase',
    color: theme.palette.terragene.lightThird,
    fontFamily:"Montserrat",
    fontSize: "1.5rem",
    lineHeight: "1.33",
    letterSpacing: "0em",
    fontWeight: 400,
    alignSelf: "center",
    marginBottom: 8,
    marginLeft: 10,
    
  },
  forgotPasswordLink: {
    color:  "white",
    textAlign: "center",
    marginTop: 10,
    marginBottom: 50,
    cursor: "pointer",
    fontSize:12,
    fontFamily:"Montserrat"
  },
  backToLoginLink: {
    color:  "white",
    textAlign: "center",
    marginTop: 10,
    marginLeft: 100,
    marginRight: 100,
    cursor: "pointer",
    fontSize:12,
    fontFamily:"Montserrat"
  },
  newCompanyLink: {
    color:  theme.palette.terragene.lightMain,
    textAlign: "center",
    marginTop: 0,
    marginBottom: 0,
    cursor: "pointer",
    fontSize:12,
    fontFamily:"Montserrat"
  },

  loading: {
    margin: "0!important",
    position: "absolute!important",
    top: 0,
    left: 0,
    right: 0,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4
  },

  userIcon: {
    width: 40,
    height: 40,
    color: theme.palette.terragene.lightThird,
    "& svg": {
      fill: "currentcolor"
    }
  },

  img: {
    width: 352,
  },
  logoBionova: {
    width: theme.palette.images.widthlogoBionovaLogin ? theme.palette.images.widthlogoBionovaLogin : 160,
    paddingBottom:60,
  },
}));

