import React, { useEffect, useState } from "react";
import classNames from "classnames";
import {
  getSession,
  getSysUserLanguage,
  logout,
  getUserType,
  getUserCompany,
  getEula,
} from "../../utils/sessionHandler";
import request from "../../utils/request";
import { useTheme } from "@material-ui/core/styles";
import Redirect from "../../utils/Redirect";

import Menu from "./Menu";

import useStyles from "./NavBarStyles";
import { getMenuItemId } from "../../utils/ValidateURL";
import Button from "../Button";
import { isNull, isUndefined } from "lodash";
import t from "../../utils/translation";

export default function NavBar(props) {
  const theme = useTheme();
  const classes = useStyles();
  const [open, setOpen] = useState(window.innerWidth >= 960);
  const [user, setUser] = useState({});
  const [userType, setUserType] = useState(1);

  useEffect(() => {
    const infouser = getSession();
    const eula = getEula();

    if (infouser.UserType === 0 && (!eula || !eula.acceptedEula))
      window.location.href = "/appeula";

    if (infouser.SectorId)
      request()
        .get(`/api/Sector/${infouser.SectorId}`)
        .then((res) => {
          infouser.SectorName = res.data.name;
          setUser(infouser);
        });
    else setUser(infouser);

    setUserType(
      infouser.UserType === undefined ? 1 : infouser.UserType === 3 ? 3 : 2
    );
  }, []);

  const { MenuOptions = {}, currentPage = {}, screenName, moduleName } = props;

  const handleLogout = () => {
    logout();
  };

  const activeMenuOption = getMenuItemId(
    currentPage.menuGroup,
    currentPage.key
  );

  return (
    <div>
      <header className={classes.root}>
        <div className={classes.navbar}>
          <div
            className={classes.hamburgerButtonRoot}
            onClick={() => setOpen(!open)}
          >
            <span className={classes.hamburgerButtonContent}>
              <svg
                focusable="false"
                viewBox="0 0 24 24"
                aria-hidden="true"
                className={classes.hamburgerButtonSvg}
              >
                <path d="M3 18h18v-2H3v2zm0-5h18v-2H3v2zm0-7v2h18V6H3z"></path>
              </svg>
            </span>
          </div>
          <div>
            <img
              src={theme.palette.images.logoBionova}
              className={classes.logoBionova}
            ></img>
          </div>
          <div style={{ marginLeft: 25 }}>
            {!isUndefined(getSysUserLanguage()) &&
              !isNull(getSysUserLanguage()) &&
              getUserType() === 0 && (
                <Button
                  caption={"Translate"}
                  color={"primary"}
                  onClick={() =>
                    Redirect({
                      redirect: true,
                      path: "/accesstotranslate",
                      parameters: {
                        moduleId: moduleName,
                        screenId: screenName,
                      },
                    })
                  }
                />
              )}
          </div>
        </div>
        <div className={classes.rightContainer}>
          <img
            src={theme.palette.images.logo}
            className={classes.logoTerragene}
          ></img>
          <div className="dropdown">
            <div data-toggle="dropdown" className={classes.user}>
              <span className={classes.userName}>{user.toString}</span>
              <span className={classes.userIcon}>
                <svg viewBox="0 0 24 24">
                  <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 3c1.66 0 3 1.34 3 3s-1.34 3-3 3-3-1.34-3-3 1.34-3 3-3zm0 14.2c-2.5 0-4.71-1.28-6-3.22.03-1.99 4-3.08 6-3.08 1.99 0 5.97 1.09 6 3.08-1.29 1.94-3.5 3.22-6 3.22z" />
                </svg>
              </span>
            </div>
            <div
              className={classNames(classes.mainDropdown, "dropdown-menu")}
              aria-labelledby="dropdownMenu2"
            >
              <div className={classes.companyInfo}>
                {(user.Company || user.Distributor) && (
                  <div className={classes.title}>{t("CompanyInfo")}</div>
                )}
                <div>
                  {user.Company && <div>{user.Company.Name}</div>}
                  {user.Distributor && <div>{user.Distributor.Name}</div>}
                  {user.Distributor && (
                    <div className={classes.title}>{t("Domain")}</div>
                  )}
                  {user.Distributor && <div>{user.Distributor.Domain}</div>}
                  {user.Company && (
                    <div>{user.IdDistributorCompany}</div>
                  )}
                  {user.Company && (
                    <span>{user.Company?.Country?.Name + " - "}</span>
                  )}
                  
                  {user.Company && <span>{user.Company.Province + " - "}</span>}
                  {user.Company && <span>{user.Company.City + " - "}</span>}
                  {user.Company && <span>{user.Company.Addres}</span>}
                  {user.Sector && user.Sector.Name && (
                    <div className={classes.title}>{t("Sector")}</div>
                  )}
                  {user.Sector && user.Sector.Name && (
                    <div>{user.Sector.Name}</div>
                  )}
                  {user.UserTypeDescription && (
                    <div className={classes.title}>{t("UserType")}</div>
                  )}
                  {user.UserTypeDescription && (
                    <div>{user.UserTypeDescription}</div>
                  )}
                </div>
                <div className={classes.title}>Email</div>
                <div>{user.Email}</div>
              </div>
              <button
                className={classNames(classes.btnLogout, "dropdown-item")}
                type="button"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </header>
      <Menu
        open={open}
        setOpen={setOpen}
        MenuOptions={MenuOptions}
        activeMenuOption={activeMenuOption}
        menuType={theme ? theme.menuType : null}
        userType={userType}
        menuOptions={theme ? theme.menuOptions : null}
      />
      <div
        className={classNames(classes.content, {
          [classes.menuOpenGutter]: open,
        })}
      >
        <div className={classes.contentChild}>{props.children}</div>
      </div>
    </div>
  );
}
