import React, { useState, useEffect, useContext } from "react";
import _ from "lodash";
import LocalizationContext from "../../utils/LocalizationContext";
import t from "../../utils/translation";
import {
  getUserCompany,
  getSession,
  getDist,
  getUserType,
} from "../../utils/sessionHandler";
import request from "../../utils/request";
import { useLocation } from "react-router-dom";
import { unflatten } from "flat";

import Select from "../../components/Select";
import CRUD from "../../components/CRUD";
import PageContentLoading from "../../components/PageContentLoading";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import Progress from "../../components/Progress";

import useStyles from "./UserStyles";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

export default function User() {
  const classes = useStyles();
  const company = getUserCompany();
  const query = useQuery();
  const locale = useContext(LocalizationContext);

  const [loadingContent, setLoadingContent] = useState(true);
  const [loadingData, setLoadingData] = useState(false);
  const [data, setData] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [selectedSector, setSelectedSector] = useState(
    query.get("sectorId") || ""
  );
  const [openResetPassword, setOpenResetPassword] = useState(false);
  const [userToResetPassword, setUserToResetPassword] = useState(null);
  const [loading, setloading] = useState(false);
  const [resetPasswordResponse, setResetPasswordResponse] = useState(null);

  useEffect(() => {
    request()
      .get(`/api/sector?companyId=${company.Id}`)
      .then((response) => {
        setTimeout(() => {
          setSectors(
            response.data.reduce((sectorOptions, sector) => {
              sectorOptions.push([sector.id, sector.name]);
              return sectorOptions;
            }, [])
          );
          setLoadingContent(false);
        }, 250);
      })
      .catch((error) => console.log(error));
  }, [company.Id]);

  useEffect(() => {
    if (selectedSector) {
      setLoadingData(true);
      request()
        .get(`/api/appuser?sectorId=${selectedSector}`)
        .then((response) => {
          setTimeout(() => {
            setData(
              response.data.map((x) => ({
                ..._.mapKeys(x, (value, key) => "AppUser." + key),
              }))
            );
            setLoadingData(false);
          }, 250);
        })
        .catch((error) => console.log(error.response));
    }
  }, [selectedSector]);

  const updateData = (updatedObj) => {
    setLoadingData(true);
    request()
      .get(`/api/appuser?sectorId=${selectedSector}`)
      .then((response) => {
        setTimeout(() => {
          setData(
            response.data.map((x) => ({
              ..._.mapKeys(x, (value, key) => "AppUser." + key),
            }))
          );
          const intSectorId = parseInt(updatedObj.AppUser.sectorId);
          if (
            updatedObj.AppUser.id == updatedObj.UserLogged.Id &&
            intSectorId != updatedObj.UserLogged.SectorId
          ) {
            request()
              .post(`/api/login/getNewAppToken`, updatedObj.AppUser.id)
              .then((response) => {
                const newToken = response.data;

                localStorage.removeItem("__token");
                localStorage.setItem("__token", JSON.stringify(newToken));
              });
          }
          setLoadingData(false);
        }, 250);
      })
      .catch((error) => console.log(error.response));
  };

  const handleResetPassowrd = () => {
    setloading(true);
    request()
      .post("/api/appuser/resetpassword", {
        AppUser: unflatten(userToResetPassword).AppUser,
        UserLogged: getSession(),
        DistributorId: getDist().id,
      })
      .then((response) => {
        setOpenResetPassword(false);
        setloading(false);
        setResetPasswordResponse({ type: "success", message: response.data });
        setTimeout(() => {
          setResetPasswordResponse(null);
        }, 3000);
      })
      .catch((error) => {
        console.log(error.response);
        setloading(false);
        setResetPasswordResponse({
          type: "danger",
          message: error.response?.data?.message,
        });
        setTimeout(() => {
          setResetPasswordResponse(null);
        }, 3000);
      });
  };

  if (loadingContent) return <PageContentLoading />;

  return (
    <div>
      <div className={"container-fluid px-0"}>
        <div className={"row mx-0 "}>
          <div
            style={{ margin: "25px 0px 0px 25px" }}
            className={"col-12 col-md-5 px-0"}
          >
            <Select
              label={t("SectorLabel", locale)}
              options={sectors}
              emptyOptionText={t("ChooseASector", locale)}
              variant={"outlined"}
              value={selectedSector}
              onChange={(val) => setSelectedSector(val)}
            />
          </div>
        </div>
      </div>

      {resetPasswordResponse && (
        <div className={`alert alert-${resetPasswordResponse.type}`}>
          {resetPasswordResponse.message}
        </div>
      )}
      {selectedSector && (
        <CRUD
          url={"/api/appuser"}
          title={t("Title", locale)}
          data={data}
          loadingData={loadingData}
          updateData={updateData}
          columns={[
            "AppUser.name",
            "AppUser.lastName",
            "AppUser.email",
            "AppUser.userTypeDescription",
          ]}
          headers={[
            t("NameLabel", locale),
            t("LastNameLabel", locale),
            t("MailLabel", locale),
            t("UserTypeLabel", locale),
          ]}
          tableActions={[
            {
              action: (val) => {
                setOpenResetPassword(true);
                setUserToResetPassword(val);
              },
              tooltip: t("ResetPasswordTooltip"),
              icon: "M7.11 8.53L5.7 7.11C4.8 8.27 4.24 9.61 4.07 11h2.02c.14-.87.49-1.72 1.02-2.47zM6.09 13H4.07c.17 1.39.72 2.73 1.62 3.89l1.41-1.42c-.52-.75-.87-1.59-1.01-2.47zm1.01 5.32c1.16.9 2.51 1.44 3.9 1.61V17.9c-.87-.15-1.71-.49-2.46-1.03L7.1 18.32zM13 4.07V1L8.45 5.55 13 10V6.09c2.84.48 5 2.94 5 5.91s-2.16 5.43-5 5.91v2.02c3.95-.49 7-3.85 7-7.93s-3.05-7.44-7-7.93z",
            },
          ]}
          idField={"AppUser.id"}
          fieldsMap={[
            "AppUser.sectorId",
            "AppUser.name",
            "AppUser.lastName",
            "AppUser.email",
            "AppUser.userType",
          ]}
          formFields={[
            {
              label: t("SectorLabel", locale),
              class: "col-12 col-md-6 px-0",
              type: "select",
              options: sectors,
              defaultValue: selectedSector,
              updateOnly: true,
              required: true,
            },
            {
              label: t("NameLabel", locale),
              class: "col-12 col-md-6 px-0",
              required: true,
              regex: true,
            },
            {
              label: t("LastNameLabel", locale),
              class: "col-12 col-md-6 px-0",
              required: true,
              regex: true,
            },
            {
              label: t("MailLabel", locale),
              class: "col-12 col-md-6 px-0",
              newOnly: true,
              required: true,
              regex: true,
            },
            {
              label: t("UserTypeLabel", locale),
              class: "col-12 col-md-6 px-0",
              type: "select",
              options:
                getUserType() === 0
                  ? [
                      [1, "Supervisor"],
                      [2, "Operator"],
                      [4, "Connection"],
                    ]
                  : [[2, "Operator"]],
              descriptionField: "AppUser.userTypeDescription",
              newOnly: true,
              required: true,
            },
          ]}
          detailFields={[
            { label: t("NameLabel", locale), name: "AppUser.toString" },
            { label: t("MailLabel", locale), name: "AppUser.email" },
            {
              label: t("UserTypeLabel", locale),
              name: "AppUser.userTypeDescription",
            },
            {
              label: t("CreationUserLabel", locale),
              name: "AppUser.creationUser",
            },
            {
              label: t("CreationDateLabel", locale),
              name: "AppUser.creationDate",
              type: "dateTime",
            },
            { label: t("EditUserLabel", locale), name: "AppUser.editUser" },
            {
              label: t("EditDateLabel", locale),
              name: "AppUser.editDate",
              type: "dateTime",
            },
          ]}
          baseObject={{
            AppUser: {
              id: null,
              name: "",
              lastName: "",
              email: "",
              sectorId: selectedSector,
              userType: 1,
            },
            DistributorId: getDist().id,
            UserLogged: getSession(),
          }}
        />
      )}
      {openResetPassword && (
        <Modal
          closeModal={() => setOpenResetPassword(false)}
          header={
            <React.Fragment>
              <div className={classes.header}>
                <span className={classes.headerText}>
                  {t("ResetPasswordHeader", locale)}
                </span>
              </div>
              <Progress active={loading} />
            </React.Fragment>
          }
          footer={
            <div className={classes.footerAction}>
              <Button
                caption={t("CancelButton")}
                onClick={() => setOpenResetPassword(false)}
                variant={"text"}
                color={"error"}
                disabled={loading}
              />
              <Button
                caption={t("ConfirmButton")}
                variant={"text"}
                onClick={handleResetPassowrd}
                disabled={loading}
              />
            </div>
          }
        >
          <div className={"container-fluid"}>
            <div className={"row"}>
              <div className={"col-12"} style={{ textAlign: "center" }}>
                {t("ResetPasswordConfirmationText", locale)}{" "}
                <b>{userToResetPassword["AppUser.toString"]}</b>?
              </div>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
}
