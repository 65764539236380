import React, { useState } from "react";
import request from "../../../utils/request";
import { unflatten } from "flat";
import _ from "lodash";
import t from "../../../utils/translation";

import Modal from "../../Modal";
import Button from "../../Button";

export default function Delete(props) {
  const [responseError, setResponseError] = useState(null);
  const [forceDelete, setForceDelete] = useState(false);
  const {
    classes,
    dispatch,
    url,
    objectToDelete,
    baseObject,
    baseObjectOverwrite,
    title,
    idField
  } = props;
  const NametoString = `(${objectToDelete[
    idField.split(".")[0] + ".toString"
  ] || objectToDelete.toString})`;

  const handleDelete = () => {
    const requestObject = _.merge(
      {},
      baseObject,
      unflatten(objectToDelete),
      baseObjectOverwrite
    );

    if (responseError)
      requestObject.ForcedDelete = true;

    request()
      .delete(url, requestObject)
      .then(response => {
        dispatch({ type: "delete" });
        props.saveSuccess(response.data);
      })
      .catch(error => {
        console.log(error.response)
        if (typeof error.response?.data?.errors === "object") {
          const errorBag = error.response.data.errors;
          setResponseError(
            <div>
              <p>{error.response.data.title}: </p>
              {Object.keys(errorBag).map((k) => (
                <p key={k}>{errorBag[k][0]}</p>
              ))}
            </div>
          );
        } else {
          setResponseError(
            error.response?.data?.message ||
            error.response?.data?.title ||
            error.response?.data
          );
        }
      });
  };

  return (
    <Modal
      closeModal={() => dispatch({ type: "closeConfirmation" })}
      header={
        <div className={classes.header}>
          <span className={classes.headerText}>
            {(t("DeleteText") || `Delete`) + ` ${title} ${NametoString}`}
          </span>
        </div>
      }
      footer={
        <div className={classes.footerAction}>
          <Button
            caption={t("CancelButton") || "Cancel"}
            onClick={() => dispatch({ type: "closeConfirmation" })}
            variant={"text"}
            color={"error"}
          />
          <Button
            caption={t("ConfirmButton") || "Confirm"}
            variant={"text"}
            onClick={handleDelete}
          />
        </div>
      }
    >
      <div className={"container-fluid"}>
        {responseError && (
          <div className={"row mx-0"}>
            <div className={"col-12"}>
              <div className={"alert alert-danger"}>{responseError}</div>
            </div>
          </div>
        )}
        <div className={"row"}>
          <div className={"col-12"} style={{ textAlign: "center" }}>
            {(t("DeleteConfirmationText") ||
              "Are you sure you want to delete this") + ` ${title}?`}
          </div>
        </div>
      </div>
    </Modal>
  );
}

