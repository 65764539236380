import React, { useState, useRef, useLayoutEffect } from "react";
import classnames from "classnames";
import moment from "moment";
import MomentUtils from "@date-io/moment";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import df, { userLanguageCode } from "../../utils/dateFormater";
import t from "../../utils/translation";
import useStyles from "./DateInputStyles";
import { createTheme, ThemeProvider } from "@material-ui/core";
moment.locale(userLanguageCode());

const theme = createTheme({
   palette : {
    primary :{
      main:'#1A1A1A',
    }
   },
   typography : {
    fontFamily: 'Montserrat',
   }
});

const DateInput = (props) => {
  const classes = useStyles();

  const [focused, setFocused] = useState(false);
  const [labelWidth, setlabelWidth] = useState(null);

  const fullwidth = props.fullwidth !== undefined ? props.fullwidth : true;
  const variant = props.variant || "standard";
  const value = props.value;
  const clearable = props.clearable;
  const locale = userLanguageCode();

  const inputLabelRef = useRef();
  useLayoutEffect(() => {
    setlabelWidth(inputLabelRef.current.offsetWidth);
  }, []);

  const outlinePadding = () => (focused || value ? 8 : labelWidth * 0.35 + 14);
  const whiteSpaceWidth = () => (focused || value ? labelWidth * 0.75 + 12 : 0);

  return (
    <div style={{ margin: props.gutter ? props.gutter : "8px 8px" }}>
      <div
        className={classnames(classes.root, {
          [classes.focused]: focused,
          [classes.inputLabelShrink]: value || focused,
          [classes.hasError]: props.error,
          [classes.disabled]: props.disabled,
          [classes.fullwidth]: fullwidth,
        })}
      >
        <label
          ref={inputLabelRef}
          className={classnames(classes.inputLabel, {
            [classes.inputLabelOutlined]: variant === "outlined",
          })}
        >
          {props.label} {props.isRequired ? "*" : ""}
        </label>
        <div
          className={classnames(classes.inputBaseRoot, {
            [classes.inputUnderline]: variant === "standard",
          })}
        >
          <div
            className={classnames(classes.inputBaseInput, {
              [classes.outlinedInputInput]: variant === "outlined",
            })}
          >
            <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider
              libInstance={moment}
              utils={MomentUtils}
              locale={locale}
            >
              <DatePicker
                onOpen={() => setFocused(true)}
                onClose={() => setFocused(false)}
                value={value}
                clearable={clearable}
                disabled={props.disabled}
                okLabel={t("AcceptButton")}
                cancelLabel={t("CancelButton")}
                clearLabel={t("ClearButton")}
                onChange={(val) => props.onChange(val)}
                labelFunc={(date) => df(date, "date")}
              />
            </MuiPickersUtilsProvider>
            </ThemeProvider>

            <i
              className={classnames(
                "far fa-calendar-alt",
                classes.calendarIcon
              )}
            ></i>
          </div>

          {variant === "outlined" && (
            <fieldset
              className={classes.inputOutline}
              style={{ paddingLeft: outlinePadding() }}
            >
              <legend
                className={classes.whiteSpace}
                style={{ width: whiteSpaceWidth() }}
              >
                <span>&#8203;</span>
              </legend>
            </fieldset>
          )}
        </div>
        {!props.noError && (
          <p
            className={classnames(classes.errorMessage, {
              [classes.errorMessageContained]: variant === "outlined",
            })}
          >
            {props.error}
          </p>
        )}
      </div>
    </div>
  );
};

export default DateInput;

