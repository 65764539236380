import React, { useState, useEffect } from "react";
import request from "../../utils/request";
import { flatten, unflatten } from "flat";
import useStyles from "./LicenseStyles";

import CRUD from "../../components/CRUD";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import Progress from "../../components/Progress";
import Input from "../../components/Input";
import FixedAlert from "../../components/FixedAlert/FixedAlert";

export default function License() {
  const classes = useStyles();

  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [confirmationObject, setConfirmationObject] = useState(null);
  const [loadingConfirm, setLoadingConfirm] = useState(false);
  const [invoiceNumber, setInvoiceNumber] = useState(null);
  const [observation, setObservation] = useState("");
  const [error, setError] = useState("");
  const [licenseToEdit, setLicenseToEdit] = useState(false);

  useEffect(() => {
    updateData();
  }, []);

  const updateData = () => {
    setLoadingData(true);
    request()
      .get(`/api/LicenseCode`)
      .then((response) => {
        setData(response.data.map((i) => flatten(i)));
        setTimeout(() => {
          setLoadingData(false);
        }, 250);
      })

      .catch((error) => {
        console.log(error.response);
      });
  };

  const clearFields = () => {
    setObservation("");
    setInvoiceNumber(null);
    setConfirmationObject(null);
  };

  const approveLicense = (licenseCode, invoice, notes) => {
    const licenseCodeObject = unflatten(licenseCode);
    const licenseObject = licenseCodeObject.license;
    licenseObject.invoiceNumber = invoice;
    licenseObject.observations = notes;

    setLoadingConfirm(true);
    request()
      .post("/api/license/approvelicense", licenseObject)
      .then(() => {
        setTimeout(() => {
          setLoadingConfirm(false);
          setConfirmationObject(null);
          setInvoiceNumber(null);
          setObservation("");
          updateData();
        }, 500);
      })
      .catch((error) => {
        setError(error?.response?.data?.message);
        console.log();
        setLoadingConfirm(false);
      });
  };

  const cancelLicense = (licenseCode, notes) => {
    const licenseCodeObject = unflatten(licenseCode);
    const licenseObject = licenseCodeObject.license;
    licenseObject.observations = notes;

    setLoadingConfirm(true);
    request()
      .post("/api/license/cancellicense", licenseObject)
      .then(() => {
        setTimeout(() => {
          setLoadingConfirm(false);
          setConfirmationObject(null);
          setObservation("");
          updateData();
        }, 500);
      })
      .catch((error) => {
        console.log(error.response);
        setLoadingConfirm(false);
      });
  };

  const suspendLicense = (licenseCode, notes) => {
    const licenseCodeObject = unflatten(licenseCode);
    const licenseObject = licenseCodeObject.license;
    licenseObject.observations = notes;

    setLoadingConfirm(true);
    request()
      .post("/api/license/suspendlicense", licenseObject)
      .then(() => {
        setTimeout(() => {
          setLoadingConfirm(false);
          setConfirmationObject(null);
          setObservation("");
          updateData();
        }, 500);
      })
      .catch((error) => {
        console.log(error.response);
        setLoadingConfirm(false);
      });
  };

  const unsuspendLicense = (licenseCode, notes) => {
    const licenseCodeObject = unflatten(licenseCode);
    const licenseObject = licenseCodeObject.license;
    licenseObject.observations = notes;

    setLoadingConfirm(true);
    request()
      .post("/api/license/unsuspendlicense", licenseObject)
      .then(() => {
        setTimeout(() => {
          setLoadingConfirm(false);
          setConfirmationObject(null);
          setObservation("");
          updateData();
        }, 500);
      })
      .catch((error) => {
        console.log(error.response);
        setLoadingConfirm(false);
      });
  };

  return (
    <>
      <CRUD
        newOption={false}
        editOption={false}
        deleteOption={false}
        data={data}
        loadingData={loadingData}
        url={"/api/license"}
        updateData={updateData}
        title={"Licenses"}
        columns={[
          "license.company.name",
          "licenseProduct.name",
          "license.licensePeriod.periodName",
          "license.licenseStatusDescription",
          "code",
        ]}
        headers={["Company", "Name", "Period", "Status", "Code"]}
        idField={"licenseProduct"}
        fieldsMap={[
          "license.companyId",
          "license.company.name",
          "license.licenseStatusDescription",
          "license.licenseId",
        ]}
        formFields={[
          { label: "Company", class: "col-12 col-md-6 px-0", required: true },
          { label: "Status", class: "col-12 col-md-6 px-0", required: true },
        ]}
        detailFields={[
          { label: "Company", name: "license.company.toString" },
          {
            label: "Company Administrator Name",
            name: "license.company.administrator.toString",
          },
          {
            label: "Administrator Mail",
            name: "license.company.administrator.email",
          },
          { label: "Product", name: "licenseProduct.name" },
          { label: "Period", name: "license.licensePeriod.periodName" },
          { label: "Days", name: "license.licensePeriod.days" },
          { label: "Invoice Number", name: "license.invoiceNumber" },
          { label: "Start Date", name: "license.startDate", type: "date" },
          { label: "End Date", name: "license.endDate", type: "date" },
          { label: "Status", name: "license.licenseStatusDescription" },
          { label: "Code", name: "code" },
          {
            label: "Observations",
            name: "license.observations",
            class: "col-12 px-0",
          },
        ]}
        tableActions={[
          {
            action: (r) =>
              setConfirmationObject({
                header: "Approve License",
                body: "activationBody",
                action: (l, i, o) => approveLicense(l, i, o),
                object: r,
              }),
            tooltip: "Approve",
            icon: "M9 16.2L4.8 12l-1.4 1.4L9 19 21 7l-1.4-1.4L9 16.2z",
            hasCondition: true,
            condition: [["license.licenseStatus", "=", 0]],
          },
          {
            action: (r) =>
              setConfirmationObject({
                header: "Cancel License",
                body: "Are you sure you want to cancel this license?",
                action: (l, o) => cancelLicense(l, o),
                object: r,
              }),
            tooltip: "Cancel",
            icon: "M12 2C6.47 2 2 6.47 2 12s4.47 10 10 10 10-4.47 10-10S17.53 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.59-13L12 10.59 8.41 7 7 8.41 10.59 12 7 15.59 8.41 17 12 13.41 15.59 17 17 15.59 13.41 12 17 8.41z",
            hasCondition: true,
            condition: [["license.licenseStatus", "=", 0]],
          },
          {
            action: (r) =>
              setConfirmationObject({
                header: "Suspend License",
                body: "Are you sure you want to suspend this license?",
                action: (l, o) => suspendLicense(l, o),
                object: r,
              }),
            tooltip: "Suspend",
            icon: "M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zM9 6c0-1.66 1.34-3 3-3s3 1.34 3 3v2H9V6zm9 14H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z",
            hasCondition: true,
            condition: [["license.licenseStatus", "=", 2]],
          },
          {
            action: (r) =>
              setConfirmationObject({
                header: "Unsuspend License",
                body: "Are you sure you want to unsuspend this license?",
                action: (l, o) => unsuspendLicense(l, o),
                object: r,
              }),
            tooltip: "Unsuspend",
            icon: "M18 8h-1V6c0-2.76-2.24-5-5-5S7 3.24 7 6h2c0-1.66 1.34-3 3-3s3 1.34 3 3v2H6c-1.1 0-2 .9-2 2v10c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2V10c0-1.1-.9-2-2-2zm0 12H6V10h12v10zm-6-3c1.1 0 2-.9 2-2s-.9-2-2-2-2 .9-2 2 .9 2 2 2z",
            hasCondition: true,
            condition: [["license.licenseStatus", "=", 4]],
          },
        ]}
        baseObject={{
          periodName: "",
          days: "",
          price: "",
        }}
      />
      {confirmationObject && (
        <Modal
          closeModal={() => {
            setObservation("");
            setInvoiceNumber(null);
            setConfirmationObject(null);
          }}
          header={
            <React.Fragment>
              <div className={classes.header}>
                <span className={classes.headerText}>
                  {confirmationObject.header}
                </span>
              </div>
              <FixedAlert
                text={error}
                type={"error"}
                closeErrorCb={() => {
                  setError("");
                }}
              ></FixedAlert>
              <Progress active={loadingConfirm} />
            </React.Fragment>
          }
          footer={
            <div className={classes.footerAction}>
              <Button
                caption={"Cancel"}
                variant={"text"}
                onClick={() => clearFields()}
                disabled={loadingConfirm}
                color={"error"}
              />
              <Button
                caption={"Confirm"}
                variant={"text"}
                onClick={
                  confirmationObject.body === "activationBody"
                    ? () =>
                        confirmationObject.action(
                          confirmationObject.object,
                          invoiceNumber,
                          observation
                        )
                    : () =>
                        confirmationObject.action(
                          confirmationObject.object,
                          observation
                        )
                }
                disabled={loadingConfirm}
              />
            </div>
          }
        >
          <div className={"container-fluid"}>
            <div className={"row"}>
              <div className={"col-12"} style={{ textAlign: "center" }}>
                {confirmationObject.body === "activationBody" ? (
                  <>
                    <Input
                      label={"Invoice Number"}
                      value={invoiceNumber}
                      onChange={(val) => setInvoiceNumber(val)}
                      isRequired
                    />
                    <Input
                      label={"Observation"}
                      value={observation}
                      onChange={(val) => setObservation(val)}
                      isRequired
                    />
                  </>
                ) : (
                  <>
                    {confirmationObject.body}
                    <Input
                      label={"Observation"}
                      value={observation}
                      onChange={(val) => setObservation(val)}
                      isRequired
                    />
                  </>
                )}
              </div>
            </div>
          </div>
        </Modal>
      )}
    </>
  );
}
